import { z } from "zod";

import { dataPartSchema, eventListItemSchema } from "@hsl/fund-page/schemas";

import EventCard from "../../../components/FundEventCard";
import usePageSection from "../../../hooks/usePageSection";
import Container from "./Container";

const eventsSchema = dataPartSchema.extend({
    data: z.array(eventListItemSchema),
    conf: z.object({
        featured_state_for_first_card: z.number(),
    }),
});

const Events = () => {
    const { data, title, conf } = usePageSection("events", eventsSchema);
    if (data.length === 0) {
        return null;
    }
    const sortedCards = data.sort((a, b) => {
        if (a.featured_states.includes(conf.featured_state_for_first_card)) {
            return -1;
        }
        if (
            b.featured_states.includes(conf.featured_state_for_first_card) &&
            !a.featured_states.includes(conf.featured_state_for_first_card)
        ) {
            return 1;
        }
        return new Date(a.date).valueOf() > new Date(b.date).valueOf() ? 1 : -1;
    });
    return (
        <Container title={title} buttonText="More events" buttonHref="Events">
            {sortedCards.slice(0, 3).map((event) => (
                <EventCard key={event.id} {...event} />
            ))}
        </Container>
    );
};

export default Events;
