import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";

import Button from "../../../components/Button";

interface Props {
    href: string;
    text: string;
}

const NewsAndViewsCTA = ({ href, text }: Props) => {
    return (
        <Button
            href={href}
            className="hover:text-purple mt-4 w-max border border-white text-white hover:bg-white sm:mt-6"
        >
            {text}
            <ArrowRightIcon width={18} className="ml-2" />
        </Button>
    );
};

export default NewsAndViewsCTA;
