import { useMemo } from "react";
import { groupBy } from "ramda";

import { EMAIL_REGEX } from "@hsl/core/utils/isValidEmail";
import type { AdvancedDataTableSchema } from "@hsl/fund-page/schemas";

import FundPageContent from "../../../components/FundPageContent";

interface Props {
    data: AdvancedDataTableSchema;
}

const DealingAndLiquidity = ({ data }: Props) => {
    const { title, part } = data;

    /**
     *  Needed to handle email overrides such as below:
     *  `Dealing instructions only jpmbl.tadealing@jpmorgan.com`
     *  -> here 'Dealing instructions only' is the override str
     */
    const computedData = useMemo(() => {
        // Group data by key to avoid repeting
        const groupedObj = groupByKey(part.data);
        const groupedData = Object.keys(groupedObj).flatMap((key) =>
            groupedObj[key]?.map(([label, val], i) => [
                i === 0 ? String(label).split(" ").join("\u00A0") : "",
                val,
            ]),
        ) as (string | null)[][];

        // Collect email replacement strings
        const emailReplacementStrs: (string | null)[] = Array(
            groupedData.length,
        ).fill(null);
        const groupedDataWithEmailsReplaced = groupedData.map(
            ([label, value], i) => {
                const vals = value?.split(" ");
                const emailStr = vals?.find((str) =>
                    new RegExp(EMAIL_REGEX).test(str),
                );
                // if email string found then add to array
                if (emailStr && value) {
                    const replacementStr = value.replace(emailStr, "");
                    emailReplacementStrs[i] =
                        replacementStr.length > 0 ? replacementStr : null;
                    return [label, emailStr];
                }
                return [label, value];
            },
        );

        return {
            data: groupedDataWithEmailsReplaced,
            emailReplacementStrings: emailReplacementStrs,
        };
    }, [part.data]);

    return (
        <>
            <FundPageContent.Header>{title}</FundPageContent.Header>
            <FundPageContent.KeyValueList
                data={computedData.data}
                emailReplacementString={
                    !computedData.emailReplacementStrings.every(
                        (x) => x === null,
                    )
                        ? computedData.emailReplacementStrings
                        : "Contact"
                }
            />
        </>
    );
};

export default DealingAndLiquidity;

const groupByKey = groupBy(function (data: (string | null)[]) {
    return String(data[0]);
});
