import { z } from "zod";

import cx from "@hsl/core/utils/cx";
import { personSchema } from "@hsl/fund-page/schemas";

import useFundPageStore from "../../store/store";
import FundPageContent from "../FundPageContent";
import Skeleton from "../Skeletons";
import TextDataPart from "../TextDataPart";

interface Props {
    className?: string;
}

export const PersonCard = ({
    academicQualifications,
    bio,
    firstName,
    headshot,
    lastName,
    professionalQualifications,
    role,
    yearsAtFirm,
    yearsInIndustry,
    className,
}: z.infer<typeof personSchema> & Props) => {
    const tenant = useFundPageStore((store) => store.config.tenant);
    return (
        <div
            className={cx(
                "border-secondary flex flex-col rounded-xl border-t-[22px] bg-white px-6 pb-8 pt-4",
                className,
            )}
        >
            <div className="flex-1">
                <Head />
                <TextDataPart data={bio} className="my-2" />
            </div>
            <div className="border-offWhite flex flex-col gap-y-4 border-t pt-2 sm:pt-4">
                {academicQualifications && <AcademicQualifications />}
                {professionalQualifications && <ProfessionalQualifications />}
                <div className="flex justify-between">
                    {yearsAtFirm && <YearsAtFirm />}
                    {yearsInIndustry && <YearsInIndustry />}
                </div>
            </div>
        </div>
    );

    function Head() {
        return (
            <div className="flex items-center">
                <img
                    src={headshot}
                    alt={`${firstName} ${lastName} headshot`}
                    className="h-[60px] w-[60px] rounded-full sm:h-[90px] sm:w-[90px]"
                />
                <div className="flex flex-col justify-center pl-4">
                    <div className="text-purple text-lg font-semibold leading-tight sm:text-xl">
                        {`${firstName} ${lastName}`}
                    </div>
                    <AboutHeader>{role}</AboutHeader>
                </div>
            </div>
        );
    }

    function ProfessionalQualifications() {
        return (
            <div>
                <AboutHeader>Professional Qualifications</AboutHeader>
                <FundPageContent.P>
                    {professionalQualifications}
                </FundPageContent.P>
            </div>
        );
    }

    function AcademicQualifications() {
        return (
            <div>
                <AboutHeader>Academic Qualifications</AboutHeader>
                {academicQualifications?.map((qual, i) => (
                    <TextDataPart key={`${qual}-${i}`} data={qual} />
                ))}
            </div>
        );
    }

    function YearsAtFirm() {
        return (
            <div>
                <AboutHeader>Time at {tenant}</AboutHeader>
                <FundPageContent.P>{yearsAtFirm} years</FundPageContent.P>
            </div>
        );
    }

    function YearsInIndustry() {
        return (
            <div>
                <AboutHeader>Industry Experience</AboutHeader>
                <p>{yearsInIndustry} years</p>
            </div>
        );
    }
};
const AboutHeader = ({ children }: { children: React.ReactNode }) => {
    return (
        <h4 className="text-purple mb-0 text-xs font-semibold uppercase sm:text-sm">
            {children}
        </h4>
    );
};

export const PersonCardSkeleton = ({
    className,
    width,
}: {
    className?: string;
    width?: string;
}) => (
    <div className={className}>
        <Skeleton
            width={width ?? "100%"}
            height={"22px"}
            className="bg-secondary/50 !mb-0"
        />
        <Skeleton width={"100%"} height={"200px"} />
    </div>
);
