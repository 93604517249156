import { useMemo, useState, type ReactNode } from "react";
import ArrowDownTray from "@heroicons/react/20/solid/ArrowDownTrayIcon";

import cx from "@hsl/core/utils/cx";

import useFundPageStore from "../../store/store";
import Skeleton from "../Skeletons";

// @ts-expect-error -> not sure why index.d.ts file is not working...
import DownloadIconGreen from "../../assets/downloadGreen.svg";
// @ts-expect-error - ditto
import DownloadIconWhite from "../../assets/downloadWhite.svg";
// @ts-expect-error - ditto
import FactSheetIconGreen from "../../assets/factSheetGreen.svg";
// @ts-expect-error - ditto
import FactSheetIconWhite from "../../assets/factSheetWhite.svg";

export interface Props {
    href?: string;
    className?: string;
    text?: ReactNode;
    textClassName?: string;
    color?: "green" | "white";
    date?: string;
    iconContainerClassName?: string;
    iconClassName?: string;
}

const DocumentDownloadCTA = ({
    href,
    className,
    text,
    textClassName,
    color = "green",
    date,
    iconContainerClassName,
    iconClassName,
}: Props) => {
    const baseURL = useFundPageStore((store) => store.config.base_url);
    const [showDownloadIcon, setShowDownloadIcon] = useState(false);

    const FactSheetIcon =
        color === "green" ? FactSheetIconGreen : FactSheetIconWhite;
    const DownloadIcon =
        color === "green" ? DownloadIconGreen : DownloadIconWhite;

    const textContent = useMemo(() => {
        if (typeof text === "string") {
            const [first, ...rest] = text.split(" ");
            return (
                <>
                    {first}
                    <br />
                    {rest.join(" ")}
                </>
            );
        }
        return text;
    }, [text]);

    return (
        <div
            className={cx(
                "mb-4 flex w-max items-center",
                color === "white" && "text-white",
                className,
            )}
        >
            <a
                href={`${baseURL}${href}`}
                target="_blank"
                className={cx(
                    "mr-4 flex h-[48px] max-h-[48px] items-center justify-center sm:h-[82px] sm:max-h-[82px] sm:w-[73px] sm:max-w-[73px]",
                    iconContainerClassName,
                )}
                onMouseEnter={() => setShowDownloadIcon(true)}
                onMouseLeave={() => setShowDownloadIcon(false)}
                rel="noreferrer"
            >
                {showDownloadIcon ? (
                    <img
                        src={DownloadIcon}
                        className={cx("h-full", iconClassName)}
                        alt="Download"
                    />
                ) : (
                    <img
                        src={FactSheetIcon}
                        className={cx("h-full", iconClassName)}
                        alt="Download"
                    />
                )}
            </a>
            <a
                href={`${baseURL}${href}`}
                target="_blank"
                className="flex flex-col justify-center"
                rel="noreferrer"
            >
                {text && (
                    <h5
                        className={cx(
                            "text-purple mb-0 text-sm font-normal !leading-tight sm:text-lg",
                            date && "!leading-[1.2]",
                            color === "white" && "text-white",
                            textClassName,
                        )}
                    >
                        {textContent}
                        <ArrowDownTray className="-mt-1 ml-1 inline h-3 sm:h-[18px]" />
                    </h5>
                )}
                {date && <div className="text-sm text-black/60">{date}</div>}
            </a>
        </div>
    );
};

export default DocumentDownloadCTA;

export const DocumentDownloadCTASkeleton = ({ color }: Props) => {
    const FactSheetIcon =
        color === "green" ? FactSheetIconGreen : FactSheetIconWhite;
    return (
        <div className="mb-4 flex w-max items-center">
            <div className="mr-4 flex h-[82px] max-h-[82px] items-center justify-center">
                <img src={FactSheetIcon} alt="Fact sheet is loading" />
            </div>
            <div>
                <Skeleton width={"65px"} />
                <Skeleton width={"165px"} />
            </div>
        </div>
    );
};
