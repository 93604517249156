import BarTable, { type Props } from "@hsl/components/BarTable";

const SpringBarTable = (props: Props) => {
    return (
        <BarTable
            {...props}
            containerClassName="pt-0 text-sm sm:text-base"
            barClassNames={["bg-secondary", "bg-secondary"]}
            barContainerClassName={"max-w-[100px] sm:min-w-[90px]"}
            valueClassNames={["w-[60%] lg:w-auto py-0.5", "py-0.5"]}
        />
    );
};

export default SpringBarTable;
