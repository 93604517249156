import { useState } from "react";
import { z } from "zod";

// import usePartData from "@hsl/core/fund-page/hooks/usePartData";
import {
    convertCurrencyStrMinor,
    type CurrencyStr,
} from "@hsl/core/fund-page/utils/convertCurrencyStr";
import {
    advancedDataTableData,
    advancedDataTableSchema,
    dataPartSchema,
    shareClassSelectorSchema,
    type AdvancedDataTableData,
    type ShareClassOption,
} from "@hsl/fund-page/schemas";

import Dropdown from "../../../components/Dropdown";
import FundPageContent from "../../../components/FundPageContent";
import Skeleton from "../../../components/Skeletons";
import usePartData from "../../../hooks/usePartData";
import useFundPageStore from "../../../store/store";
import DividendHistoryTable from "../components/DividendHistoryTable";

export const dividendHistorySchema = dataPartSchema.extend({
    part: z.object({
        dividendsTable: advancedDataTableSchema,
        dividendsShareclassSelector: shareClassSelectorSchema,
    }),
});

export const DividendHistory = (
    props: z.infer<typeof dividendHistorySchema>,
) => {
    const config = useFundPageStore((store) => store.config);
    const { part, title } = props;
    const { dividendsTable, dividendsShareclassSelector } = part;
    const [activeShareClass, setActiveShareClass] = useState<ShareClassOption>(
        dividendsShareclassSelector.part.data[0] as ShareClassOption,
    );
    const { data, isLoading } = usePartData<AdvancedDataTableData>(
        Number(dividendsTable.part.partId),
        {
            baseUrl: config.base_url,
            audienceId: String(config.audienceId),
            jurisdictionId: String(config.jurisdictionId),
            languageId: String(config.languageId),
            routeId: String(config.routeId),
            shareClassID: String(activeShareClass.id),
        },
        advancedDataTableData,
    );

    return (
        <div>
            <div className="flex items-center justify-between pb-4">
                <FundPageContent.Header className="!mb-0">
                    {title}
                </FundPageContent.Header>
                <span className="flex items-center">
                    <span className="hidden pr-4 sm:inline">
                        Choose share class
                    </span>
                    <Dropdown<ShareClassOption>
                        value={activeShareClass}
                        options={dividendsShareclassSelector.part.data}
                        toLabel={(val) => val.name}
                        onChange={setActiveShareClass}
                    />
                </span>
            </div>
            {isLoading || !data ? (
                <DividendHistoryTableSkeleton />
            ) : (
                <DividendHistoryTable
                    {...data}
                    activeCurrency={convertCurrencyStrMinor(
                        activeShareClass.currency as CurrencyStr,
                    )}
                />
            )}
        </div>
    );
};

const DividendHistoryTableSkeleton = () => {
    return (
        <div className="flex w-full min-w-full flex-col gap-y-4">
            {Array(5)
                .fill(null)
                .map((_, i) => {
                    return (
                        <Skeleton
                            key={`DividendHistoryTableSkeleton-${i}`}
                            width={"100%"}
                        />
                    );
                })}
        </div>
    );
};
