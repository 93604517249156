import Checkbox, {
    type Props as CheckBoxProps,
} from "@hsl/components/Checkbox";
import cx from "@hsl/core/utils/cx";

import { type Company } from "../../context";

type PillCheckBoxProps = Pick<
    CheckBoxProps<Company>,
    "value" | "selected" | "label"
> & { onChange?: (company: Company) => void };

export const pillCheckBoxCx = (selected?: boolean, classNames?: string) =>
    cx(
        "inline-flex items-center justify-center",
        "w-max h-[25px]",
        "px-4 rounded-full transition-all cursor-pointer",
        "leading-0 text-sm",
        {
            "bg-primary text-white border-primary hover:opacity-[0.85]":
                selected,
            "bg-gray-100 text-primary hover:bg-gray-200": !selected,
        },
        classNames,
    );

export const CompanyPillCheckbox = ({
    value,
    selected,
    label,
    onChange,
}: PillCheckBoxProps) => {
    return (
        <Checkbox<Company>
            value={value}
            selected={selected}
            label={label}
            labelClassName={pillCheckBoxCx(selected)}
            inputClassName="hidden"
            onChange={(company) => {
                onChange && onChange(company);
            }}
        />
    );
};
