import { ReactNode } from "react";

import { GenericErrorBoundary } from "@hsl/lgim-explorer/src/components/GenericErrorBoundary/GenericErrorBoundary";

import { ButtonWithArrow } from "../../../components/Button";
import { ListContainer as CardListContainer } from "../../../components/Card/Card";
import FundPageContent from "../../../components/FundPageContent";

interface Props {
    title?: string;
    children: ReactNode;
    buttonText?: string;
    buttonHref?: string;
}

const Container = ({ buttonText, buttonHref, children, title }: Props) => {
    return (
        <GenericErrorBoundary>
            <div className="bg-offWhite">
                <div className="mx-auto">
                    <FundPageContent.Header h2>{title}</FundPageContent.Header>
                    <CardListContainer>{children}</CardListContainer>
                </div>
                <div className="flex justify-end py-4">
                    <ButtonWithArrow
                        href={buttonHref}
                        className="lg:px-7 lg:py-4"
                    >
                        {buttonText}
                    </ButtonWithArrow>
                </div>
            </div>
        </GenericErrorBoundary>
    );
};

export default Container;
