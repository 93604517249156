import cx from "@hsl/core/utils/cx";
import { EventListItem } from "@hsl/fund-page/schemas";
import dayjs from "dayjs";
import { ReactNode } from "react";
import Card from "../Card";
import { MapPin, NewPage, OnlineEvent } from "../icons";
import TextDataPart from "../TextDataPart";

export const FundEventCard = ({
    id,
    type,
    image,
    date,
    start_time,
    end_time,
    description,
    name,
    read_url,
    venue,
    link,
    status,
    route,
    category,
    disableLink,
}: EventListItem & { disableLink?: boolean }) => {
    const eventDate = dayjs(date);
    const dateStr = eventDate.format("dddd DD MMM YYYY");
    const timeStr = `${start_time} - ${end_time}`;
    const externalLink = link && link !== "None" ? link : undefined;
    const externalLinkObj = externalLink ? new URL(externalLink) : undefined;
    if (externalLinkObj) {
        externalLinkObj.searchParams.append("type", "event");
    }
    return (
        <Card.Container
            href={
                disableLink ? undefined : externalLinkObj?.toString() ?? route
            }
            newTab={Boolean(externalLinkObj)}
        >
            <Card.Image
                imgURL={
                    image !== "None" && !!image
                        ? image
                        : "/static/images/event-card-default.jpg"
                }
            >
                <StatusBanner />
                <div className="absolute right-2 top-2 flex h-[65px] w-[65px] flex-col items-center justify-center rounded-full bg-white">
                    <span className="text-primary text-3xl font-light leading-none">
                        {eventDate.format("DD")}
                    </span>
                    <span className="text-secondary -mt-1 font-semibold uppercase">
                        {eventDate.format("MMM")}
                    </span>
                </div>
            </Card.Image>
            <Card.Body>
                <h4 className="text-primary mb-3 text-2xl font-extralight leading-[1] sm:mb-4 sm:text-3xl">
                    {name}
                </h4>
                <TextDataPart className="flex-1" data={description} />
                {type.toLowerCase() === "online" ? (
                    <EventCardFooter
                        Icon={<OnlineEvent width={34} />}
                        venue="Online Event"
                        newPage
                    />
                ) : (
                    <EventCardFooter
                        Icon={<MapPin width={34} />}
                        venue={`${venue.city}, ${venue.name}`}
                    />
                )}
            </Card.Body>
        </Card.Container>
    );

    function EventCardFooter({
        Icon,
        venue,
        newPage,
    }: {
        Icon: ReactNode;
        venue: string;
        newPage?: boolean;
    }) {
        return (
            <Card.Footer className="flex h-16 text-sm sm:text-base">
                {Icon}
                <div className="flex-1 pl-2">
                    <span className="block font-semibold uppercase">
                        {venue}
                    </span>
                    <div className="flex flex-wrap">
                        <span className="span pr-1">
                            {dateStr}
                            {","}
                        </span>
                        <span className="span whitespace-nowrap">
                            {timeStr}
                        </span>
                    </div>
                </div>
                <div className="ml-auto">
                    {newPage && (
                        <NewPage className={disableLink && "opacity-0"} />
                    )}
                </div>
            </Card.Footer>
        );
    }

    function StatusBanner() {
        return (
            <div
                className={cx(
                    "bg-primary absolute left-0 right-0 top-0 h-6 bg-opacity-75",
                    status === "past" && "bg-black"
                )}
            >
                <p className="flex h-full items-center justify-center text-sm font-medium uppercase text-white">
                    {category}
                </p>
            </div>
        );
    }
};
