import { type Config } from "tailwindcss";
import { type DefaultTheme } from "tailwindcss/types/generated/default-theme";

import cobrandingConfig from "@hsl/lgim-cobranding/tailwind.config";
import fundCentreConfig from "@hsl/lgim-cobranding/tailwind.config";
import baseConfig from "@hsl/tailwind-config";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultTheme = require("tailwindcss/defaultTheme") as DefaultTheme;

export default {
    content: [
        "../../components/cobranding/lgim-cobranding/src/**/*.{js,jsx,ts,tsx}",
        "../../components/cobranding/core-cobranding/src/**/*.{js,jsx,ts,tsx}",
        "../../components/fund-explorers/lgim-explorer/src/**/*.{js,jsx,ts,tsx}",
        "../../components/fund-pages/lgim-fund-page/src/**/*.{js,jsx,ts,tsx}",
        "../../components/mps/lgim-mps/src/**/*.{js,jsx,ts,tsx,svelte}",
        "../../packages/components/**/*.{js,jsx,ts,tsx,svelte}",
        "../../packages/core/utils/**/*.{js,jsx,ts,tsx,svelte}",
    ],
    theme: {
        container: {
            padding: {
                sm: "1rem",
                md: "1rem",
                lg: "1rem",
                xl: "1rem",
                "2xl": "1rem",
            },
            screens: {
                sm: "640px",
                md: "768px",
                lg: "1024px",
                xl: "1200px",
                "2xl": "1200px",
            },
        },
        extend: {
            colors: {
                "poppy-red-lightest": "#FFEAEC",
                "pastel-red": "#F6B2B6",
                "light-red": "#EC6084",
                "leafy-green-lightest": "#E5F4EA",
                "warm-gray": "#A3A3A3",
                "dark-yellow": "#DD9C00",
                "pastel-yellow": "#FFEEA4",
                ochre: "#B65F17",
                "dandelion-yellow-lightest": "#FDF8C6",
                "core-yellow": "#FFD500",
                "pastel-green": "#D9E6B1",
                "dark-green": "#005629",
                "white-smoke": "#F6F6F6",
                "taupe-grey": "#737373",
                "super-blue-dark": "#175EA1",
                "super-blue-lightest": "#E3F2FD",
                "super-blue-light": "#8ECAFB",
                "light-blue": "#71CBF4",
                "pastel-blue": "#C9E8FB",
                "dark-blue": "#004E86",
                "leafy-green-light": "#97D3AB",
                "light-green": "#BBD143",
                black: "#000000",
                "l-g-blue": "#006FB8",
                "core-red": "#E22922",
                "dark-red": "#A81815",
                charcoal: "#333333",
                "core-green": "#028844",
                "core-blue": "#0076D6",
                white: "#FFFFFF",
                "battleship-grey": "#575757",
                platinum: "#E3E3E3",
                bullet: "#DD9C00",
            },
            fontFamily: {
                sans: ["Roboto", ...defaultTheme.fontFamily.sans],
                serif: ["Lyon", ...defaultTheme.fontFamily.serif],
            },
            backgroundImage: {
                gapRight: `linear-gradient(to right, rgb(246 246 246) calc(100% - ${defaultTheme.spacing[0.5]}), white calc(100% - ${defaultTheme.spacing[0.5]}))`,
                gapLeft: `linear-gradient(to left, rgb(246 246 246) calc(100% - ${defaultTheme.spacing[0.5]}), white calc(100% - ${defaultTheme.spacing[0.5]}))`,
                gapX: `linear-gradient(to right, white ${defaultTheme.spacing[0.5]}, rgb(246 246 246) ${defaultTheme.spacing[0.5]}, rgb(246 246 246) calc(100% - ${defaultTheme.spacing[1]}), white calc(100% - ${defaultTheme.spacing[1]}))`,
            },
            keyframes: {
                "max-width": {
                    "0%": {
                        maxWidth: "0%",
                    },
                    "100%": {
                        maxWidth: "100%",
                    },
                },
            },
            flex: {
                "0": "0",
            },
        },
    },
    presets: [baseConfig, cobrandingConfig, fundCentreConfig],
} satisfies Config;
