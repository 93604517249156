import { useState } from "react";
import { Popover } from "@headlessui/react";
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";
import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import XCircleIcon from "@heroicons/react/24/solid/XCircleIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import Checkbox from "@hsl/components/Checkbox";
import { useBreakpointContext } from "@hsl/core/hooks/useBreakpoint";
import useDisableBodyScroll from "@hsl/core/hooks/useDisableBodyScroll";
import cx from "@hsl/core/utils/cx";

import useSignUpForm from "../../hooks/useSignUpForm";
import themeColors from "../../lib/themeColors";
import useFundPageStore from "../../store/store";
import Button, { BUTTON_CLASSNAMES } from "../Button";
import FundPageContent from "../FundPageContent";
import TextInput from "../TextInput";

const signUpformSchema = z.object({
    email: z.string().email(),
    name: z.string(),
    company: z.string(),
    part_version_id: z.string(),
    fund_name: z.string(),
});

export type SignUpFormArgs = z.infer<typeof signUpformSchema>;

interface Props {
    partVersionId: string;
    fundName: string;
    onCancel(): void;
}

export const SignUpForm = ({ partVersionId, fundName, onCancel }: Props) => {
    const baseURL = useFundPageStore((store) => store.config.base_url);
    const [tcs, setTcs] = useState(false);
    const { success, onSubmit, setSucess } = useSignUpForm(baseURL);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SignUpFormArgs>({
        defaultValues: {
            email: "",
            name: "",
            company: "",
            fund_name: fundName,
            part_version_id: partVersionId,
        },
        resolver: zodResolver(signUpformSchema),
    });

    return (
        <FundPageContent.Box
            containerClassName="h-full"
            className="relative h-full w-full !px-4 sm:w-[330px]"
        >
            {typeof success === "boolean" && <Message />}
            <div>
                <div className="sm:hidden">
                    <div className="flex justify-end py-2">
                        <XMarkIcon
                            width={24}
                            height={24}
                            className="text-primary"
                            onClick={onCancel}
                        />
                    </div>
                    <FundPageContent.Header h2 className="mb-0">
                        Sign up
                    </FundPageContent.Header>
                    <FundPageContent.P className="py-4">
                        This event is for Investment Professionals only.
                        <br />
                        Please register using a business address only.
                    </FundPageContent.P>
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col gap-y-2"
                >
                    <TextInput
                        {...register("email")}
                        placeholder="Email"
                        error={errors.email}
                    />
                    <TextInput
                        {...register("name")}
                        placeholder="Name"
                        error={errors.name}
                    />
                    <TextInput
                        {...register("company")}
                        placeholder="Company"
                        error={errors.company}
                    />
                    <div className="flex items-center justify-end py-2">
                        <CTAs />
                    </div>
                    <div className="align-middle text-sm">
                        <Checkbox<boolean>
                            value={tcs}
                            onChange={(_, checked) => setTcs(checked)}
                            selected={tcs}
                            containerClassName="mr-1 inline-flex"
                        />
                        <TermsAndConditions />
                    </div>
                </form>
            </div>
        </FundPageContent.Box>
    );

    function CTAs() {
        const disabled = tcs === false;
        return (
            <>
                {onCancel && (
                    <button
                        className="text-purple px-4 underline transition hover:font-semibold"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                )}
                <Button
                    type="submit"
                    disabled={disabled}
                    className={cx(
                        !disabled &&
                            "hover:border-purple hover:text-purple hover:bg-white",
                        "bg-purple w-max border py-2 pr-1.5 text-white",
                    )}
                >
                    Sign up
                    <ArrowRightIcon width={18} className="ml-2" />
                </Button>
            </>
        );
    }

    function TermsAndConditions() {
        return (
            <>
                <p className="inline text-sm">
                    By ticking this box you consent to allow SCP to store your
                    details in order to send you the documents requested, as
                    well as send, from time to time, additional information
                    relevant to this fund such as manager commentary or other
                    data. You may unsubscribe at any time by clicking on the
                    appropriate link in the email or by emailing us at:
                </p>
                <div>
                    <a
                        href="mailto:dataprotection@springcapitalpartners.com"
                        className="text-purple"
                    >
                        dataprotection@springcapitalpartners.com
                    </a>
                    .
                </div>
            </>
        );
    }

    function Message() {
        return (
            <div className="bg-offWhite max-w-screen absolute inset-0 z-20 flex flex-col items-center justify-center rounded-lg">
                {success ? (
                    <>
                        <CheckCircleIcon
                            width={75}
                            color={themeColors.primary}
                        />
                        <span className="text-xl">Success!</span>
                    </>
                ) : (
                    <>
                        <XCircleIcon width={75} color={themeColors.secondary} />
                        <span>There was an error with your submission</span>
                        <button
                            className="text-secondary underline"
                            onClick={() => setSucess(undefined)}
                        >
                            Try again
                        </button>
                    </>
                )}
            </div>
        );
    }
};

interface SignUpFormWithPopoverProps {
    partVersionId: string;
    fundName: string;
    label: string;
    buttonClassNames?: string;
    className?: string;
    panelClassName?: string;
}

export const SignUpFormWithPopover = ({
    partVersionId,
    fundName,
    label,
    buttonClassNames,
    className,
    panelClassName,
}: SignUpFormWithPopoverProps) => {
    const isMobile = useBreakpointContext()?.currentBreakpoint === "xs";
    const { modalOpen, setModalOpen } = useFundPageStore((store) => store);

    useDisableBodyScroll(isMobile && modalOpen);

    return (
        <Popover className={cx("relative z-20", className)}>
            {({ open }) => {
                return (
                    <>
                        <Popover.Button
                            className={cx(
                                BUTTON_CLASSNAMES,
                                "border-purple text-purple hover:bg-purple border font-semibold hover:text-white",
                                buttonClassNames,
                            )}
                            onClick={() => isMobile && setModalOpen(!open)}
                        >
                            {label}
                        </Popover.Button>

                        <Popover.Panel
                            className={cx(
                                "fixed inset-0 z-10 h-screen w-screen rounded-lg pt-12 shadow-2xl sm:absolute sm:-left-[25%] sm:mt-2 sm:h-max sm:w-max sm:pt-0",
                                panelClassName,
                            )}
                        >
                            {({ close }) => (
                                <SignUpForm
                                    partVersionId={partVersionId}
                                    fundName={fundName}
                                    onCancel={() => {
                                        close();
                                        modalOpen && setModalOpen(false);
                                    }}
                                />
                            )}
                        </Popover.Panel>
                    </>
                );
            }}
        </Popover>
    );
};
