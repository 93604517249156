import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { validateConfig } from "@hsl/core/fund-explorer/validators";
import { type Config } from "@hsl/spring-capital/src/js/fund-page/config";

import Events from "./src/views/Events";
import FundPageView from "./src/views/FundPageView";
import HomeNewsAndViews from "./src/views/HomeNewsAndViews";
import ManagerESGView from "./src/views/ManagerESGView/ManagerESGView";
import ManagerPage from "./src/views/ManagerPage";
import NewsAndViews from "./src/views/NewsAndViews/NewsAndViews";
import PostRead from "./src/views/PostRead/PostRead";
import SingleEventRead from "./src/views/SingleEventRead";

import "./tailwind.css";

interface Props {
    config: Config;
}

const Spring = ({ config }: Props) => {
    if (config.partConfig?.key) {
        switch (config.partConfig.key) {
            case "managerESG":
                return <ManagerESGView />;
            case "newsAndViews":
                return <NewsAndViews />;
            case "homeEventsAndNews":
                return <HomeNewsAndViews />;
            case "postRead":
                return <PostRead />;
            case "events":
                return <Events />;
            case "singleEventRead":
                return <SingleEventRead />;
            case "managerPage":
                return <ManagerPage />;
            default:
                throw Error("Provided key does not exist.");
        }
    }

    return <FundPageView />;
};

const queryClient = new QueryClient();

const App = ({ config }: Props) => {
    const isValidConfig = validateConfig(config);
    return isValidConfig ? (
        <QueryClientProvider client={queryClient}>
            <Spring config={config} />
        </QueryClientProvider>
    ) : (
        <div>Invalid fund page config</div>
    );
};

export default App;
