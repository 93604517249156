import { useMemo } from "react";

import type { DocumentSet } from "@hsl/core/documents/types";

import { groupByDay, groupByMonth, groupByYear } from "../helpers/groupers";

const useAvailableDates = (data?: DocumentSet[]) => {
    return useMemo(() => {
        if (!data) {
            return null;
        }
        const datesGroupedByYear = groupByYear(data);

        return Array.from(Object.keys(datesGroupedByYear)).map((year) => {
            const datesGroupedByMonth = groupByMonth(datesGroupedByYear[year]!);
            const datesGroupedByMonthAsc = Array.from(
                Object.keys(datesGroupedByMonth),
            ).sort((a, b) => (parseInt(a) > parseInt(b) ? 1 : -1));

            return {
                value: parseInt(year),
                availableMonths: datesGroupedByMonthAsc.map((month) => {
                    const datesGroupedByDay = groupByDay(
                        datesGroupedByMonth[month]!,
                    );

                    return {
                        value: parseInt(month),
                        availableDays: Array.from(
                            Object.keys(datesGroupedByDay),
                        ).map((day) => parseInt(day)),
                    };
                }),
            };
        });
    }, [data]);
};

export default useAvailableDates;
