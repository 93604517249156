import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import { usePopper } from "react-popper";

import cx from "@hsl/core/utils/cx";

export const PLACEMENTS = [
    "top",
    "top-start",
    "top-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "right",
    "right-start",
    "right-end",
    "left",
    "left-start",
    "left-end",
] as const;

type Placement = (typeof PLACEMENTS)[number];

export interface Props extends React.PropsWithChildren {
    content: React.ReactNode;
    className?: string;
    focusRing?: boolean;
    icon?: React.ReactElement;
    placement?: Placement;
    targetClassName?: string;
    withArrow?: boolean;
    panelClassName?: string;
    containerAs?: React.ElementType;
    as?: React.ElementType;
}

const Tooltip = ({
    content,
    children,
    className,
    focusRing = false,
    icon,
    placement = "top",
    targetClassName,
    panelClassName,
    withArrow = true,
    containerAs,
    as,
}: Props) => {
    const [referenceElement, setReferenceElement] =
        useState<HTMLButtonElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>();
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: placement,
        strategy: "fixed",
        modifiers: [
            { name: "arrow", options: { element: arrowElement } },
            {
                name: "offset",
                options: {
                    offset: (() => {
                        switch (placement) {
                            case "top":
                            case "top-start":
                            case "top-end":
                            case "bottom":
                            case "bottom-start":
                            case "bottom-end":
                                return [8, 0];
                            case "right":
                            case "right-start":
                            case "right-end":
                            case "left":
                            case "left-start":
                            case "left-end":
                                return [0, 8];
                        }
                    })(),
                },
            },
        ],
    });

    return (
        <Popover
            className={cx("relative", className)}
            onMouseOver={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            as={containerAs}
        >
            <Popover.Button
                ref={setReferenceElement}
                as={as}
                className={cx(
                    "cursor-default",
                    { "focus-visible:outline-none": !focusRing },
                    targetClassName,
                )}
            >
                {children ??
                    (icon || (
                        <InformationCircleIcon className="text-charcoal h-3 w-3" />
                    ))}
            </Popover.Button>
            <Transition
                show={isOpen}
                as={Fragment}
                enter="transition-opacity ease-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-in duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Popover.Panel
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    className={cx(
                        "z-[100] px-4 py-1 shadow-md",
                        "w-max max-w-[15rem] whitespace-pre-wrap rounded bg-white text-left text-sm font-normal",
                        panelClassName,
                    )}
                >
                    {!!withArrow && (
                        <div
                            ref={setArrowElement}
                            style={styles.arrow}
                            className={cx(
                                "pointer-events-none absolute block h-0 w-0",
                                "border-8 border-transparent",
                                {
                                    "-right-4 border-l-white":
                                        !!attributes?.popper &&
                                        attributes.popper[
                                            "data-popper-placement"
                                        ]?.includes("left"),
                                    "-left-4 border-r-white":
                                        !!attributes?.popper &&
                                        attributes.popper[
                                            "data-popper-placement"
                                        ]?.includes("right"),
                                    "-top-4 border-b-white":
                                        !!attributes?.popper &&
                                        attributes.popper[
                                            "data-popper-placement"
                                        ]?.includes("bottom"),
                                    "-bottom-4 border-t-white":
                                        !!attributes?.popper &&
                                        attributes.popper[
                                            "data-popper-placement"
                                        ]?.includes("top"),
                                },
                            )}
                        />
                    )}
                    {content}
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default Tooltip;
