import { useMemo } from "react";
import type { Schema } from "zod";

import useFundPageStore from "../store/store";

function usePageSection<T extends Schema>(
    dataKey: string,
    schema: T,
): T["_output"] {
    const pageSections = useFundPageStore((store) => store.pageSections);
    const setPageSectionError = useFundPageStore(
        (store) => store.setPageSectionError,
    );
    const refetch = useFundPageStore((store) => store.isRefetching);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return useMemo(() => {
        const section = pageSections.find((x) => x.key === dataKey);
        const parsed = schema.safeParse(section);
        if (parsed.success) {
            setPageSectionError(dataKey);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return parsed.data;
        } else {
            setPageSectionError(dataKey, `Error parsing ${section?.title}`);
            throw parsed.error;
        }
    }, [pageSections, setPageSectionError, dataKey, schema, refetch]);
}

export default usePageSection;
