import React, { type PropsWithChildren } from "react";

import cx from "@hsl/core/utils/cx";

import Tooltip, { type Props as TooltipProps } from "../Tooltip/Tooltip";

export interface TableHeaderValueProps {
    alignCenter?: boolean;
    alignLeft?: boolean;
    alignRight?: boolean;
    className?: string;
    tooltip?: TooltipProps;
}

const TableHeaderValue = ({
    alignCenter,
    alignRight,
    alignLeft,
    children,
    className,
    tooltip,
}: TableHeaderValueProps & PropsWithChildren) => {
    const tooltipActive =
        !!tooltip &&
        typeof tooltip.content === "string" &&
        tooltip.content.length > 0;
    return (
        <span
            className={cx(
                {
                    "text-left": alignLeft,
                    "text-center": alignCenter,
                    "text-right": alignRight,
                    "pt-1": tooltipActive,
                },
                "block",
                className,
            )}
        >
            <span className="relative">
                {children}
                {tooltipActive && (
                    <Tooltip
                        {...tooltip}
                        className="absolute -right-3 -top-2"
                    />
                )}
            </span>
        </span>
    );
};

export default TableHeaderValue;
