import type { ReactNode } from "react";

import cx from "@hsl/core/utils/cx";

const NoDataMessage = ({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}) => {
    return (
        <div
            className={cx(
                "w-max max-w-full rounded-lg bg-[#DEDEDE] p-3 text-base text-[#737373]",
                className,
            )}
        >
            {children}
        </div>
    );
};

export default NoDataMessage;
