import { useMemo } from "react";

function useRelatedFunds() {
    const relatedFundIds = useMemo(() => {
        const funds = document.querySelectorAll("[data-related-fund]");
        return Array.from(
            new Set(
                Array.from(funds).map(
                    (domElem) => (domElem as HTMLElement).dataset.relatedFund,
                ),
            ),
        );
    }, []);

    return relatedFundIds;
}

export default useRelatedFunds;
