import { ReactNode } from "react";

import cx from "@hsl/core/utils/cx";

import { GreyCurve } from "../icons";

interface Props {
    className?: string;
    children: ReactNode;
    withCurve?: boolean;
}

const GreyCurveContainer = ({
    className,
    withCurve = true,
    children,
}: Props) => {
    return (
        <div className={cx(withCurve && "mt-12", className)}>
            {withCurve && <GreyCurve className="mx-auto max-w-full " />}
            <div className="bg-offWhite mx-auto -mt-1 pt-1">
                <div className="container mx-auto">{children}</div>
            </div>
        </div>
    );
};

export default GreyCurveContainer;
