import { useMemo } from "react";
import dayjs from "dayjs";

import { type LineChartSchema } from "@hsl/fund-page/schemas";

import LineChart from "../../../components/LineChart";
import { usePerformanceContext } from "../context";

type PerformanceLineChartProps = {
    underFive: LineChartSchema;
    fiveToTen: LineChartSchema;
    overTen: LineChartSchema;
};

const PerformanceLineChart = ({
    underFive,
    fiveToTen,
    overTen,
}: PerformanceLineChartProps) => {
    const { activeShareClass } = usePerformanceContext();

    const activeData = useMemo(() => {
        const shareClassLaunch = dayjs(activeShareClass.launchDate);
        const fiveYearsAgo = dayjs().subtract(5, "year");
        const tenYearsAgo = dayjs().subtract(10, "year");
        if (shareClassLaunch.isBefore(tenYearsAgo)) {
            return overTen;
        }
        if (shareClassLaunch.isBefore(fiveYearsAgo)) {
            return fiveToTen;
        }
        return underFive;
    }, [activeShareClass.launchDate, fiveToTen, overTen, underFive]);

    return (
        <LineChart
            data={activeData.part}
            launchDate={activeData.part.launch_date}
            shareClassId={activeShareClass.id}
        />
    );
};

export default PerformanceLineChart;
