import { forwardRef, type MouseEvent } from "react";
import ChevronLeftIcon from "@heroicons/react/20/solid/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/20/solid/ChevronRightIcon";

import cx from "@hsl/core/utils/cx";

// import useFundPageStore from "../../store/store";
import Dropdown from "../Dropdown";

interface Props {
    months: number[];
    selection: [number, number];
    year: number;
    years: number[];
    next: number | undefined;
    prev: number | undefined;
    activeYearIndex: number;
    locale?: string;
    onSelect: (month: number) => void;
    onChange: (index: number) => void;
}

const MonthsDisplay = forwardRef<HTMLDivElement, Props>(
    (
        {
            months,
            selection,
            year,
            years = [],
            next,
            prev,
            locale,
            onSelect,
            onChange,
        },
        ref,
    ) => {
        const isAvailable = (m: number) => months.includes(m);
        const changeHandler =
            (prevNext: number | undefined) => (event: MouseEvent) => {
                event.preventDefault();
                event.stopPropagation();
                hasPrevNext(prevNext) && onChange(prevNext);
            };

        const handleDropdownChange = (y: number) => onChange(years.indexOf(y));
        const hasPrevNext = (
            prevNext: number | undefined,
        ): prevNext is number => prevNext !== undefined;

        const formatter = new Intl.DateTimeFormat(locale, {
            month: "short",
        });

        return (
            <div ref={ref}>
                <div className="bg-pastel-blue grid grid-cols-3">
                    <span
                        className={cx([
                            {
                                "opacity-50": !hasPrevNext(prev),
                                "cursor-pointer": hasPrevNext(prev),
                            },
                            "block w-full py-2 text-center",
                        ])}
                        onClick={changeHandler(prev)}
                    >
                        <ChevronLeftIcon className="mx-auto h-6 w-6" />
                    </span>
                    <span className="block w-full py-2 text-center">
                        {years.length > 1 ? (
                            <Dropdown
                                noChevron
                                options={years}
                                value={year}
                                buttonClassName="transition hover:text-core-blue/90"
                                onChange={handleDropdownChange}
                            />
                        ) : (
                            year
                        )}
                    </span>
                    <span
                        className={cx([
                            {
                                "opacity-50": !hasPrevNext(next),
                                "cursor-pointer": hasPrevNext(next),
                            },
                            "block w-full py-2 text-center",
                        ])}
                        onClick={changeHandler(next)}
                    >
                        <ChevronRightIcon className="mx-auto h-6 w-6" />
                    </span>
                </div>
                <ul className="grid grid-cols-3">
                    {Array.from({ length: 12 }).map((_, i) => {
                        const month = formatter.format(new Date(year, i));
                        return (
                            <li
                                key={month}
                                className={cx([
                                    {
                                        "font-bold":
                                            i === selection[0] &&
                                            year === selection[1],
                                        "opacity-50": !isAvailable(i),
                                        "cursor-pointer": isAvailable(i),
                                    },
                                    "p-[5px] py-2 text-center",
                                ])}
                                onClick={() => isAvailable(i) && onSelect(i)}
                            >
                                {month}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    },
);

export default MonthsDisplay;
