import { useEffect, useState } from "react";

export default function useMediaQuery(mq: string) {
    const [mql] = useState(matchMedia(mq));
    const [match, setMatch] = useState(mql.matches);
    useEffect(() => {
        mql.addEventListener("change", (e) => setMatch(e.matches));
    }, [mql]);

    return match;
}
