import React, { type PropsWithChildren } from "react";

const TableFootnote = ({ children }: PropsWithChildren) => {
    if (!children) {
        return null;
    }
    return (
        <p
            dangerouslySetInnerHTML={{ __html: children }}
            className="mt-4 block text-xs"
        />
    );
};

export default TableFootnote;
