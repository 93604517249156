import * as React from "react";

const SvgFilter = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={17}
        fill="none"
        {...props}
    >
        <g fill="currentColor">
            <path d="M2 4.167h12V5.5H2z" />
            <path
                fillRule="evenodd"
                d="M3.334 6.833h9.333l-.666 1.334H4l-.667-1.334ZM5.334 9.5h5.333l-.666 1.333H6L5.334 9.5Z"
                clipRule="evenodd"
            />
            <path d="M7 12.167h2V13.5H7z" />
        </g>
    </svg>
);
export default SvgFilter;
