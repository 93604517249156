import { groupBy } from "ramda";

import type { PostListFilterItem } from "@hsl/fund-page/schemas";

export const groupFilterItemByTenantName = groupBy(function (
    filterItem: PostListFilterItem,
) {
    return /Evenlode/gi.test(filterItem.name)
        ? "evenlode"
        : /Chelverton/gi.test(filterItem.name)
        ? "chelverton"
        : /Zennor/gi.test(filterItem.name)
        ? "zennor"
        : "other";
});

export const sortPostListStr = (filterItems: string[][]) => {
    return filterItems
        ?.map((tenantGroup) => tenantGroup.sort((a, b) => a.localeCompare(b)))
        .flatMap((x) => x);
};

export const sortPostListFilterItem = (filterItems: PostListFilterItem[][]) => {
    return filterItems
        ?.map((tenantGroup) =>
            tenantGroup.sort((a, b) => a.name.localeCompare(b.name)),
        )
        .flatMap((x) => x);
};

export const groupStrByTenantName = groupBy(function (str: string) {
    return /Evenlode/gi.test(str)
        ? "evenlode"
        : /Chelverton/gi.test(str)
        ? "chelverton"
        : /Zennor/gi.test(str)
        ? "zennor"
        : "other";
});
