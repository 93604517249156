import { z } from "zod";

import {
    advancedDataTableSchema,
    dataPartSchema,
} from "@hsl/fund-page/schemas";
import FundPageContent from "@hsl/spring-fund-page/src/components/FundPageContent";

import Tabs, {
    Tab,
    TabContainer,
    TabsProvider,
    useTabsContext,
} from "../../../components/Tabs";
import { PerformanceSummaryTable } from "../components";

export const summarySchema = dataPartSchema.extend({
    part: z.object({
        cumulative: advancedDataTableSchema,
        annualised: advancedDataTableSchema,
    }),
});

export const SummaryPerformance = (props: z.infer<typeof summarySchema>) => {
    const { title, part } = props;
    const { cumulative, annualised } = part;

    return (
        <TabsProvider>
            <div className="relative">
                <div className="flex flex-col pb-2 sm:flex-row sm:items-center sm:justify-between">
                    <FundPageContent.Header>{title}</FundPageContent.Header>
                    <SummaryTabs
                        tabs={[cumulative, annualised].map((x) => x.key)}
                    />
                </div>
                <Tabs
                    content={[
                        <PerformanceSummaryTable
                            {...cumulative}
                            key={cumulative.key}
                        />,
                        <PerformanceSummaryTable
                            {...annualised}
                            key={annualised.key}
                        />,
                    ]}
                    tabsContainerClassName="justify-end absolute left-0 right-0 -top-[2rem]"
                />
            </div>
        </TabsProvider>
    );
};

const SummaryTabs = ({ tabs }: { tabs: string[] }) => {
    const { activeTabIndex, setActiveTabIndex } = useTabsContext();

    return (
        <TabContainer className="py-1 sm:-mt-2 sm:py-0">
            {tabs.map((str, i) => (
                <Tab
                    key={str}
                    text={str.slice(0, 1).toUpperCase() + str.slice(1)}
                    isActive={activeTabIndex === i}
                    onClick={() => setActiveTabIndex(i)}
                />
            ))}
        </TabContainer>
    );
};
