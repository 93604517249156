import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { EventListItem } from "@hsl/fund-page/schemas";

export type EventsContextType = {
    allEvents: EventListItem[];
    activeFund?: string;
    activeCategory?: string;
    setActiveFund(val: string | undefined): void;
    setActiveCategory(val: string | undefined): void;
    clearFilters(): void;
};

export interface Props {
    children: React.ReactNode;
    events: EventListItem[];
}

export const EventsContext = createContext<EventsContextType | undefined>(
    undefined,
);

export const EventsContextProvider = ({ children, events }: Props) => {
    const [activeFund, setActiveFund] = useState<string | undefined>();
    const [activeCategory, setActiveCategory] = useState<string | undefined>();

    const clearFilters = () => {
        setActiveFund(undefined);
        setActiveCategory(undefined);
    };

    const contextValue = useMemo(
        () => ({
            allEvents: events,
            activeFund,
            activeCategory,
            setActiveFund,
            setActiveCategory,
            clearFilters,
        }),
        [
            events,
            activeFund,
            activeCategory,
            setActiveFund,
            setActiveCategory,
            clearFilters,
        ],
    );

    return (
        <EventsContext.Provider value={contextValue}>
            {children}
        </EventsContext.Provider>
    );
};

export function useEventsContext() {
    const eventsContext = useContext(EventsContext);
    if (!eventsContext) throw new Error("No EventsContextProvider");
    return eventsContext;
}
