import React, {
    type ElementType,
    type PropsWithChildren,
    type ReactNode,
} from "react";
import { Disclosure } from "@headlessui/react";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";

import cx from "@hsl/core/utils/cx";

interface Props {
    type?: "disclosure" | "controlled";
    className?: string;
    isOpen?: boolean;
    label: ReactNode | ((open: boolean) => React.ReactNode);
    labelClassNames?: string;
    chevronClassNames?: string;
    chevronWidth?: number;
    withChevron?: boolean;
    onClick?: (args?: any) => void;
    as?: ElementType<any>;
}

const CollapsibleLabel = ({
    as,
    isOpen = false,
    label,
    labelClassNames,
    chevronClassNames,
    withChevron = false,
    chevronWidth = 18,
    type = "disclosure",
    onClick,
}: Props & PropsWithChildren) => {
    const Container = React.useCallback(
        ({ children }: { children: React.ReactNode }) => {
            if (type === "disclosure") {
                return (
                    <Disclosure.Button
                        className={cx(
                            "flex items-center text-sm",
                            labelClassNames,
                        )}
                        as={as}
                        onClick={onClick}
                    >
                        {children}
                    </Disclosure.Button>
                );
            } else {
                return (
                    <div
                        className={cx(
                            "flex items-center text-sm",
                            onClick && "cursor-pointer",
                            labelClassNames,
                        )}
                        onClick={onClick}
                    >
                        {children}
                    </div>
                );
            }
        },
        [labelClassNames, as, onClick, type],
    );

    return (
        <>
            <Container>
                {typeof label === "function" ? label(isOpen) : label}{" "}
                {!!withChevron && (
                    <ChevronDownIcon
                        width={chevronWidth}
                        className={cx(
                            chevronClassNames,
                            isOpen && "rotate-180 transform",
                            "transition",
                        )}
                    />
                )}
            </Container>
        </>
    );
};

export default CollapsibleLabel;
