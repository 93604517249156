export function toSearchParams<T>(filterParams?: T) {
    if (!filterParams) return {};
    const filterMap = Object.keys(filterParams)
        .map((filterKey) => {
            const value = filterParams[filterKey as keyof T]?.toString();
            if (!value) return null;
            return [
                filterKey,
                filterParams[filterKey as keyof T]?.toString() ?? "",
            ];
        })
        .filter((x) => x !== null);
    return Object.fromEntries(filterMap as [string, string][]);
}
