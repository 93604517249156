import { current } from "immer";
import { z } from "zod";

import { dataPartSchema, postListItemSchema } from "@hsl/fund-page/schemas";

import FundPageContent from "../../../components/FundPageContent";
import { GreyCurve } from "../../../components/icons";
import NewsAndViewsCard, {
    NewsAndViewsCardSkeleton,
} from "../../../components/NewsAndViewsCard";
import { useContextPostId } from "../../../hooks/useContextPostId";
import useInsights from "../../../hooks/useInsights";
import usePageSection from "../../../hooks/usePageSection";
import useRelatedFunds from "../../../hooks/useRelatedFunds";
import useFundPageStore from "../../../store/store";

const articlesSchema = dataPartSchema.extend({
    data: z.array(postListItemSchema),
});

const Articles = () => {
    const { springAudienceId } = useFundPageStore((store) => store.config);

    const { data, title } = usePageSection("articles", articlesSchema);

    const relatedFundIds = useRelatedFunds();

    const postId = useContextPostId();

    const { insights, isLoading } = useInsights({
        pageSize: 3,
        filters: {
            funds: relatedFundIds,
            audiences: [springAudienceId],
            categories: [37, 38, 39, 40, 41, 112, 156, 230],
            posts_to_exclude: [postId],
        },
    });

    if (insights.length === 0) {
        return null;
    }

    return (
        <div className="overflow-hidden print:hidden">
            <GreyCurve className="mx-auto" />
            <div className="bg-offWhite -mt-1 pb-6 pt-1">
                <div className="container mx-auto">
                    <FundPageContent.Header h2>{title}</FundPageContent.Header>
                    <div className="flex flex-col gap-6 lg:flex-row">
                        {isLoading ? (
                            <>
                                <NewsAndViewsCardSkeleton />
                                <NewsAndViewsCardSkeleton />
                                <NewsAndViewsCardSkeleton />
                            </>
                        ) : (
                            insights.map((article, i) => (
                                <NewsAndViewsCard
                                    key={`${article.title}-${i}`}
                                    {...article}
                                />
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Articles;
