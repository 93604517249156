import { type ColumnMeta } from "@tanstack/react-table";
import { z } from "zod";

import cx from "@hsl/core/utils/cx";
import {
    type FormattedValue,
    type TopLevelDataObj,
} from "@hsl/fund-explorer/types";

// unicode non breaking space equal to the width of a fixed width number
export const FIGURE_SPACE = " ";
// unicode minus sign - a bit wider than a default hyphen
export const MINUS = "−";

export type ColDefMeta = ColumnMeta<TopLevelDataObj, FormattedValue>;

export const columnDisplayDirectivesSchema = z.object({
    pinOnMobile: z.boolean(),
    hideOnMobile: z.boolean(),
    hideOnDesktop: z.boolean(),
    withBackground: z.boolean().or(z.string()),
    withBackgroundHeader: z.boolean().or(z.string()),
    highlightText: z.boolean().or(z.string()),
    gapLeft: z.boolean(),
    gapRight: z.boolean(),
    tooltip: z.string(),
    reducedPadding: z.boolean(),
    noPadding: z.boolean(),
    boldHeader: z.boolean(),
    hideHeader: z.boolean(),
    alignCenter: z.boolean(),
    alignRight: z.boolean(),
    alignLeft: z.boolean(),
    alignOnDecimal: z.boolean(),
    suffix: z.string(),
    minWidth: z.string(),
});

export type ColumnDisplayDirectives = z.infer<
    typeof columnDisplayDirectivesSchema
>;

export type TabDisplayDirectives = {
    boldHeaders?: boolean;
    alignRight?: boolean;
    alignCenter?: boolean;
    alignLeft?: boolean;
};

export type DisplayDirectives = {
    style: {
        minWidth: string | undefined;
    };
    cellParentClassName: string;
    headerParentClassName: string;
    transformContext(context: any): any;
};

export function handleDisplayDirectives(
    directives: Partial<ColumnDisplayDirectives> = {},
    tableDirectives: Partial<TabDisplayDirectives> = {},
    meta: ColDefMeta,
    previousDirectives: Partial<ColumnDisplayDirectives> = {},
): DisplayDirectives {
    const {
        hideOnMobile,
        hideOnDesktop,
        hideHeader,
        pinOnMobile,
        withBackground,
        withBackgroundHeader,
        highlightText,
        gapLeft,
        gapRight,
        reducedPadding,
        noPadding,
        boldHeader,
        alignCenter,
        alignRight,
        alignLeft,
        alignOnDecimal,
        suffix,
        minWidth,
    } = directives;
    const { boldHeaders } = tableDirectives;
    const { cellParentClassName, headerParentClassName } = meta;

    const isAtEdgeOfBackground =
        withBackground && !previousDirectives.withBackground;

    const textCentre =
        alignCenter ||
        alignOnDecimal ||
        (!alignRight && !alignLeft && tableDirectives.alignCenter);
    const textLeft =
        alignLeft ||
        (!alignCenter &&
            !alignRight &&
            !alignOnDecimal &&
            tableDirectives.alignLeft);
    const textRight =
        alignRight ||
        (!alignCenter &&
            !alignLeft &&
            !alignOnDecimal &&
            tableDirectives.alignRight);

    function addHeaderClassNames() {
        return cx(
            "py-1.5 sm:py-1 px-2 [&:last-child]:pr-5 bg-clip-padding text-left",
            {
                "sticky left-0 bg-white z-10 pr-4": pinOnMobile,
                "hidden sm:table-cell": hideOnMobile,
                "sm:hidden": hideOnDesktop,
                "text-white/[0]": hideHeader,
                "text-center": textCentre || alignOnDecimal,
                "text-right": textRight,
                "text-left": textLeft,
                "pl-3": gapLeft || isAtEdgeOfBackground,
                "pr-3": gapRight,
                "px-1": reducedPadding,
                "px-0": noPadding,
                "bg-white-smoke": withBackgroundHeader,
            },
            {
                "font-medium": boldHeaders || boldHeader,
            },
            headerParentClassName,
        );
    }

    function addCellClassNames() {
        return cx(
            "py-1.5 sm:py-1 px-2 [&:last-child]:pr-5 bg-clip-padding",
            {
                "sticky left-0 bg-white z-10 pr-4": pinOnMobile,
                "hidden sm:table-cell": hideOnMobile,
                "sm:hidden": hideOnDesktop,
                "bg-white-smoke": withBackground,
                "text-core-blue font-[500]": highlightText,
                "bg-gapRight pr-3": withBackground && gapRight,
                "bg-gapLeft pl-3": withBackground && gapLeft,
                "pl-3": isAtEdgeOfBackground,
                "bg-gapX": withBackground && gapLeft && gapRight,
                "text-center": textCentre,
                "text-right": textRight,
                "text-left": textLeft,
                "text-center tabular-nums": alignOnDecimal,
                "px-1": reducedPadding,
                "px-0": noPadding,
            },
            cellParentClassName,
        );
    }

    function addSuffix(value: unknown) {
        if (!suffix) return value;
        if (typeof value !== "string") return value;
        return `${value} ${suffix}`;
    }

    return {
        style: {
            minWidth: minWidth,
        },
        cellParentClassName: addCellClassNames(),
        headerParentClassName: addHeaderClassNames(),
        transformContext(context) {
            return {
                ...context,
                getValue: () => {
                    if (alignOnDecimal) {
                        const allValues = context.table
                            .getRowModel()
                            .rows.map((row) => row.getAllCells())
                            .flatMap((row) =>
                                row.filter(
                                    (cell) =>
                                        cell.column.id === context.column.id,
                                ),
                            )
                            .map((cell) => cell.getValue<string>())
                            .filter((v) => typeof v === "string" && v !== "-");
                        const largestValue = Math.max(
                            ...allValues.map((v) => v.length),
                        );
                        const value = context.getValue<string>();

                        if (typeof value !== "string" || value === "-") {
                            return value;
                        }

                        return addSuffix(
                            value
                                // pad with unicode non-breaking figure space
                                .padStart(largestValue, FIGURE_SPACE)
                                // replace hyphen-minus with minus sign
                                .replace("-", MINUS),
                        );
                    } else {
                        return addSuffix(context.getValue());
                    }
                },
            };
        },
    } satisfies ColDefMeta;
}

export default handleDisplayDirectives;
