import Button from "../../../../components/Button";
import FundPageContent from "../../../../components/FundPageContent";
import { Printer as PrinterIcon } from "../../../../components/icons";
import SignUpForm from "../../../../components/SignUpForm";
import Skeleton from "../../../../components/Skeletons";
import { useManagerDiaryContext } from "../../context";
import { CompanyFilters } from "./CompanyFilters";
import { FilterCheckbox, FilterSection } from "./FilterContent";
import { PostTypeFilters } from "./PostTypeFilters";

export interface FiltersProps {
    formData: {
        fundName: string;
        partId: string;
    };
}

export const Filters = ({ formData }: FiltersProps) => {
    const {
        reverseOrder,
        isLoadingFilters,
        setReverseOrder,
        clearFilters,
        postTypes,
    } = useManagerDiaryContext();

    if (isLoadingFilters && postTypes.length === 0)
        return <Skeleton height={900} />;

    return (
        <FundPageContent.Box
            containerClassName="h-full sm:h-auto"
            className="min-h-screen sm:min-h-0"
        >
            <div className="flex items-center justify-between">
                <FundPageContent.Header>Filter by</FundPageContent.Header>
                <button
                    className="text-sm underline sm:text-base"
                    onClick={clearFilters}
                >
                    Clear filters
                </button>
            </div>
            <PostTypeFilters />
            <CompanyFilters />
            <FilterSection className="flex items-center justify-between">
                <FilterCheckbox
                    label="Reverse order"
                    value={""}
                    selected={reverseOrder}
                    onChange={() => setReverseOrder(!reverseOrder)}
                />
            </FilterSection>
            <FilterSection>
                <SignUpForm
                    fundName={formData.fundName}
                    partVersionId={formData.partId}
                    label="Sign up for new Post alerts"
                />
            </FilterSection>
        </FundPageContent.Box>
    );
};
