import { z } from "zod";

import {
    advancedDataTableSchema,
    dataPartSchema,
    formPartSchema,
} from "@hsl/fund-page/schemas";

import FundPageContent from "../../../components/FundPageContent";
import SignUpForm from "../../../components/SignUpForm";

export const topHoldingsSchema = dataPartSchema.extend({
    part: z.object({
        holdings: advancedDataTableSchema,
        holdingsSignUpForm: formPartSchema,
    }),
});

export const TopHoldings = ({ part }: z.infer<typeof topHoldingsSchema>) => {
    const { data, title } = part.holdings.part;
    const { partVersionId, fundName } = part.holdingsSignUpForm.part.data;

    return (
        <>
            <FundPageContent.Header>{title}</FundPageContent.Header>
            <FundPageContent.KeyValueList
                data={data}
                numbered
                rightAlignValue
                withSumTotal
                valueClassName="font-semibold"
            />
            <SignUpForm
                label="Sign up for a full list of holdings"
                fundName={fundName}
                partVersionId={partVersionId}
                buttonClassNames="mt-2 sm:mt-4"
            />
        </>
    );
};
