import * as React from "react";

const SvgFca = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={21}
        height={22}
        fill="none"
        {...props}
    >
        <g clipPath="url(#fca_svg__a)">
            <path
                fill="#016560"
                d="M1 6.579h2v12.7h3V8.39h3V19.28h3V8.39h3V19.28h3v-12.7h2c.253.01.502-.08.69-.25.187-.17.299-.406.31-.658a.874.874 0 0 0-.417-.734l-.015-.008-.025-.02L11 .025a5.797 5.797 0 0 0-.999 0L.456 4.91l-.026.016-.014.01a.889.889 0 0 0-.415.733c.01.252.121.49.309.66.187.17.435.261.69.25Zm1 13.608-2 1.814h21l-2-1.812-17-.002Z"
            />
        </g>
        <defs>
            <clipPath id="fca_svg__a">
                <path fill="#fff" d="M0 0h21v22H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgFca;
