import { useCallback, useMemo } from "react";
import dayjs from "dayjs";

import { type PerformanceChartPeriod } from "@hsl/core/fund-page/schemas";

import DEFAULT_LABELS from "../helpers/defaultLabels";
import { ONE_MONTH, ONE_YEAR } from "../helpers/times";

interface Props {
    startTime: Date | undefined;
    endTime: Date | undefined;
    asAtDate: Date | undefined;
    earliestStartDate: Date | undefined;
    options: PerformanceChartPeriod[];
    activePeriods?: PerformanceChartPeriod[];
    labels?: Partial<Record<PerformanceChartPeriod, string>>;
    shortLabels?: Partial<Record<PerformanceChartPeriod, string>>;
    significantPerformanceDate?: Date;
}

const usePeriodOptions = ({
    startTime,
    endTime,
    asAtDate,
    earliestStartDate,
    options,
    activePeriods,
    labels,
    shortLabels,
    significantPerformanceDate,
}: Props) => {
    const getLabel = useCallback(
        (option: PerformanceChartPeriod) => {
            return (labels && labels[option]) ?? DEFAULT_LABELS[option];
        },
        [labels],
    );

    const getShortLabel = useCallback(
        (option: PerformanceChartPeriod) => {
            return (shortLabels && shortLabels[option]) ?? option.toUpperCase();
        },
        [shortLabels],
    );

    return useMemo(() => {
        if (startTime && endTime && asAtDate && earliestStartDate) {
            return options
                .map((option) => {
                    let showOption = false;
                    let isActive = false;

                    const TIME_DIFF =
                        asAtDate.valueOf() - earliestStartDate.valueOf();
                    const startTimeDjs = dayjs(startTime);
                    const endTimeDjs = dayjs(endTime);

                    const activeTimeDifference =
                        endTime.valueOf() - startTime.valueOf();

                    switch (option) {
                        case "sspd":
                            showOption = true;
                            isActive = dayjs(significantPerformanceDate).isSame(
                                startTimeDjs,
                                "month",
                            );
                            break;
                        case "sl":
                            showOption = TIME_DIFF < 10 * ONE_YEAR;
                            isActive = dayjs(activeTimeDifference).isSame(
                                TIME_DIFF,
                                "month",
                            );
                            break;
                        case "10y":
                            showOption = TIME_DIFF > 10 * ONE_YEAR;
                            isActive = dayjs(activeTimeDifference).isSame(
                                10 * ONE_YEAR,
                                "month",
                            );
                            break;
                        case "5y":
                            showOption = TIME_DIFF > 5 * ONE_YEAR;
                            isActive = dayjs(activeTimeDifference).isSame(
                                5 * ONE_YEAR,
                                "month",
                            );
                            break;
                        case "3y":
                            showOption = TIME_DIFF > 3 * ONE_YEAR;
                            isActive = dayjs(activeTimeDifference).isSame(
                                3 * ONE_YEAR,
                                "month",
                            );
                            break;
                        case "1y":
                            showOption = TIME_DIFF > ONE_YEAR;
                            isActive = startTimeDjs
                                .add(1, "year")
                                .isSame(endTimeDjs, "month");

                            break;
                        case "3m":
                            showOption = TIME_DIFF > 3 * ONE_MONTH;
                            isActive = startTimeDjs
                                .add(3, "months")
                                .isSame(endTimeDjs, "month");
                            break;
                        case "1m":
                            showOption = TIME_DIFF > ONE_MONTH;
                            isActive = dayjs(activeTimeDifference).isSame(
                                ONE_MONTH,
                                "day",
                            );
                            break;
                    }
                    return {
                        option,
                        label: getLabel(option),
                        shortLabel: getShortLabel(option),
                        showOption,
                        isActive,
                    };
                })
                .filter(
                    (x) =>
                        (!activePeriods && x.showOption) ||
                        (activePeriods && activePeriods.includes(x.option)),
                );
        }
    }, [
        startTime,
        endTime,
        asAtDate,
        earliestStartDate,
        options,
        getLabel,
        getShortLabel,
        activePeriods,
        significantPerformanceDate,
    ]);
};

export default usePeriodOptions;
