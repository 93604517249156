import { useSyncExternalStore } from "react";

interface Props {
    children: React.ReactNode;
    fallback?: React.ReactNode;
}

export const ClientOnly = ({ children, fallback = null }: Props) => {
    const isClient = useClientOnly();

    if (!isClient) return fallback;

    return children;
};

export const useClientOnly = () => {
    return useSyncExternalStore(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => () => {},
        () => true,
        () => false,
    );
};
