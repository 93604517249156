import * as React from "react";

const SvgArrowRightGreen = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={10}
        fill="none"
        {...props}
    >
        <path
            fill="#016560"
            d="M10.3 4.247a.71.71 0 1 1 0 1.42H.71a.71.71 0 1 1 0-1.42h9.59Z"
        />
        <path
            fill="#016560"
            d="M6.246.208a.71.71 0 0 1 1.005 0l4.247 4.247.502.502-4.75 4.75a.71.71 0 0 1-1.004-1.005l3.745-3.745-3.745-3.744a.71.71 0 0 1-.041-.96l.041-.045Z"
        />
    </svg>
);
export default SvgArrowRightGreen;
