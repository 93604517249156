import React, { type PropsWithChildren } from "react";

import cx from "@hsl/core/utils/cx";

type TableRowProps = {
    withBorder?: boolean;
    className?: string;
} & PropsWithChildren;

const TableRow = ({
    children,
    withBorder = false,
    className,
}: TableRowProps) => (
    <tr className={cx(withBorder && "border-platinum border-b", className)}>
        {children}
    </tr>
);

export default TableRow;
