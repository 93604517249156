import cx from "@hsl/core/utils/cx";

interface Props {
    value: number;
    minValue: number;
    maxValue: number;
    barClassNames?: string[];
    barContainerClassName?: string;
}

export const ZeroAlignedBar = ({
    value,
    minValue,
    maxValue,
    barClassNames = [],
    barContainerClassName,
}: Props) => {
    return (
        <div
            className={cx(
                "flex h-3 min-w-[75px] items-stretch sm:min-w-[165px]",
                barContainerClassName,
            )}
        >
            <div
                className="flex items-stretch justify-end"
                style={{
                    flex: Math.max(Math.abs(minValue), 0),
                }}
            >
                {value < 0 && (
                    <div
                        className={barClassNames[0]}
                        style={{
                            width: `${Math.abs(value / minValue) * 100}%`,
                        }}
                    />
                )}
            </div>
            <div
                className="flex items-stretch"
                style={{
                    flex: Math.max(maxValue, 0),
                }}
            >
                {value > 0 && (
                    <div
                        className={barClassNames[1] ?? barClassNames[0]}
                        style={{
                            width: `${Math.abs(value / maxValue) * 100}%`,
                        }}
                    />
                )}
            </div>
        </div>
    );
};
