import { AdvancedDataTableSchema } from "@hsl/fund-page/schemas";

import FundPageContent from "../../../components/FundPageContent";

export const IncomeAndTaxStatus = ({
    title,
    part,
}: AdvancedDataTableSchema) => {
    return (
        <div className="flex h-full flex-col justify-between">
            <div>
                <FundPageContent.Header>{title}</FundPageContent.Header>
                <FundPageContent.KeyValueList data={part.data} />
            </div>
            <FundPageContent.Footnote withBorder data={part.note} />
        </div>
    );
};
