import * as React from "react";

const SvgAvatar = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <g clipPath="url(#avatar_svg__a)">
            <path
                fill="#016560"
                d="M9.5 9.5a4.75 4.75 0 1 0 0-9.5 4.75 4.75 0 0 0 0 9.5Zm0 2.375c-3.17 0-9.5 1.591-9.5 4.75V19h19v-2.375c0-3.159-6.33-4.75-9.5-4.75Z"
            />
        </g>
        <defs>
            <clipPath id="avatar_svg__a">
                <path fill="#fff" d="M0 0h19v19H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgAvatar;
