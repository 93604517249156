import { type ShareClassOption } from "@hsl/fund-page/schemas";

import Dropdown from "../../../components/Dropdown";
import { usePerformanceContext } from "../context";

const PerformanceShareClassSelector = () => {
    const { shareClasses, activeShareClass, setActiveShareClass } =
        usePerformanceContext();
    return (
        <div className="flex items-center">
            <span className="mr-2 hidden text-base sm:inline">
                Choose share class
            </span>
            <Dropdown<ShareClassOption>
                value={activeShareClass}
                options={shareClasses}
                toLabel={(val) => val.name}
                onChange={setActiveShareClass}
            />
        </div>
    );
};

export default PerformanceShareClassSelector;
