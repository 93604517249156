import { z } from "zod";

import { dataPartSchema, postListItemSchema } from "@hsl/fund-page/schemas";

import { ButtonWithArrow } from "../../../components/Button";
import { ListContainer as CardListContainer } from "../../../components/Card/Card";
import FundPageContent from "../../../components/FundPageContent";
import GreyCurveContainer from "../../../components/GreyCurveContainer";
import NewsAndViewsCard from "../../../components/NewsAndViewsCard";
import usePageSection from "../../../hooks/usePageSection";
import useRelatedFunds from "../../../hooks/useRelatedFunds";

const newsAndViewsSchema = dataPartSchema.extend({
    data: z.array(postListItemSchema),
});
export const SingleEventReadNewsAndViews = () => {
    const { data, title } = usePageSection("newsAndViews", newsAndViewsSchema);

    const relatedFundIds = useRelatedFunds();

    const postsFilteredByFund = data.filter((post) => {
        const postFundIds = post.funds.map((x) => x.id);
        return relatedFundIds.some((x) => x && postFundIds.includes(x));
    });

    if (postsFilteredByFund.length === 0) {
        return null;
    }

    return (
        <>
            <GreyCurveContainer>
                <CardListContainer>
                    <div>
                        <FundPageContent.Header h2 className="font-extralight">
                            {title}
                        </FundPageContent.Header>
                        <CardListContainer>
                            {postsFilteredByFund.slice(0, 3).map((post) => (
                                <NewsAndViewsCard key={post.id} {...post} />
                            ))}
                        </CardListContainer>
                    </div>
                </CardListContainer>
                <ButtonWithArrow className="mt-6" href="/News-Views">
                    More news and views
                </ButtonWithArrow>
            </GreyCurveContainer>
        </>
    );
};
