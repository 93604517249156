import React from "react";

type TabsContextType = {
    activeTabIndex: number;
    switchingTabs: boolean;
    toggleSwitching: () => void;
    setActiveTabIndex: (i: number) => void;
};

export const TabsContext = React.createContext<TabsContextType | undefined>(
    undefined,
);

interface Props {
    children: React.ReactNode;
    activeIndex?: number;
}

export const TabsProvider = ({ children, activeIndex }: Props) => {
    const [isSwitching, setIsSwitching] = React.useState(false);
    const [activeTabIndex, setActiveTabIndex] = React.useState(
        activeIndex ?? 0,
    );

    const contextValue = React.useMemo(
        () => ({
            activeTabIndex,
            switchingTabs: isSwitching,
            toggleSwitching: () => {
                setIsSwitching(true);
                setTimeout(() => {
                    setIsSwitching(false);
                }, 500);
            },
            setActiveTabIndex,
        }),
        [activeTabIndex, isSwitching, setActiveTabIndex],
    );

    return (
        <TabsContext.Provider value={contextValue}>
            {children}
        </TabsContext.Provider>
    );
};

export function useTabsContext() {
    const Tabs = React.useContext(TabsContext);
    if (!TabsContext || !Tabs) throw Error("No tabs context");
    return Tabs;
}
