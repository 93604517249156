import { cloneElement, forwardRef, useMemo, type ReactElement } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";

import ClientOnly from "@hsl/components/ClientOnly";
import { dataPartSchema, formDataSchema } from "@hsl/fund-page/schemas";

import { ButtonWithArrow } from "../../../components/Button";
import { Avatar, BriefCase, Diet, Fca, Mail } from "../../../components/icons";
import InfoMessage from "../../../components/InfoMessage";
import TextInput, {
    type Props as TextInputProps,
} from "../../../components/TextInput";
import usePageSection from "../../../hooks/usePageSection";
import useSignUpForm from "../../../hooks/useSignUpForm";

const eventFormSchema = dataPartSchema.extend({
    data: formDataSchema,
});

type EventForm = z.infer<typeof eventFormSchema>;

const eventFormValidationSchema = z.object({
    "First name": z.string().min(1),
    "Last name": z.string().min(1),
    "Job title": z.string().optional(),
    Email: z.string().email(),
    Company: z.string().min(1),
    "FCA registration": z.string().optional(),
    "Dietary requirements": z.string().optional(),
    part_version_id: z.string(),
    fund_name: z.string(),
    "Event ID": z.string(),
});

export type EventFormArgs = z.infer<typeof eventFormValidationSchema>;

const EventFormPortals = () => {
    const { data, portal } = usePageSection("form", eventFormSchema);

    const formElem = useMemo(
        () => document.querySelector<HTMLElement>("#registration-form"),
        [],
    );

    if (!formElem) {
        throw new Error("No form element found");
    }

    const portalElems = useMemo(
        () => document.querySelectorAll(`[data-react-portal="${portal}"]`),
        [portal],
    );

    if (!portalElems || portalElems.length === 0) {
        return null;
    }

    return (
        <>
            {Array.from(portalElems).map((portal) =>
                createPortal(
                    <EventFormElement
                        fundName={data.fundName}
                        partVersionId={data.partVersionId}
                        event_id={formElem.dataset.eventId}
                    />,
                    portal,
                ),
            )}
        </>
    );
};

const EventFormElement = ({
    fundName,
    partVersionId,
    event_id,
}: Pick<EventForm["data"], "fundName" | "partVersionId" | "event_id">) => {
    const { success, onSubmit } = useSignUpForm("", 5000);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<EventFormArgs>({
        defaultValues: {
            "First name": "",
            "Last name": "",
            "Job title": "",
            Email: "",
            Company: "",
            "FCA registration": "",
            "Dietary requirements": "",
            fund_name: fundName,
            part_version_id: partVersionId,
            "Event ID": event_id,
        },
        resolver: zodResolver(eventFormValidationSchema),
    });

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex w-full flex-col gap-8 md:flex-row">
                    <div className="flex flex-1 flex-col gap-y-4">
                        <EventFormInput
                            {...register("First name")}
                            placeholder="First name"
                            error={errors["First name"]}
                            Icon={<Avatar />}
                        />
                        <EventFormInput
                            {...register("Last name")}
                            placeholder="Last name"
                            error={errors["Last name"]}
                        />
                        <EventFormInput
                            {...register("Job title")}
                            placeholder="Job title (optional)"
                            error={errors["Job title"]}
                        />
                        <EventFormInput
                            {...register("Email")}
                            placeholder="Email"
                            error={errors["Email"]}
                            Icon={<Mail />}
                        />
                    </div>
                    <div className="flex flex-1 flex-col gap-y-4">
                        <EventFormInput
                            {...register("Company")}
                            placeholder="Company"
                            error={errors["Company"]}
                            Icon={<BriefCase />}
                        />
                        <EventFormInput
                            {...register("FCA registration")}
                            placeholder="FCA registration number (optional)"
                            error={errors["FCA registration"]}
                            Icon={<Fca />}
                        />
                        <EventFormInput
                            {...register("Dietary requirements")}
                            placeholder="Dietary requirements (optional)"
                            error={errors["Dietary requirements"]}
                            Icon={<Diet />}
                        />
                        <div className="flex justify-end md:pl-[35px]">
                            <ButtonWithArrow
                                type="submit"
                                className="w-52 rounded-xl py-2 pl-3 pr-4 text-base font-extralight lg:w-full"
                            >
                                Submit
                            </ButtonWithArrow>
                        </div>
                    </div>
                </div>
            </form>
            {typeof success === "boolean" && (
                <InfoMessage
                    className="mt-4"
                    severity={success ? "info" : "warning"}
                    html={
                        success
                            ? "Registration submitted successfully"
                            : "There was an error with your submission"
                    }
                />
            )}
        </div>
    );
};

export default function EventFormClientOnly() {
    return (
        <ClientOnly>
            <EventFormPortals />
        </ClientOnly>
    );
}

const EventFormInput = forwardRef(
    (props: TextInputProps & { Icon?: ReactElement }, ref) => {
        const { Icon, ...rest } = props;
        return (
            <div className="flex items-center">
                <div className="min-w-[35px]">
                    {Icon && cloneElement(Icon, { className: "mr-2" })}
                </div>
                <TextInput ref={ref} {...rest} />
            </div>
        );
    },
);
