import { useCallback, useEffect, useMemo, useState } from "react";
import { Provider as WrapProvider } from "react-wrap-balancer";

import ClientOnly, { useClientOnly } from "@hsl/components/ClientOnly";
import { BreakpointProvider } from "@hsl/core/hooks/useBreakpoint";
// import { useScrollIntoView } from "@hsl/fund-page/hooks";
import {
    ActiveSectionContextProvider,
    useActiveSectionContext,
} from "@hsl/fund-page/hooks/useActiveSection";
import { SidebarProvider } from "@hsl/fund-page/hooks/useSidebarToggle";
import { GenericErrorBoundary } from "@hsl/lgim-explorer/src/components/GenericErrorBoundary/GenericErrorBoundary";

import Nav from "../components/Nav";
import { FundPageSkeleton } from "../components/Skeletons";
import useComputedPageSections from "../hooks/useComputedPageSections";
import useIntheLoupe from "../hooks/useIntheLoupe";
// import useTopOffset from "../hooks/useTopOffset";
import useFundPageStore from "../store/store";
import CommentaryAndPortfolio from "./CommentaryAndPortfolio";
import FundFacts from "./FundFacts";
import NewsAndViews from "./FundPageNewsAndViews";
import Literature from "./Literature";
import ManagerDiary from "./ManagerDiary";
import Overview from "./Overview/Overview";
import Performance from "./Performance";
import PricesAndDividends from "./PricesAndDividends";
import RelatedFunds from "./RelatedFunds";

const FundPageView = () => {
    const {
        loading,
        config: { pageTitle },
    } = useFundPageStore((store) => store);
    useEffect(() => {
        if (pageTitle) {
            document.title = pageTitle;
        }
    }, [pageTitle]);
    // const offset = useTopOffset([210, 190, 200]); -> need to take this out for the SSR to work
    return (
        <SidebarProvider>
            <BreakpointProvider>
                <WrapProvider>
                    <ActiveSectionContextProvider
                        delayScroll={100}
                        scrollOffset={200}
                    >
                        <GenericErrorBoundary>
                            {loading ? <FundPageSkeleton /> : <Content />}
                        </GenericErrorBoundary>
                    </ActiveSectionContextProvider>
                </WrapProvider>
            </BreakpointProvider>
        </SidebarProvider>
    );
};

export default FundPageView;

const Content = () => {
    const { activeSection, setActiveSection, setObserverDisabled } =
        useActiveSectionContext();
    const pageSections = useFundPageStore((store) => store.pageSections);
    const isClient = useClientOnly();

    const [hiddenSections, toggleHiddenSections] = useState<string[]>([]);
    const computedPageSections = useComputedPageSections(pageSections);
    const intheLoupeAccess = useIntheLoupe();

    const handleNoData = useCallback((key: string, hide: boolean) => {
        toggleHiddenSections((prev) =>
            hide ? [...prev, key] : prev.filter((k) => k !== key),
        );
    }, []);

    useEffect(() => {
        const activePageSection = window.location.hash;
        if (activePageSection === "#intheLoupe") {
            setObserverDisabled(true);
            // timeout required for SSR page - unsure why
            setTimeout(() => {
                setActiveSection("intheLoupe");
            });
        }
    }, [setActiveSection, setObserverDisabled]);

    const PageSections = useMemo(
        () => (
            <>
                {computedPageSections.map(({ key }) => {
                    if (hiddenSections.includes(key)) return null;
                    switch (key) {
                        case "overview":
                            return <Overview key={key} dataKey={key} />;
                        case "newsAndViews":
                            return (
                                <ClientOnly key={key}>
                                    <NewsAndViews
                                        dataKey={key}
                                        onNoData={handleNoData}
                                    />
                                </ClientOnly>
                            );
                        case "commentaryAndPortfolio":
                            return (
                                <CommentaryAndPortfolio
                                    key={key}
                                    dataKey={key}
                                />
                            );
                        case "performance":
                            return <Performance key={key} dataKey={key} />;
                        case "pricesAndDividends":
                            return (
                                <ClientOnly key={key}>
                                    <PricesAndDividends
                                        key={key}
                                        dataKey={key}
                                    />
                                </ClientOnly>
                            );
                        case "fundFacts":
                            return <FundFacts key={key} dataKey={key} />;
                        case "literature":
                            return <Literature key={key} dataKey={key} />;
                        default:
                            return null;
                    }
                })}
                <RelatedFunds />
            </>
        ),
        [computedPageSections],
    );

    return (
        <div className="bg-offWhite max-w-screen relative z-0 min-h-screen">
            {/* <Banner /> */}
            <Nav pageSections={computedPageSections} />
            <div>
                {activeSection === "intheLoupe" &&
                intheLoupeAccess &&
                isClient ? (
                    <ClientOnly>
                        <ManagerDiary />
                    </ClientOnly>
                ) : (
                    PageSections
                )}
            </div>
        </div>
    );
};
