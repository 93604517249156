import {
    type DocsData,
    type DocumentResponse,
    type LitAPIParams,
    type LitStructureAPIParams,
    type ParamVal,
    type SortableByRank,
    type StructureResponse,
} from "@hsl/core/documents/types";

export async function fetchDocStructure(
    confId: number,
    params: LitStructureAPIParams,
    baseURL = "",
) {
    const { activeShareClassId } = params;
    const createParams = (
        params: LitAPIParams | LitStructureAPIParams,
    ): string => {
        const reducer = (
            urlparams: URLSearchParams,
            [key, value]: [string, ParamVal],
        ): URLSearchParams => {
            if (Array.isArray(value)) {
                return value.reduce(
                    (urlparams: URLSearchParams, v: string | number) =>
                        reducer(urlparams, [key, v]),
                    urlparams,
                );
            }
            urlparams.append(key, `${value}`);
            return urlparams;
        };
        return Object.entries(params)
            .reduce(reducer, new URLSearchParams())
            .toString();
    };

    const byRank =
        (order: "asc" | "desc") => (a: SortableByRank, b: SortableByRank) =>
            order === "asc" ? a.rank - b.rank : b.rank - a.rank;
    const byRankAsc = byRank("asc");

    const setsRes = await fetch(
        `${baseURL}/srp/api/literature-structure/${confId}/?${createParams(
            params,
        )}`,
    );
    const sets = (await setsRes.json()) as StructureResponse;
    const setsWithDocs = sets.data.filter((set) => set.document_count > 0);
    const setsWithDocsParams = activeShareClassId
        ? { ...params, share_classes: activeShareClassId }
        : params;
    const docsRes = await Promise.all(
        setsWithDocs.map((set) =>
            fetch(
                `${baseURL}/srp/api/documents/?${createParams({
                    ...setsWithDocsParams,
                    lit_types: set.lit_types,
                    date_from: set.show_history
                        ? set.date_from ?? "1970-01-01"
                        : "",
                })}`,
            ),
        ),
    );
    const docs = (
        (await Promise.all(
            docsRes.map((response) => response.json()),
        )) as DocumentResponse[]
    ).map((docRes) => ({
        ...docRes,
        data: docRes.data.slice().sort(byRankAsc),
    }));

    return {
        data: setsWithDocs
            .sort(byRankAsc)
            .map((set, i) => [set, docs[i]?.data ?? []]) as DocsData,
        settings: sets.settings,
    };
}
