// import { usePlatformAvailability } from "@hsl/core/fund-page/hooks";

import FundPageContent from "../../../components/FundPageContent";
import Skeleton from "../../../components/Skeletons";
import usePlatformAvailability from "../../../hooks/usePlaformAvailability";
import useFundPageStore from "../../../store/store";

const PlatformAvailability = () => {
    const { fundId, base_url } = useFundPageStore((store) => store.config);

    const Query = usePlatformAvailability(fundId, base_url);

    if (Query.isLoading) {
        return <Skeleton height={"200px"} width={"100%"} />;
    }
    if (Query.isError || !Query.data || Query.data.length === 0) {
        return null;
    }

    return (
        <FundPageContent.Box>
            <FundPageContent.Header>
                Platform availability
            </FundPageContent.Header>
            <div className="row-gap-8 grid grid-cols-2 flex-wrap gap-x-12 gap-y-8 py-4 sm:flex">
                {Query.data.map(({ label, logo, url }) => (
                    <a key={label} href={url} target="_blank">
                        <img
                            alt={label}
                            src={`${base_url}/${logo}`}
                            className="max-h-[35px] w-auto"
                        />
                    </a>
                ))}
            </div>
        </FundPageContent.Box>
    );
};

export default PlatformAvailability;
