import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

export const platformAvailabilityObj = z.object({
    formal_name: z.string(),
    label: z.string(),
    logo: z.string(),
    url: z.string(),
});

export type PlatformAvailabilityObj = z.infer<typeof platformAvailabilityObj>;

const schema = z.object({
    data: z.array(platformAvailabilityObj),
});

function usePlatformAvailability(fundId: number, baseURL?: string) {
    const query = useQuery({
        queryKey: ["platformAvailability", fundId],
        enabled: true,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const res = await fetch(
                `${baseURL ?? ""}/srp/api/platform/?fund=${fundId}`,
            );
            const json = await res.json();
            const parsed = schema.parse(json);
            return parsed.data;
        },
    });
    return query;
}

export default usePlatformAvailability;
