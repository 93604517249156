import type { z } from "zod";

import { textCollectionPartSchema } from "@hsl/fund-page/schemas";

import TextDataPart from "../../../components/TextDataPart";

export const glossarySchema = textCollectionPartSchema.optional();

export type GlossarySchema = z.infer<typeof glossarySchema>;

export const Glossary = (props: GlossarySchema) => {
    if (!props?.part) {
        return null;
    }
    return (
        <TextDataPart
            data={props.part.data.text}
            className="[&_strong]:text-primary"
        />
    );
};
