import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
// import { useBreakpointContext } from 'hsl-core/hooks';
import produce from "immer";
import { z } from "zod";

import { toSearchParams } from "@hsl/core/utils/toSearchParams";

/**************************************
 *  TYPES
 **************************************/

export type InsightsFiltersParams = {
    states?: number[];
    authors?: number[];
    categories?: number[];
    fund_groups?: number[];
    fund_types?: number[];
    types?: string[];
    audience: number;
    funds?: number[];
};

export const filtersResponseItemSchema = z.object({
    count: z.number(),
    id: z.number().or(z.string()),
    name: z.string(),
    tooltip: z.string().optional(),
});
export type FiltersResponseItem = z.infer<typeof filtersResponseItemSchema>;

export const filtersResponseSchema = z.object({
    authors: z.array(filtersResponseItemSchema).optional(),
    categories: z.array(filtersResponseItemSchema).optional(),
    fundGroups: z.array(filtersResponseItemSchema).optional(),
    fundTypes: z.array(filtersResponseItemSchema).optional(),
    types: z.array(filtersResponseItemSchema).optional(),
    customTags: z.array(filtersResponseItemSchema).optional(),
    funds: z.array(filtersResponseItemSchema).optional(),
});
export type FiltersResponse = z.infer<typeof filtersResponseSchema>;

export type FilterKey = keyof FiltersResponse;

type Args = InsightsFiltersParams & { baseUrl?: string };

/**************************************
 *  FETCH
 **************************************/

async function fetchFiltersData({ baseUrl, ...params }: Args) {
    try {
        const searchParams = new URLSearchParams(
            toSearchParams<InsightsFiltersParams>(params),
        );
        const filtersRes = await fetch(
            `${
                baseUrl ?? ""
            }/srp/api/insight_filters?${searchParams.toString()}`,
        );
        const filtersData: FiltersResponse = await filtersRes.json();
        // We need to manipulate the data here to ensure that the filters obj always has an id
        // -> post_types is a pain - ideally the BE team should add ids
        const computedDataWithIds = Object.fromEntries(
            Object.keys(filtersData).map((filterKey) => {
                const filtersArr =
                    filtersData[filterKey as keyof FiltersResponse];
                return [
                    filterKey,
                    filtersArr?.map(
                        (filterObj) =>
                            ({
                                id: filterObj.id ?? filterObj.name,
                                name:
                                    filterObj.name === "webinar_external"
                                        ? "Webinar"
                                        : filterObj.name,
                                count: filterObj.count,
                            } as FiltersResponseItem),
                    ),
                ];
            }),
        );
        return filtersResponseSchema.parse(computedDataWithIds);
    } catch (error) {
        /* eslint-disable  no-console */
        console.error("Error fetching insights filters", error);
        throw error;
    }
}

/**************************************
 *  HOOK
 **************************************/

export function useInsightsFilters({
    baseUrl,
    ...params
}: InsightsFiltersParams & { baseUrl?: string }) {
    // const isMobile = !useBreakpointContext()?.isGreaterThan('sm');
    const [filters, setFilters] = useState<FiltersResponse>();

    const initialData = useQuery({
        queryKey: ["insightsFiltersInit", params, baseUrl],
        queryFn: async () => {
            const initialData = await fetchFiltersData({
                baseUrl,
                audience: params.audience,
                categories: params.categories,
                funds: params.funds,
            });
            if (!filters) {
                setFilters(initialData);
            }
            return initialData;
        },
        enabled: params.audience !== -1 && !filters,
    });

    const filtersApiData = useQuery({
        queryKey: ["insightsFilters", params, baseUrl],
        queryFn: async () => {
            const updatedData = await fetchFiltersData({ baseUrl, ...params });
            if (filters) {
                setFilters(
                    produce(filters, (draft) => {
                        Object.keys(filters).forEach((filterKey) => {
                            const currentFilters =
                                filters[filterKey as FilterKey]!;
                            const newFilters =
                                updatedData[filterKey as FilterKey]!;
                            const updatedFilters = currentFilters.map(
                                (filter) => {
                                    const filterToUpdate = newFilters.find(
                                        ({ id }) => id === filter.id,
                                    );
                                    if (filterToUpdate) {
                                        return {
                                            ...filter,
                                            count: filterToUpdate.count,
                                        };
                                    } else {
                                        return { ...filter, count: 0 };
                                    }
                                },
                            );
                            draft[filterKey as FilterKey] = updatedFilters;
                        });
                    }),
                );
            }
            return updatedData;
        },
        enabled: params.audience !== -1 && Boolean(filters),
    });

    return {
        filters,
        isLoading: filtersApiData.isLoading || initialData.isLoading,
    };
}
