import * as React from "react";

const SvgNewsAwards = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={41}
        height={41}
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            d="M32.785 16.435V8.983h-4.458V6.5H13.244v2.483H8.786V16.4c0 .355 0 2.804 1.954 4.969 1.885 2.057 4.183 3.122 8.194 3.193.309.532.48 1.136.48 1.775v4.613h-1.715c-.754 0-1.37.64-1.37 1.42h-1.715c-.754 0-1.37.64-1.37 1.42v.71h15.085v-.71c0-.78-.617-1.42-1.371-1.42h-1.714c0-.78-.617-1.42-1.371-1.42h-1.715v-4.577c0-.639.171-1.242.48-1.775 4.011-.07 6.307-1.17 8.194-3.193 1.954-2.129 1.954-4.614 1.954-4.968m-20.503 3.548c-1.508-1.597-1.44-3.372-1.44-3.443v-5.429h2.4v4.614c0 1.241.344 2.377.926 3.371 0 .035.514.781.789 1.065.685.816 1.406 1.491 2.056 2.165-2.4-.284-3.566-1.1-4.73-2.342m17.038-.001c-1.2 1.278-2.33 2.094-4.799 2.378.652-.674 1.406-1.35 2.056-2.165.275-.319.79-1.029.79-1.065a6.6 6.6 0 0 0 .925-3.372v-4.613h2.4v5.394c.033.071.102 1.846-1.371 3.443Z"
        />
    </svg>
);
export default SvgNewsAwards;
