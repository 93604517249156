import * as React from "react";

const SvgBriefCase = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={21}
        fill="none"
        {...props}
    >
        <g clipPath="url(#briefCase_svg__a)">
            <path
                fill="#016560"
                d="M16.077 4.375V2.21c0-.29-.054-.578-.161-.847a2.21 2.21 0 0 0-.463-.718 2.126 2.126 0 0 0-.694-.478A2.07 2.07 0 0 0 13.94 0H8.06c-.28 0-.56.056-.819.167-.26.11-.495.273-.694.478a2.21 2.21 0 0 0-.463.718 2.269 2.269 0 0 0-.16.847v2.165H0V18.79c0 .29.054.579.161.847.107.268.265.512.463.718.199.205.435.368.694.479.26.11.538.167.819.166h17.726c.281 0 .56-.056.819-.166.26-.111.495-.274.694-.48.198-.205.356-.449.463-.717.107-.268.162-.556.161-.847V4.375h-5.923Zm-2.115 0H8.039V2.188h5.923v2.187Z"
            />
        </g>
        <defs>
            <clipPath id="briefCase_svg__a">
                <path fill="#fff" d="M0 0h22v21H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgBriefCase;
