import type { z } from "zod";

import { textPartSchema } from "@hsl/fund-page/schemas";

import TextDataPart from "../../../components/TextDataPart";

export const importantInformationSchema = textPartSchema;

export type ImportantInformationSchema = z.infer<
    typeof importantInformationSchema
>;

export const ImportantInformation = ({ part }: ImportantInformationSchema) => {
    return <TextDataPart data={part.data} />;
};
