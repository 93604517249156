/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { produce } from "immer";
import { parse as json5Parse } from "json5";
import { mergeAll } from "ramda";

import {
    bannerPartSchema,
    DATA_ATTRIBUTES,
    PostListItem,
    type BannerData,
    type Config,
    type ConfigPageSection,
    type DataCanvasSection,
    type DataPartType,
    type ElemData,
    type FundPagePartDataArgs,
    type PageSection,
    type PerformanceChartArgs,
    type PerformanceChartResponseSchema,
    type PricesPartArgs,
    type ScriptContentReturn,
} from "./schemas";

type FundPageServicesConfig = Pick<
    Config,
    | "audienceId"
    | "routeId"
    | "fundId"
    | "base_url"
    | "languageId"
    | "fetchLanguageAware"
    | "pageSections"
    | "usertypeShortName"
    | "jurisdictionShortName"
    | "postId"
    | "version"
>;
type OverrideKeys = { productType?: Config["productType"] };

export async function getDataFromPart(args: FundPagePartDataArgs) {
    const { baseUrl, partId } = args;

    const searchParams = createSearchParams(args);

    const res = await fetch(
        `${baseUrl ?? ""}/srp/api/part?${searchParams.toString()}`,
    );

    if (!res.ok) {
        throw new Error(
            `Failed to fetch part ${partId} - ${res.status} ${res.statusText}`,
        );
    }
    const html = await res.text();

    if (html) {
        const parsedData = parsePartForData(html, args);
        return parsedData;
    }
    return undefined;
}

function getContentContainer(template: HTMLTemplateElement): {
    contentContainer: HTMLElement;
    partType?: string;
    parseAsScript?: boolean;
    parseAsText?: boolean;
    dataPartType?: string;
} {
    const allContentContainers = Array.from(template.content.children).filter(
        (child) => child.children.length > 0,
    );

    const contentContainer = allContentContainers[0] as HTMLElement;

    const partId = (template.content.children[0] as HTMLElement)?.dataset
        .part_id;

    if (partId) {
        if (!contentContainer) {
            console.warn(
                "%cerror services.tsx No content present ",
                "color: orange; display: block; width: 100%;",
                `Part ${partId}`,
            );
        } else if (allContentContainers.length > 1) {
            console.log(
                "%cerror services.tsx Multiple data parts found ",
                "color: orange; display: block; width: 100%;",
                `Part ${partId}`,
            );
        }
    }

    const partType = contentContainer?.dataset.part_type;
    const scriptContainer = contentContainer?.querySelector(
        "[data-parse-as-script]",
    );
    const parseAsScript = partType !== "CanvasInstance" && !!scriptContainer;

    const textContainer = contentContainer?.querySelector(
        "[data-parse-as-text]",
    );
    const parseAsText = partType !== "CanvasInstance" && !!textContainer;

    const dataPartType = (scriptContainer as HTMLElement)?.dataset.dataType;

    return {
        contentContainer,
        partType,
        parseAsScript,
        parseAsText,
        dataPartType,
    };
}

export function createTemplate() {
    let template: HTMLTemplateElement;

    if (typeof document !== "undefined") {
        template = document.createElement("template");
    } else {
        // needs to be require so that it's synchronous
        // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/consistent-type-imports
        const { JSDOM } = require("jsdom") as typeof import("jsdom");
        const dom = new JSDOM("<!doctype html><html><body></body></html>");
        const document = dom.window.document;
        template = document.createElement("template");
    }

    return template;
}

function parsePartForData(html: string, args: FundPagePartDataArgs): any {
    const template = createTemplate();
    template.innerHTML = html.trim();

    const {
        contentContainer,
        partType,
        parseAsScript,
        parseAsText,
        dataPartType,
    } = getContentContainer(template);

    if (!partType) {
        return undefined;
    }

    // allows the use of advancedHTML as script content
    if (parseAsScript) {
        const advancedHTMLScriptData = getScriptContent(contentContainer);
        if (!advancedHTMLScriptData) {
            return undefined;
        }
        return {
            type: dataPartType ?? partType,
            ...advancedHTMLScriptData,
        };
    }
    if (parseAsText) {
        const advancedHTMLTextData = getScriptContent(contentContainer);
        if (!advancedHTMLTextData) {
            return undefined;
        }
        return {
            type: dataPartType ?? partType,
            data: advancedHTMLTextData,
        };
    }

    let dataType: DataPartType;
    let data: string | undefined | ScriptContentReturn;
    let partId: number | undefined;
    switch (partType) {
        case "TextCollection": {
            dataType = "Text";
            const content = getTextContent(contentContainer, null);
            data = content?.data;
            partId = content?.partId;
            break;
        }
        case "AdvancedDatatable": {
            dataType = "DataTable";
            data = getAdvancedDataTableContent(contentContainer);
            break;
        }
        case "AdvancedHTML":
        case "MiscText":
            dataType = "Text";
            data = contentContainer.firstElementChild?.innerHTML.trim();
            partId = Number(contentContainer.dataset.part_id);
            break;
        case "PeopleList":
            dataType = "RecordArray";
            data = getScriptContent(contentContainer);
            break;
        case "ShareClassSelector":
            dataType = "ShareClassData";
            data = getScriptContent(contentContainer);
            break;
        case "Prices":
            dataType = "Prices";
            data = getScriptContent(contentContainer);
            break;
        case "CanvasInstance":
            return getCanvasData(contentContainer, args);
        case "Tabstrip":
            return getTabstripData(contentContainer, args);
        case "Simple":
        case "Literature":
        case "PerformanceScenarioDateSelector":
        case "ShareClassGroupProperty":
        case "PostList":
        case "EventList":
        case "Form":
        case "FundPropertyPart":
        case "Filter":
            return getScriptContent(contentContainer);
        case "DateSelector":
            dataType = "AvailableDates";
            data = getScriptContent(contentContainer);
            break;
        case "PerfChart":
            return getPerfChartDataFromPart(contentContainer);
        case "StaticTable":
            return undefined;
        default:
            throw Error(`Unknown part type: ${partType}`, { cause: args });
    }
    if (data === undefined) {
        return undefined;
    }
    if (typeof data === "string") {
        return {
            type: dataType,
            data: data,
            partId,
        };
    }
    return {
        type: dataType,
        ...data,
        partId: data.partId ?? partId,
    };
}

function getTextContent(
    elem: HTMLElement,
    querySelector: string | null = "p",
    showError = true,
) {
    if (!elem || !elem.innerText || elem.innerText.trim().length === 0) {
        return undefined;
    }
    const partId = Number(elem.dataset.part_id);
    if (querySelector !== null) {
        const pElem = elem.querySelector(querySelector);
        if (!pElem && showError) {
            console.log(
                "%cerror services.tsx getTextContent",
                "color: red; display: block; width: 100%;",
                "No text content tag found",
                `Part ${partId}`,
            );
        }
        return {
            data: pElem?.innerHTML,
            partId,
        };
    }
    return {
        data: elem?.innerHTML,
        partId,
    };
}

/**
 * Gets the script content from a HTML element. Expects a structure that is somewhat like this:
 *
 * ```html
 * <div data-part_id="123">
 *   <script class="data" type="application/json">
 *      // JSON data
 *   </script>
 *   <!-- Optional -->
 *   <script class="headers" type="application/json">
 *     // JSON data
 *   </script>
 *   <script id="conf" type="application/json">
 *      // JSON data
 *   </script>
 *   <p class="note_template">
 *     // Text content
 *   </p>
 *   <div data-title="Title">
 *     // title
 *   </div>
 *   <!-- /Optional -->
 * </div>
 * ```
 *
 * @param elem HTMLElement to get script content from. Must contain a script tag with the class `.data`
 * @returns
 */
export function getScriptContent(elem: HTMLElement): ScriptContentReturn {
    const dataContent = getDataScriptContent(elem);
    const headerContent = getHeaderScriptContent(elem);
    const confContent = getConfScriptContent(elem);
    const noteTemplate = getTextContent(elem, "p.note_template", false);
    if (
        !dataContent ||
        (Array.isArray(dataContent) && dataContent.length === 0)
    ) {
        return undefined;
    }
    const partId = Number(elem.dataset.part_id);
    const childElem = elem.firstElementChild as HTMLDivElement;
    return {
        data: dataContent?.data,
        headers: headerContent?.data,
        conf: confContent?.data,
        note: noteTemplate?.data,
        title:
            childElem?.dataset.title && childElem.dataset.title !== "None"
                ? childElem.dataset.title
                : undefined,
        partId,
    };
}

function getAdvancedDataTableContent(elem: HTMLElement): ScriptContentReturn {
    const scriptData = getScriptContent(elem);
    const noteTemplate = getTextContent(elem, "p.note_template");
    if (!scriptData) {
        return undefined;
    }
    return {
        ...scriptData,
        note: noteTemplate?.data,
    };
}

export function getGenericScriptContent<T>(
    elem: HTMLElement,
    identifier: string,
    errorOnNoScript?: boolean,
): { data: T; partId: number } | undefined {
    const script = elem.querySelector(`script${identifier}`);
    const partId = Number(elem.dataset.part_id);
    const dataContent = script?.textContent;
    if (!dataContent) {
        if (errorOnNoScript) {
            console.log(
                "%cservices.tsx getGenericScriptContent",
                "color: red;",
                "No script",
                partId,
                `Identifier ${identifier}`,
            );
        }
        if (script) {
            console.log(
                "%cservices.tsx getGenericScriptContent",
                "color: lightCoral;",
                "Script is empty",
                partId,
                `Identifier ${identifier}`,
            );
        }
        return undefined;
    }
    try {
        return {
            data: json5Parse(dataContent),
            partId,
        };
    } catch (error) {
        console.log(
            "%cservices.tsx line:381 dataContent",
            "color: #007acc;",
            dataContent,
        );
        console.log(
            "%cerror services.tsx getGenericScriptContent ",
            "color: red; display: block; width: 100%;",
            `Error parsing generic script: Part ${partId} ${identifier}`,
            error,
        );
    }
}

const getDataScriptContent = (elem: HTMLElement) =>
    getGenericScriptContent<Record<string, string[]>>(elem, ".data", true);

const getHeaderScriptContent = (elem: HTMLElement) =>
    getGenericScriptContent<string[]>(elem, ".headers");

const getConfScriptContent = (elem: HTMLElement) =>
    getGenericScriptContent<Record<string, any>>(elem, "#conf");

const getPriceTypeListContent = (elem: HTMLElement) =>
    getGenericScriptContent<string[]>(elem, "#price_type_list");

export async function fetchPerfChartData(
    args: PerformanceChartArgs,
    baseURL?: string,
): Promise<PerformanceChartResponseSchema> {
    const {
        audienceId,
        routeId,
        shareClassID,
        partId,
        period_length,
        start_date,
        end_date,
        languageId,
    } = args;

    const searchParams = new URLSearchParams({
        part_id: String(partId),
        audience: String(audienceId),
        route: String(routeId),
        shareclass: String(shareClassID),
        max_period_length: String("40"),
        languageId: String(languageId),
    });

    if (start_date) {
        searchParams.set("start_date", String(start_date));
    }
    if (end_date) {
        searchParams.set("end_date", String(end_date));
    }
    if (period_length) {
        searchParams.set("period_length", String(period_length));
    }

    const URL = `${
        baseURL ?? ""
    }/srp/api/perf-chart-part-new?${searchParams.toString()}`;

    const perfChartData = await fetch(URL)
        .then((res) => res.text())
        .catch(() => {
            throw new Error("Error fetching performance chart data");
        });
    return json5Parse(perfChartData);
}

function getPerfChartDataFromPart(elem: HTMLElement) {
    const { data } = getScriptContent(elem)!;
    const priceTypeList = getPriceTypeListContent(elem);
    return mergeAll([data, { price_type_list: priceTypeList }]);
}

function getTabstripData(elem: HTMLElement, args: FundPagePartDataArgs) {
    const headerData = getHeaderScriptContent(elem);
    const templates = elem.querySelectorAll("template");
    const templateData = Array.from(templates).map((template) => {
        return parsePartForData(template.innerHTML, args);
    });
    return {
        content: templateData,
        tabs: headerData,
    };
}

function getCanvasData(elem: HTMLElement, args: FundPagePartDataArgs) {
    const container = elem.querySelector("main");

    if (!container) {
        console.log(
            "%cerror services.tsx getCanvasData",
            "color: red; display: block; width: 100%;",
            "No main tag on canvas.",
            args.partId,
        );
        return;
    }

    return constructNestedData(container);

    /**  Add data attributes from the dom */
    function applyDataAttributes(
        data: Record<string, any>,
        elem: HTMLElement,
    ): ElemData {
        const sectionData: ElemData = {
            part: data,
            ...elem.dataset,
        };

        DATA_ATTRIBUTES.forEach((attribute) => {
            if (attribute in elem.dataset) {
                sectionData[attribute] = true;
            }
        });

        return sectionData;
    }

    /**  Method for getting data attributes at same level in the tree */
    function constructData(elem: HTMLDataElement) {
        let data;
        if ("list" in elem.dataset || "takeAllParts" in elem.dataset) {
            // parse each child indivdually
            data = Array.from(elem.children).map((child) => {
                return parsePartForData(child?.outerHTML, args);
            });
        } else {
            data = parsePartForData(elem?.innerHTML, args);
        }
        if (
            !("useShareclassData" in elem.dataset) &&
            !("useFundData" in elem.dataset) &&
            (!data ||
                (typeof data === "object" && Object.keys(data).length === 0))
        ) {
            return null;
        }
        const dataPartData = applyDataAttributes(data, elem);
        return [elem.dataset.key, dataPartData];
    }

    /**  Method for getting data attributes at lower levels of the tree */
    function constructNestedData(container: HTMLElement): Record<string, any> {
        const childrenArr = Array.from(container.children) as HTMLElement[];
        const constructedElements = [];

        for (const elem of childrenArr) {
            switch (elem.tagName) {
                case "SCRIPT": {
                    try {
                        const scriptContent = json5Parse(elem.textContent!);

                        if (scriptContent) {
                            const scriptData = applyDataAttributes(
                                scriptContent,
                                elem,
                            );
                            constructedElements.push([elem.id, scriptData]);
                        }
                    } catch (e) {
                        console.warn("Could not parse script in canvas", {
                            cause: e,
                        });
                    }
                    break;
                }
                case "DATA": {
                    const dataData = constructData(elem as HTMLDataElement);
                    if (dataData) {
                        constructedElements.push(dataData);
                    }
                    break;
                }
                case "SECTION": {
                    const nestedSections = constructNestedData(elem);
                    if (Object.keys(nestedSections).length > 0) {
                        const sectionData = applyDataAttributes(
                            nestedSections,
                            elem,
                        );
                        constructedElements.push([
                            elem.dataset.key,
                            sectionData,
                        ]);
                    }
                    break;
                }
            }
        }

        return Object.fromEntries(constructedElements);
    }
}

async function getAllDataParts(
    dataCanvasId: number,
    config: FundPageServicesConfig,
    shareClass?: number,
) {
    const {
        audienceId,
        routeId,
        fundId,
        base_url,
        languageId,
        fetchLanguageAware,
        usertypeShortName,
        jurisdictionShortName,
        postId,
        version,
    } = config;
    const data: (PageSection | DataCanvasSection)[] | undefined =
        await getDataFromPart({
            baseUrl: base_url,
            partId: dataCanvasId,
            audienceId: audienceId,
            fundID: fundId,
            routeId: routeId,
            shareClassID: shareClass,
            languageId: fetchLanguageAware ? languageId : undefined,
            usertypeShortName,
            jurisdictionShortName,
            postId,
            version,
        });
    return data;
}

export async function getAllDataPartsFromConfig(
    config: FundPageServicesConfig & OverrideKeys,
    shareClass?: number,
) {
    const pageSections = config.pageSections;

    const pageSectionsData = await Promise.all(
        pageSections.map(async (section) => {
            const data = section.data;
            if (!data || typeof data !== "number") {
                if (section.litApiId || section.postApiId) {
                    return section;
                }
                return undefined;
            }
            const partsData = await getAllDataParts(
                data,
                {
                    ...config,
                    base_url: section.baseURL ?? config.base_url,
                },
                shareClass,
            );
            if (!partsData || Object.keys(partsData).length === 0) {
                return undefined;
            }
            return {
                ...handleSectionOverrides(section),
                ...partsData,
            };
        }),
    );

    return pageSectionsData.filter((x) => x !== undefined);

    function handleSectionOverrides(section: ConfigPageSection) {
        return produce(section, (draft) => {
            if (!section.overrides) {
                return;
            }
            if (section.overrides.title) {
                const { type, value, match } = section.overrides.title;
                //expand as needed...
                switch (type) {
                    case "productType":
                        if (config.productType === match) {
                            draft.title = value as string;
                        }
                        break;
                }
            }
        });
    }
}

export async function getPricesData<T>(args: PricesPartArgs) {
    const { year, month, dates, fundId, partId, audienceId, version, baseUrl } =
        args;
    const params = new URLSearchParams({
        id: `${partId}`,
        audience: `${audienceId}`,
        fund_id: `${fundId}`,
        price_part_start_date: `${year}-${month}-${dates[0]}`,
        price_part_end_date: `${year}-${month}-${dates[1]}`,
        version,
    });
    const res = await fetch(`${baseUrl ?? ""}/srp/api/part?${params}`);
    if (!res.ok) {
        throw new Error(
            `Failed to fetch part ${args.partId} - ${res.status} ${res.statusText}`,
        );
    }
    const html = await res.text();
    const parsed = parsePartForData(html, {
        audienceId,
        fundID: fundId,
        partId,
        routeId: 0,
        shareClassID: 0,
        version,
    }) as { data: T };
    return {
        ...parsed.data,
        partId,
    };
}

export async function getBannerImage(config: Config) {
    const {
        audienceId,
        routeId,
        fundId,
        base_url,
        languageId,
        fetchLanguageAware,
    } = config;
    const data = await getDataFromPart({
        baseUrl: base_url,
        partId: config.banner,
        audienceId: audienceId,
        fundID: fundId,
        routeId: routeId,
        languageId: fetchLanguageAware ? languageId : undefined,
    });
    const result = bannerPartSchema.parse(data);
    const defaultImage = result.default.part.data.src;
    const overrideImage = result.override?.part;
    if (overrideImage) {
        const { image, text, title, links } = overrideImage.banner.part.data;
        return {
            image,
            text,
            title,
            links,
        };
    }
    return {
        image: defaultImage,
    };
}

function createSearchParams(args: FundPagePartDataArgs) {
    const {
        partId,
        audienceId,
        routeId,
        fundID,
        version,
        shareClassID,
        asAtDate,
        languageId,
        jurisdictionShortName,
        usertypeShortName,
        price_part_start_date,
        price_part_end_date,
        price_part_day,
        price_part_month,
        price_part_year,
        postId,
    } = args;
    const searchParams = new URLSearchParams({
        id: String(partId),
        audience: String(audienceId),
        route: String(routeId),
        version: String(version ?? "live"),
        languageId: String(languageId),
    });

    if (shareClassID) {
        searchParams.set("share_class_id", String(shareClassID));
    }
    if (fundID) {
        searchParams.set("fund_id", String(fundID));
    }
    // if (!shareClassID && !fundID) {
    //     throw new Error("No fund or share class ID provided");
    // }

    if (asAtDate) {
        searchParams.set("as_at_date", String(asAtDate));
    }
    if (usertypeShortName) {
        searchParams.set("usertype", String(usertypeShortName));
    }
    if (jurisdictionShortName) {
        searchParams.set("jurisdiction", String(jurisdictionShortName));
    }
    if (price_part_start_date) {
        searchParams.set(
            "price_part_start_date",
            String(price_part_start_date),
        );
    }
    if (price_part_end_date) {
        searchParams.set("price_part_end_date", String(price_part_end_date));
    }
    if (price_part_day) {
        searchParams.set("price_part_day", String(price_part_day));
    }
    if (price_part_month) {
        searchParams.set("price_part_month", String(price_part_month));
    }
    if (price_part_year) {
        searchParams.set("price_part_year", String(price_part_year));
    }
    if (postId) {
        searchParams.set("post", String(postId));
    }

    return searchParams;
}

export async function fetchPostHTMLData(searchParams: URLSearchParams) {
    const postHTML = await fetch(
        `/srp/api/post-html?${searchParams.toString()}`,
    );
    const postHTMLJson = await postHTML.json();
    const postsData = Object.values(postHTMLJson).map((post) => {
        const template = createTemplate();
        template.innerHTML = String(post).trim();
        const contentContainer = template.content.children[0];
        if (contentContainer) {
            const postData = getScriptContent(contentContainer as HTMLElement);
            return postData?.data as PostListItem;
        }
        return null;
    });
    // Going to force the sorting here as date order was inverted for some reason...
    return postsData
        .filter((x) => x !== null)
        .sort((a, b) => (a!.date < b!.date ? 1 : -1));
}

export function getConfigFromPartView<T>(raw: string) {
    const rawConfig: {
        configuration: Omit<
            Config,
            | "fundId"
            | "fundName"
            | "fundDescriptor"
            | "routeId"
            | "audienceId"
            | "jurisdictionId"
            | "usertypeId"
            | "usertypeName"
            | "languageId"
            | "umbrellaId"
            | "primaryShareclassId"
            | "locale"
        >;
        fundId: number;
        fundName: string;
        fundDescriptor: string;
        routeId: number;
        audienceId: number;
        jurisdictionId: number;
        usertypeId: number;
        usertypeName: string;
        languageId: number;
        umbrellaId: number;
        primaryShareclassId: number;
        locale: string;
    } = json5Parse(raw);

    const { configuration, ...rest } = rawConfig;

    const config = {
        ...configuration,
        ...rest,
        locale: rawConfig.locale.replace("_", "-") || "en-GB",
    } as T;

    return config;
}
