import * as React from "react";

const SvgDiet = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={27}
        fill="none"
        {...props}
    >
        <g clipPath="url(#diet_svg__a)">
            <path
                fill="#016560"
                d="M5.248.321C2.515.321.3 2.906.3 6.093a5.664 5.664 0 0 0 3.807 5.618l-.82 13.353a1.536 1.536 0 0 0 1.548 1.647h.83a1.535 1.535 0 0 0 1.548-1.646l-.824-13.353a5.664 5.664 0 0 0 3.807-5.619c0-3.188-2.215-5.772-4.948-5.772Zm14.065 0-1.374 8.247h-1.032L16.22.321h-.687l-.687 8.247h-1.032L12.44.321h-.687v10.721a.825.825 0 0 0 .825.825h2.147l-.81 13.2a1.535 1.535 0 0 0 1.55 1.644h.825a1.535 1.535 0 0 0 1.548-1.646l-.81-13.2h2.147A.825.825 0 0 0 20 11.04V.321h-.687Z"
            />
        </g>
        <defs>
            <clipPath id="diet_svg__a">
                <path fill="#fff" d="M.3.32H20v26.39H.3z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgDiet;
