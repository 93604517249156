import {
    useRef,
    type Dispatch,
    type JSXElementConstructor,
    type ReactNode,
    type SetStateAction,
} from "react";
import { Popover } from "@headlessui/react";
import ChevronDownIcon from "@heroicons/react/24/solid/ChevronDownIcon";
import {
    DayPicker,
    useDayPicker,
    useDayRender,
    useNavigation,
    type DateFormatter,
    type DayProps,
    type Matcher,
} from "react-day-picker";
import styles from "react-day-picker/dist/style.module.css";

import cx from "@hsl/core/utils/cx";
import CustomScroller from "@hsl/lgim-explorer/src/components/CustomScroller";
import dayjs from "@hsl/lgim-explorer/src/lib/dayjs";

export interface DatePickerProps {
    fromDate: Date;
    toDate: Date;
    value?: Date;
    disabled?: boolean;
    loading?: boolean;
    modifierClassNames?: {
        selected?: string;
        disabled?: string;
    };
    dayPickerClassName?: string;
    dropDownOptionClassNames?: DayPickerDropDownClassNames;
    panelClassName?: string;
    Icon?: JSXElementConstructor<any>;
    dateFormat?: string;
    disabledDays?: Matcher | Matcher[] | undefined;
    onChange: Dispatch<SetStateAction<Date | undefined>>;
    onClick?: () => void;
}
export const DatePicker = ({
    fromDate,
    toDate,
    value,
    disabled,
    loading,
    modifierClassNames,
    dayPickerClassName,
    dropDownOptionClassNames,
    panelClassName,
    dateFormat = "DD MMM YYYY",
    disabledDays,
    Icon,
    onChange,
    onClick,
}: DatePickerProps) => {
    const dayFormatter: DateFormatter = (date: Date) => {
        const dayOfWeek = date.getDay();
        switch (dayOfWeek) {
            case 1:
                return "Mon";
            case 2:
                return "Tue";
            case 3:
                return "Wed";
            case 4:
                return "Thu";
            case 5:
                return "Fri";
        }
    };

    return (
        <Popover className="relative">
            {({ open, close }) => (
                <>
                    <Popover.Button
                        className={cx(
                            "flex w-max items-center text-sm",
                            dayPickerClassName,
                        )}
                        disabled={disabled === true || loading}
                        onClick={onClick}
                    >
                        {value && dayjs(value).format(dateFormat)}
                        {Icon ? (
                            <Icon
                                className={cx(
                                    "ml-2.5 transition",
                                    {
                                        invisible: disabled,
                                    },
                                    open ? "opacity-100" : "opacity-75",
                                )}
                            />
                        ) : (
                            <ChevronDownIcon
                                height={15}
                                className={cx(
                                    "ml-2.5 transition",
                                    {
                                        "rotate-180": open,
                                        invisible: disabled,
                                    },
                                    open ? "opacity-100" : "opacity-75",
                                )}
                            />
                        )}
                    </Popover.Button>
                    <Popover.Panel
                        unmount={false}
                        className={cx(
                            "absolute z-50 bg-white shadow-lg",
                            panelClassName,
                        )}
                    >
                        <DayPicker
                            ISOWeek
                            mode="single"
                            captionLayout="dropdown"
                            selected={value}
                            defaultMonth={value}
                            disabled={disabled || disabledDays}
                            fromDate={fromDate}
                            toDate={toDate}
                            formatters={{
                                formatWeekdayName: dayFormatter,
                            }}
                            components={{
                                Caption: () => (
                                    <DayPickerCaption
                                        dropDownOptionClassNames={
                                            dropDownOptionClassNames
                                        }
                                    />
                                ),
                                Day: DayPickerDay,
                            }}
                            modifiersClassNames={{
                                selected: cx(
                                    "text-white !opacity-100",
                                    modifierClassNames?.selected,
                                ),
                                disabled: cx(
                                    "opacity-75",
                                    modifierClassNames?.disabled,
                                ),
                            }}
                            className={cx(
                                `performance-date-picker bg-white [&_td:empty]:!hidden`,
                            )}
                            classNames={styles}
                            onDayClick={() => close()}
                            onSelect={onChange}
                        />
                    </Popover.Panel>
                </>
            )}
        </Popover>
    );
};

const DayPickerDay = ({ date, displayMonth }: DayProps) => {
    const ref = useRef<HTMLButtonElement>(null);
    const { divProps, buttonProps, isButton } = useDayRender(
        date,
        displayMonth,
        ref,
    );

    const currentMonth = displayMonth.getMonth();
    const currentYear = displayMonth.getFullYear();

    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);

    const notCurrentMonth = date.getMonth() !== currentMonth;
    const monthStartsOnAWeekend = [0, 6].includes(firstDayOfMonth.getDay());
    const isWeekendDay = [0, 6].includes(date.getDay());

    if ((notCurrentMonth && monthStartsOnAWeekend) || isWeekendDay) {
        return null;
    }
    if (isButton && !notCurrentMonth) {
        return (
            <button
                {...buttonProps}
                className={cx(
                    buttonProps.className,
                    "!m-auto",
                    !buttonProps.disabled && "hover:bg-gray-100",
                )}
            >
                {date.getDate().toString()}
            </button>
        );
    } else {
        return <div {...divProps}>{date.getDate().toString()}</div>;
    }
};

const DayPickerCaption = ({
    dropDownOptionClassNames,
}: {
    dropDownOptionClassNames?: DayPickerDropDownClassNames;
}) => {
    return (
        <div className="flex pl-4">
            <DayPickerMonthDropdown
                dropDownOptionClassNames={dropDownOptionClassNames}
            />
            <DayPickerYearDropdown
                dropDownOptionClassNames={dropDownOptionClassNames}
            />
        </div>
    );
};
const DayPickerMonthDropdown = ({
    dropDownOptionClassNames,
}: {
    dropDownOptionClassNames?: DayPickerDropDownClassNames;
}) => {
    const { currentMonth, goToMonth } = useNavigation();
    return (
        <Popover className="relative mr-2">
            {({ open, close }) => (
                <>
                    <DayPickerDropdownLabel isOpen={open}>
                        {dayjs(currentMonth?.valueOf()).format("MMMM")}
                    </DayPickerDropdownLabel>
                    <Popover.Panel className="absolute left-0 right-0 z-50 bg-white shadow-lg">
                        <CustomScroller>
                            <ul>
                                {Array(12)
                                    .fill(null)
                                    .map((_, i) => {
                                        const dateToNavigateTo = new Date(
                                            currentMonth,
                                        );
                                        dateToNavigateTo.setMonth(i);
                                        const label = dayjs()
                                            .month(i)
                                            .format("MMMM");
                                        return (
                                            <DayPickerDropdownOption
                                                key={label}
                                                label={label}
                                                isActive={
                                                    currentMonth.getMonth() ===
                                                    i
                                                }
                                                classNames={
                                                    dropDownOptionClassNames
                                                }
                                                onClick={() => {
                                                    goToMonth(dateToNavigateTo);
                                                    close();
                                                }}
                                            />
                                        );
                                    })}
                            </ul>
                        </CustomScroller>
                    </Popover.Panel>
                </>
            )}
        </Popover>
    );
};
const DayPickerYearDropdown = ({
    dropDownOptionClassNames,
}: {
    dropDownOptionClassNames?: DayPickerDropDownClassNames;
}) => {
    const { fromDate, toDate } = useDayPicker();
    const { currentMonth, goToMonth } = useNavigation();

    const numberOfYearsToShow =
        fromDate && toDate
            ? toDate?.getFullYear() - fromDate?.getFullYear() + 1
            : 0;

    return (
        <Popover className="relative mr-2">
            {({ open, close }) => (
                <>
                    <DayPickerDropdownLabel isOpen={open}>
                        {dayjs(currentMonth?.valueOf()).format("YYYY")}
                    </DayPickerDropdownLabel>
                    <Popover.Panel className="absolute left-0 right-0 z-10 bg-white shadow-lg">
                        <CustomScroller className="max-h-[250px]">
                            <ul>
                                {fromDate &&
                                    toDate &&
                                    Array(numberOfYearsToShow)
                                        .fill(null)
                                        .map((_, i) => {
                                            const yearVal =
                                                toDate.getFullYear() - i;
                                            const dateToNavigateTo = new Date(
                                                currentMonth,
                                            );
                                            const yearStr = yearVal.toString();
                                            dateToNavigateTo.setFullYear(
                                                yearVal,
                                            );
                                            return (
                                                <DayPickerDropdownOption
                                                    key={yearStr}
                                                    label={yearStr}
                                                    isActive={
                                                        yearVal ===
                                                        currentMonth.getFullYear()
                                                    }
                                                    classNames={
                                                        dropDownOptionClassNames
                                                    }
                                                    onClick={() => {
                                                        goToMonth(
                                                            dateToNavigateTo,
                                                        );
                                                        close();
                                                    }}
                                                />
                                            );
                                        })}
                            </ul>
                        </CustomScroller>
                    </Popover.Panel>
                </>
            )}
        </Popover>
    );
};
const DayPickerDropdownLabel = ({
    children,
    isOpen,
}: {
    children: ReactNode;
    isOpen: boolean;
}) => {
    return (
        <Popover.Button className="text-charcoal outline-super-blue-light flex min-w-[100px] items-center text-sm font-medium">
            {children}
            <ChevronDownIcon
                height={15}
                className={`ml-1 transition ${isOpen && "rotate-180"}`}
            />
        </Popover.Button>
    );
};

type DayPickerDropDownClassNames = { active: string; default: string };

const DayPickerDropdownOption = ({
    label,
    isActive,
    classNames,
    onClick,
}: {
    label: string;
    isActive: boolean;
    classNames?: DayPickerDropDownClassNames;
    onClick(): void;
}) => {
    return (
        <li
            className={cx(
                "cursor-pointer p-1.5 text-sm transition hover:font-medium",
                classNames?.default,
                isActive && (classNames?.active ?? "bg-gray-50 font-medium"),
            )}
            onClick={onClick}
        >
            {label}
        </li>
    );
};
