import { Loupe } from "../../../components/icons";
import { DisclaimerText } from "./Disclaimer";
import LatestQuarterlyReport from "./LatestQuarterlyReport";

// @ts-expect-error -> not sure why index.d.ts file is not working...
import LoupeIcon from "../../../assets/loupe.svg";

const StickyBanner = ({ stuck, title }: { stuck: boolean; title?: string }) => {
    if (!stuck) {
        return null;
    }
    return (
        <div className="bg-secondary fixed left-0 right-0 top-[168px] z-[60] flex min-h-[90px] flex-col items-center justify-center py-2 sm:top-[176px] md:top-[192px] lg:top-[172px] print:hidden">
            <div className="container flex items-center justify-between gap-x-8 md:gap-x-16">
                <div className="flex items-center">
                    <img
                        src={LoupeIcon}
                        className="h-[36px] max-h-[18px] w-[18px] max-w-[18px] sm:h-[36px] sm:max-h-[36px] sm:w-[36px] sm:max-w-[36px]"
                    />
                    <h2 className="!m-0 whitespace-nowrap pl-3 text-lg text-white">
                        {title}
                    </h2>
                </div>
                <DisclaimerText className="mx-4 hidden max-h-full flex-1 text-center font-light text-white md:block md:text-sm" />
                <div className="min-w-[32px]">
                    <LatestQuarterlyReport
                    // textClassName="!text-xs !w-max !mb-0"
                    // iconContainerClassName="!h-[30px] !max-h-[30px] !w-[30px] !max-w-[30px] !mr-2"
                    />
                </div>
            </div>
            <DisclaimerText className="mx-4 py-2 text-center !text-xs font-semibold text-white md:hidden" />
        </div>
    );
};

export default StickyBanner;
