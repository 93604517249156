import { ButtonWithArrow } from "../../components/Button";
import Card from "../../components/Card";
import FundPageContent from "../../components/FundPageContent";
import TextDataPart from "../../components/TextDataPart";
import useFundPageStore from "../../store/store";

const DATA_KEY = "relatedFunds";

const RelatedFunds = () => {
    const springFundId = useFundPageStore((store) => store.config.springFundId);
    const relatedFunds = useFundPageStore((store) => store.relatedFunds);
    const baseURL = useFundPageStore((store) => store.config.base_url);

    const computedFunds = relatedFunds.filter(
        (x) => x.id !== String(springFundId),
    );

    if (computedFunds.length === 0) {
        return null;
    }

    return (
        <FundPageContent.Wrapper
            dataKey={DATA_KEY}
            withBg
            observe={false}
            containerClassName="!mb-0"
        >
            <FundPageContent.Header h3 className="text-white">
                Related funds
            </FundPageContent.Header>
            <Card.ListContainer>
                {computedFunds.map(
                    ({
                        id,
                        long_name,
                        descriptor,
                        image,
                        intro,
                        geographic_assetclass,
                        fund_managers,
                        page_url,
                    }) => {
                        return (
                            <Card.Container
                                key={id}
                                href={page_url}
                                className="h-[450px]"
                            >
                                <Card.Image imgURL={`${baseURL}${image}`} />
                                <Card.Body key={id} className="justify-start">
                                    <div>
                                        <Card.Brow className="uppercase">
                                            {geographic_assetclass}
                                        </Card.Brow>
                                        <Card.Title className="pt-0">
                                            {long_name}
                                        </Card.Title>
                                    </div>
                                    <TextDataPart
                                        className="flex-1"
                                        data={intro}
                                    />
                                    <Card.Footer>
                                        <div>Fund managers</div>
                                        <div className="font-semibold">
                                            {fund_managers}
                                        </div>
                                    </Card.Footer>
                                </Card.Body>
                            </Card.Container>
                        );
                    },
                )}
            </Card.ListContainer>
            <ButtonWithArrow
                href="/Our-Funds"
                className="mt-6 rounded-xl border border-white px-4 py-2 font-light"
            >
                See all funds
            </ButtonWithArrow>
        </FundPageContent.Wrapper>
    );
};

export default RelatedFunds;
