import { ListContainer } from "../../../components/Card";
import NewsAndViewsCard, {
    NewsAndViewsCardSkeleton,
} from "../../../components/NewsAndViewsCard";
import NoDataMessage from "../../../components/NoDataMessage/NoDataMessage";
import { useNewsAndViewsContext } from "../context";

export const PostList = () => {
    const {
        activePosts,
        reloadingIds,
        isLoadingMorePosts,
        numPostsToFetch,
        filtersActive,
    } = useNewsAndViewsContext();

    if (reloadingIds) {
        return (
            <ListContainer className="flex-wrap pt-8 sm:overflow-hidden">
                {Array.from(Array(numPostsToFetch)).map((_, i) => (
                    <NewsAndViewsCardSkeleton key={`newsAndViewsSkel-${i}`} />
                ))}
            </ListContainer>
        );
    }

    return (
        <div className="min-h-screen">
            <ListContainer className="flex-wrap pt-8 sm:overflow-hidden">
                {!reloadingIds &&
                !isLoadingMorePosts &&
                filtersActive &&
                activePosts?.length === 0 ? (
                    <NoDataMessage className="h-max w-full text-center">
                        There are currently no articles available for your
                        filter selection.
                    </NoDataMessage>
                ) : (
                    activePosts?.map((post) => {
                        if (post) {
                            return <NewsAndViewsCard key={post.id} {...post} />;
                        }
                    })
                )}
                {isLoadingMorePosts &&
                    Array.from(Array(numPostsToFetch)).map((_, i) => (
                        <NewsAndViewsCardSkeleton
                            key={`newsAndViewsSkel-${i}`}
                        />
                    ))}
            </ListContainer>
        </div>
    );
};
