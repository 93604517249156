import { useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { z } from "zod";

import { toSearchParams } from "@hsl/core/utils/toSearchParams";

export const authorSchema = z.object({
    id: z.number(),
    name: z.string(),
    primaryHeadshot: z.string(),
});

export type Author = z.infer<typeof authorSchema>;

export const insightSchema = z.object({
    asAtDate: z.string(),
    authors: z.array(authorSchema),
    body: z.string().optional(),
    category: z.object({
        name: z.string(),
        colour: z.string().nullable(),
    }),
    createdDate: z.string(),
    customTags: z.array(
        z.object({
            id: z.number(),
            name: z.string(),
        }),
    ),
    featuredStates: z.array(
        z.object({
            id: z.number(),
            shortName: z.string(),
            text: z.string(),
        }),
    ),
    funds: z.array(
        z.object({
            id: z.number(),
            name: z.string(),
        }),
    ),
    fundGroups: z.array(
        z.object({
            id: z.number(),
            name: z.string(),
        }),
    ),
    fundTypes: z.array(
        z.object({
            id: z.number(),
            name: z.string(),
            uuid: z.string(),
        }),
    ),
    id: z.number(),
    image: z.string().optional(),
    publicationImage: z
        .object({
            src: z.string(),
            alt: z.string(),
        })
        .optional(),
    readUrl: z.string(),
    synopsis: z.string(),
    comprehensiveSynopsis: z.string(),
    title: z.string(),
    type: z.enum([
        "text",
        "text_external",
        "article",
        "document",
        "document_external",
        "video",
        "podcast",
        "webinar_external",
    ]),
    updatedDate: z.string(),
    validFromDate: z.string(),
});
export type Insight = z.infer<typeof insightSchema>;

export const insightsResponseSchema = z.object({
    count: z.number(),
    next: z.string().nullable(),
    previous: z.string().nullable(),
    results: z.array(insightSchema),
});
export type InsightsResponse = z.infer<typeof insightsResponseSchema>;

type InsightsFilterID = string | number | undefined;

export type InsightsFiltersParams = {
    categories?: InsightsFilterID[];
    custom_tags?: InsightsFilterID[];
    categories_to_exclude?: InsightsFilterID[];
    funds?: InsightsFilterID[];
    funds_to_exclude?: InsightsFilterID[];
    fund_groups?: InsightsFilterID[];
    fund_types?: InsightsFilterID[];
    contributors?: InsightsFilterID[];
    contributors_to_exclude?: InsightsFilterID[];
    post_types?: string[];
    post_types_to_exclude?: (
        | "video"
        | "article"
        | "webinar"
        | "text"
        | "text_external"
    )[];
    audiences?: InsightsFilterID[];
    audiences_to_exclude?: InsightsFilterID[];
    posts?: InsightsFilterID[];
    posts_to_exclude?: InsightsFilterID[];
    featured_states_to_surface?: InsightsFilterID[];
};

type OrderBy = "as_at_date" | "valid_from_date" | "created_date";

export interface Args {
    queryKey?: string;
    baseURL?: string;
    filters?: InsightsFiltersParams;
    page?: number;
    pageSize?: number;
    includePostBody?: boolean;
    fetchMoreInsights?: boolean;
    disabled?: boolean;
    orderBy?: OrderBy;
}

function useInsights({
    queryKey,
    baseURL,
    filters,
    page = 1,
    pageSize,
    includePostBody = false,
    fetchMoreInsights = false,
    disabled = false,
    orderBy = "as_at_date",
}: Args) {
    const { data, isLoading, isFetching, isFetchingNextPage, fetchNextPage } =
        useInfiniteQuery<InsightsResponse>({
            queryKey: ["insights", queryKey, filters],
            enabled: !disabled,
            initialPageParam: page,
            refetchOnWindowFocus: false,
            refetchInterval: Infinity,
            getNextPageParam: (lastPage, allPages, lastPageParam) => {
                if (!lastPage.next) {
                    return undefined;
                }
                return (lastPageParam as number) + 1;
            },
            queryFn: async ({ pageParam }) => {
                const params = new URLSearchParams({
                    ...toSearchParams<InsightsFiltersParams>(filters),
                    page: String(pageParam),
                    page_size: String(pageSize),
                    include_post_body: String(includePostBody),
                    order_by: orderBy,
                });
                const res = await fetch(
                    `${baseURL ?? ""}/srp/api/insights?${params.toString()}`,
                );
                const json = await res.json();
                const parsed = insightsResponseSchema.safeParse(json);
                if (!parsed.success) {
                    console.warn(
                        "Insights response does not match schema",
                        parsed.error,
                    );
                }
                return json as InsightsResponse;
            },
        });

    useEffect(() => {
        if (!isFetching && fetchMoreInsights) {
            void fetchNextPage();
        }
    }, [fetchMoreInsights, fetchNextPage, isFetching]);

    return {
        insights:
            data?.pages.flatMap((x) => x?.results).filter((x) => !!x) ?? [],
        next: data?.pages[data?.pages.length - 1]?.next,
        isLoading,
        isLoadingMore: isFetchingNextPage,
        fetchNextPage,
    };
}

export default useInsights;
