import * as React from "react";

const SvgDownloadBlack = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={17}
        fill="none"
        {...props}
    >
        <path
            fill="#000"
            d="M7.997 12.254a.725.725 0 0 1-.272-.05.762.762 0 0 1-.25-.175L3.7 8.254a.692.692 0 0 1-.213-.538.78.78 0 0 1 .238-.537c.15-.15.33-.225.538-.225.208 0 .387.075.537.225l2.45 2.475V1.129c0-.213.072-.39.217-.534a.732.732 0 0 1 .537-.216.731.731 0 0 1 .746.75v8.525l2.475-2.475c.15-.15.33-.225.537-.225.209 0 .388.075.538.225.15.15.225.33.225.537a.734.734 0 0 1-.225.538l-3.775 3.775a.75.75 0 0 1-.253.175.752.752 0 0 1-.275.05ZM1.5 16.379c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-2.825c0-.213.072-.39.217-.534a.732.732 0 0 1 .537-.216.731.731 0 0 1 .746.75v2.825h13v-2.825c0-.213.072-.39.217-.534a.732.732 0 0 1 .537-.216.731.731 0 0 1 .746.75v2.825c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-13Z"
        />
    </svg>
);
export default SvgDownloadBlack;
