import { z } from "zod";

import { dataPartSchema, eventListItemSchema } from "@hsl/fund-page/schemas";
import { Tenant } from "@hsl/spring-capital/src/js/fund-page/config";

import { ListContainer as CardListContainer } from "../../../components/Card/Card";
import FundEventCard from "../../../components/FundEventCard";
import FundPageContent from "../../../components/FundPageContent";
import usePageSection from "../../../hooks/usePageSection";

const relatedEvent = dataPartSchema.extend({
    data: z.array(eventListItemSchema),
});
export const ManagerPageRelatedEvent = ({ tenant }: { tenant: Tenant }) => {
    const { data, title } = usePageSection("featuredEvent", relatedEvent);

    const eventsFilteredByTenant = data.filter((event) =>
        event.funds.map((fund) => fund.tenant).includes(tenant),
    );

    const eventData = eventsFilteredByTenant[0];

    if (!eventData) {
        return null;
    }

    return (
        <div className="flex flex-col">
            <FundPageContent.Header h2 className="font-extralight">
                {title}
            </FundPageContent.Header>
            <CardListContainer className="flex-1 justify-start">
                <FundEventCard key={eventData.id} {...eventData} />
            </CardListContainer>
        </div>
    );
};
