import HighchartsReact from "highcharts-react-official";
import Highcharts, { type SeriesLineOptions } from "highcharts/highstock";
import { type z } from "zod";

import ClientOnly from "@hsl/components/ClientOnly";
import cx from "@hsl/core/utils/cx";
import { type performanceChartDataPartSchema } from "@hsl/fund-page/schemas";

import "@hsl/components/LineChart/lineChart.css";

import {
    generateOptions,
    LineChartLegend,
    PerformanceLineChartProvider,
    usePerformanceLineChartContext,
} from "@hsl/components/LineChart";

import THEME_COLORS from "../../lib/themeColors";
import useFundPageStore from "../../store/store";
import FundPageContent from "../FundPageContent";
import { PerformanceLineChartDates } from "./LineChartPeriodDates";

export interface Props {
    data: z.infer<typeof performanceChartDataPartSchema>;
    shareClassId: number;
    launchDate: string;
}

const PerformanceLineChart = () => {
    const { apiData, startTime, endTime, error, indexDisplayName } =
        usePerformanceLineChartContext();

    const options = generateOptions({
        apiData,
        endTime,
        startTime,
        indexDisplayName,
        shareClassNameLength: "default",
        colors: [[THEME_COLORS.secondary], [THEME_COLORS.offWhiteDark]],
    });

    if (error) {
        return (
            <div className="text-charcoal flex h-full w-full items-center justify-center bg-gray-100 font-medium">
                There is no data available for the selected share class
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-wrap items-center justify-between text-base">
                <h4 className="text-purple text-2xl">
                    Performance since launch of share class (%)
                </h4>
                <ClientOnly>
                    <PerformanceLineChartDates />
                </ClientOnly>
            </div>
            <div className="h-full overflow-hidden pt-3">
                {apiData && (
                    <HighchartsReact
                        containerProps={{
                            style: { height: "100%" },
                            // Need to see the top value of the y-axis
                            className: cx([
                                "!overflow-visible",
                                "[&_svg]:!overflow-visible",
                                "[&_div]:!overflow-visible",
                            ]),
                        }}
                        highcharts={Highcharts}
                        constructorType={"stockChart"}
                        options={options}
                    />
                )}
            </div>
            <LineChartLegend
                series={options?.series as SeriesLineOptions[]}
                className="relative z-20 -mt-8 w-max flex-col items-start"
                entryClassName="w-full"
                legendIndicatorClassName="w-[9px] h-[9px]"
            />
            {apiData?.note && (
                <FundPageContent.Footnote withBorder>
                    {apiData.note}
                </FundPageContent.Footnote>
            )}
        </>
    );
};

const PerfLineChartWithContext = ({
    data,
    launchDate,
    shareClassId,
}: Props) => {
    const { audienceId, languageId, routeId, base_url } = useFundPageStore(
        (store) => store.config,
    );
    return (
        <PerformanceLineChartProvider
            audienceId={audienceId}
            languageId={languageId}
            routeId={routeId}
            perfChartData={data}
            launchDate={launchDate}
            shareClassId={shareClassId}
            baseURL={base_url}
        >
            <PerformanceLineChart />
        </PerformanceLineChartProvider>
    );
};

export default PerfLineChartWithContext;
