import { Config } from "tailwindcss";
import defaultTheme from "tailwindcss/defaultTheme";

import baseConfig from "@hsl/tailwind-config";

const headlessUiPlugin = require("@headlessui/tailwindcss");

export default {
    content: ["./src/**/*.{js,jsx,ts,tsx}"],
    presets: [baseConfig],
    theme: {
        extend: {
            colors: {
                "battleship-grey": "#575757",
                "dark-blue": "#004E86",
                "light-blue": "#71CBF4",
                "pastel-green": "#D9E6B1",
                "pastel-blue": "#C9E8FB",
                "core-green": "#028844",
                "core-red": "#E22922",
                "white-smoke": "#F6F6F6",
                white: "#FFFFFF",
                "taupe-grey": "#737373",
                "warm-gray": "#A3A3A3",
                platinum: "#E3E3E3",
                charcoal: "#333333",
                "core-blue": "#0076D6",
            },
            fontFamily: {
                sans: ["Roboto", ...defaultTheme.fontFamily.sans],
                serif: [
                    "Lyon",
                    "lyon display web",
                    ...defaultTheme.fontFamily.serif,
                ],
            },
        },
    },
    plugins: [headlessUiPlugin],
} satisfies Config;
