import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import type { PostListItem } from "@hsl/fund-page/schemas";

import { fetchPostHTMLData } from "../services";

interface Args {
    dataPartId: number;
    config: {
        metaTemplate: number;
        template: number;
        version: string;
        readRoute: number;
        numPostsToFetch: number;
        audienceId?: number; // if not present then will use the session cookie
        jurisdictionId?: number; // if not present then will use the session cookie,
        usertypeId?: number; // if not present then will use the session cookie,
    };
    contributor?: string;
    fund?: string | number;
    funds?: (number | string)[];
    category?: string;
    fundGroup?: string | number;
    fetchMorePosts?: boolean;
}

function usePosts({
    dataPartId,
    config,
    contributor,
    fund,
    funds,
    fundGroup,
    category,
    fetchMorePosts,
}: Args) {
    const [activePosts, setActivePosts] = useState<PostListItem[]>([]);
    const [refetchStartIndex, setRefetchStartIndex] = useState(0);
    const [refetchEndIndex, setRefetchEndIndex] = useState(
        config.numPostsToFetch,
    );

    const resetPosts = () => {
        setActivePosts([]);
        setRefetchStartIndex(0);
        setRefetchEndIndex(config.numPostsToFetch);
    };

    useEffect(() => {
        const totalNumPosts = PostIdsQuery.data?.length;
        if (
            fetchMorePosts &&
            totalNumPosts &&
            refetchEndIndex < totalNumPosts
        ) {
            setRefetchStartIndex(refetchEndIndex);
            if (
                PostIdsQuery.data?.length >
                refetchEndIndex + config.numPostsToFetch
            ) {
                setRefetchEndIndex(refetchEndIndex + config.numPostsToFetch);
            } else {
                setRefetchEndIndex(totalNumPosts);
            }
        }
    }, [fetchMorePosts]);

    const PostIdsQuery = useQuery({
        queryKey: ["postIds", contributor, fund, category],
        enabled: true,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            resetPosts();
            const searchParams = new URLSearchParams({
                id: String(dataPartId),
                ordering: "-post_metadata__as_at_date",
                version: config.version,
                category: category ?? "",
                contributor: contributor ?? "",
                audienceId: String(config?.audienceId) ?? "",
                jurisdictionId: String(config?.jurisdictionId) ?? "",
                usertypeId: String(config?.usertypeId) ?? "",
            });
            // add funds parameters
            let baseURLStr = `/srp/api/posts?`;
            if (funds) {
                baseURLStr += `${funds.map((id) => `funds=${id}&`).join("")}`;
            } else if (fundGroup) {
                searchParams.append("fund_group", String(fundGroup));
            } else if (fund) {
                searchParams.append("fund", String(fund));
            }
            baseURLStr += searchParams.toString();
            const postIds = await fetch(baseURLStr);
            const postIdsJson = await postIds.json();
            return postIdsJson;
        },
    });

    const PostsDataQuery = useQuery({
        queryKey: [
            "postData",
            PostIdsQuery.data,
            refetchStartIndex,
            refetchEndIndex,
        ],
        enabled: !!PostIdsQuery.data,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const searchParams = new URLSearchParams({
                id: String(dataPartId),
                posts: PostIdsQuery.data.slice(
                    refetchStartIndex,
                    refetchEndIndex,
                ),
                version: "live",
                template: String(config.template),
                read_route_id: String(config.readRoute),
            });
            const postsData = (await fetchPostHTMLData(
                searchParams,
            )) as PostListItem[];
            if (
                refetchStartIndex === 0 &&
                refetchEndIndex === config.numPostsToFetch
            ) {
                setActivePosts(postsData);
            } else {
                setActivePosts([
                    ...activePosts,
                    ...(postsData as PostListItem[]),
                ]);
            }
            return postsData;
        },
    });

    return {
        activePosts,
        isRefetchingIds: PostIdsQuery.isLoading || PostIdsQuery.isFetching,
        isLoadingMorePosts: PostsDataQuery.isLoading,
    };
}

export default usePosts;
