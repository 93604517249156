import { z } from "zod";

import { dataPartSchema, postListItemSchema } from "@hsl/fund-page/schemas";

import NewsAndViewsCard from "../../../components/NewsAndViewsCard";
import { type Insight } from "../../../hooks/useInsights";
import usePageSection from "../../../hooks/usePageSection";
import Container from "./Container";

const newsAndViewsSchema = dataPartSchema.extend({
    data: z.array(postListItemSchema),
});

const NewsAndViews = ({ data }: { data: Insight[] }) => {
    const { title } = usePageSection("newsAndViews", newsAndViewsSchema);
    if (data.length === 0) {
        return null;
    }
    return (
        <Container
            title={title}
            buttonText="More news and views"
            buttonHref="News-Views"
        >
            {data.slice(0, 3).map((post) => (
                <NewsAndViewsCard key={post.id} {...post} />
            ))}
        </Container>
    );
};

export default NewsAndViews;
