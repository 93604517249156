import { AvailableDates } from "../schemas";

/**
 * @description Convert available dates object to array of Dates
 */
export function convertAvailableDates(availableDates: AvailableDates) {
    return availableDates.flatMap((year) =>
        year.availableMonths.flatMap((month) =>
            month.availableDays.map(
                (day) => new Date(`${year.value}-${month.value}-${day}`),
            ),
        ),
    );
}
