import { useEffect, useRef } from "react";
import produce from "immer";
import { create } from "zustand";

type State = {
    intersections: { [key: string]: number };
    setSection: (key: string, value: number) => void;
};

export const useSectionIntersectionStore = create<State>((set) => ({
    intersections: {},
    setSection(key, value) {
        set((state) => {
            if (value === state.intersections[key]) return state;
            return produce(state, (draft) => {
                draft.intersections[key] = value;
            });
        });
    },
}));

/**
 * Observes a section in order to display active sections in the nav
 *
 * The IntersectionObserver watches the given section and stores how much of the viewport
 * it takes up (to one decimal place). The section nav can then pick the section
 * that takes up the most space.
 *
 * It uses `rootMargin` to discount the size of the header from the observed area
 * @todo account for the mobile-sized header when implementing responsive designs
 *
 * @param dataKey - section key, such as `performance`
 */
export function useFundPageSectionObserver(dataKey: string) {
    const setSection = useSectionIntersectionStore((state) => state.setSection);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const elem = ref.current;
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    // get the amount of the screen taken up by the section (to 1dp)
                    // we need to subtract the header
                    setSection(
                        dataKey,
                        Math.round(entry.intersectionRatio * 10),
                    );
                });
            },
            {
                rootMargin: "-175px 0px 0px 0px",
                threshold: [
                    0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0,
                ],
            },
        );
        if (elem) observer.observe(elem);
        return () => {
            if (elem) observer.unobserve(elem);
        };
    }, [dataKey, setSection]);

    return ref;
}
