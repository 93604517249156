import cx from "@hsl/core/utils/cx";

export type ToggleProps = {
    label?: string;
    active?: boolean;
    className?: string;
    onClick: () => void;
};

export const Toggle = ({ label, active, className, onClick }: ToggleProps) => {
    return (
        <span className={cx("flex items-center gap-x-4", className)}>
            {label && <span className="text-sm">{label}</span>}
            <span
                className="relative h-[18px] w-8 cursor-pointer rounded-full bg-gray-200 p-px"
                onClick={onClick}
            >
                <div
                    className={cx(
                        "absolute h-[16px] min-h-[16px] w-4 min-w-[16px] rounded-full bg-white",
                        active ? "right-px" : "left-px",
                    )}
                />
            </span>
        </span>
    );
};
