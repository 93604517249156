function scrollIntoView(identifier: string, offset = 0) {
    const section = document.querySelector(identifier);

    if (!section) {
        throw new Error(`${identifier} not found to scroll into view`);
    }

    // safe assumption
    const parent = section.parentElement!;

    const elementY = window.scrollY + parent.getBoundingClientRect().top;

    const headerHeight = Array.from(
        document.querySelectorAll("[data-header]"),
    ).reduce((acc, elem) => acc + elem.clientHeight, 0);

    // scroll to section
    window.scrollTo({
        top: elementY - headerHeight - offset,
        behavior: "smooth",
    });
}

export default scrollIntoView;
