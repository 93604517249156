import React from "react";

import cx from "@hsl/core/utils/cx";

import "./styles.css";

type Props = {
    className?: string;
    style?: React.CSSProperties;
} & React.PropsWithChildren;

const CustomScroller = ({ children, className, style }: Props) => {
    return (
        <div
            className={cx("lgim-scroller overflow-auto", className)}
            style={style}
        >
            {children}
        </div>
    );
};

export default CustomScroller;
