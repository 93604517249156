import cx from "@hsl/core/utils/cx";

import TextDataPart, { type Props } from "../../../components/TextDataPart";

const HEADER_STYLES = [
    "[&_h1]:sm:text-[28px]",
    "[&_h1]:text-primary",
    "[&_h1]:border-primary",
    "[&_h1]:border-b-2",
    "[&_h1]:pb-1",
    "[&_h1]:sm:pb-3",
    "[&_h1]:mb-4",
    "[&_h2]:text-[20px]",
    "[&_h2]:font-semibold",
    "[&_h3]:text-black",
    "[&_h3]:text-base",
    "[&_h3]:font-bold",
];

const ManagerDiaryTextDataPart = (props: Props) => {
    return (
        <TextDataPart
            {...props}
            sanitize={false}
            className={cx(
                "p-4 sm:px-8 sm:py-7 sm:pb-8 [&>p:not(:first-child)]:mt-0 [&_em]:py-2",
                HEADER_STYLES,
            )}
        />
    );
};

export default ManagerDiaryTextDataPart;
