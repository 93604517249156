import Highcharts, { type SeriesOptionsType } from "highcharts/highstock";

import { type PerformanceChartResponse } from "@hsl/core/fund-page/schemas";
import cx from "@hsl/core/utils/cx";

import { ONE_DAY, ONE_HOUR, ONE_MONTH, ONE_YEAR } from "./times";

interface Args {
    startTime: Date | undefined;
    endTime: Date | undefined;
    apiData?: PerformanceChartResponse;
    indexDisplayName?: string;
    colors: string[][];
    shareClassNameLength?: "short" | "default" | "long";
}

type GenOptionsType = (args: Args) => Highcharts.Options | undefined;

const html = (...args: Parameters<typeof String.raw>) =>
    String.raw(...args).trim();

const generateOptions: GenOptionsType = ({
    startTime,
    endTime,
    apiData,
    indexDisplayName,
    colors,
    shareClassNameLength,
}) => {
    if (!startTime || !endTime || !apiData) {
        return {};
    }

    const periodInMilliseconds = Math.abs(
        endTime.valueOf() - startTime.valueOf(),
    );
    const { index_plots, share_class_plots } = apiData;
    const { share_class_short_name, share_class_name, share_class_long_name } =
        apiData.share_class_info;

    const benchmarkData = [
        ...index_plots,
        ...share_class_plots
            .filter((plot) => plot.price_type.toLowerCase().includes("index"))
            .map((plot) => ({
                ...plot,
                name: indexDisplayName ?? "Benchmark",
            })),
    ];

    let tickerInterval: number;
    if (periodInMilliseconds <= ONE_MONTH) {
        tickerInterval = ONE_DAY * 7;
    } else if (periodInMilliseconds <= ONE_YEAR) {
        tickerInterval = ONE_MONTH;
    } else {
        tickerInterval = ONE_YEAR;
    }

    return {
        title: undefined,
        legend: { enabled: false },
        yAxis: {
            title: undefined,
            crosshair: true,
            opposite: false,
            showLastLabel: true,
        },
        xAxis: {
            type: "datetime",
            lineWidth: 0,
            tickInterval: tickerInterval,
            tickWidth: 0,
            crosshair: true,
        },
        plotOptions: {
            series: {
                dataGrouping: {
                    enabled: false,
                },
            },
        },
        rangeSelector: { enabled: false },
        navigator: { enabled: false },
        tooltip: {
            shared: true,
            backgroundColor: "transparent",
            borderColor: "transparent",
            shadow: false,
            useHTML: true,
            className: "rounded-xl",
            style: {
                padding: "0px",
            },
            formatter() {
                const points = this.points;
                if (!points) return null;
                const startDate = Highcharts.dateFormat(
                    "%d %b %Y",
                    startTime.valueOf() + ONE_HOUR,
                );
                const endDate = Highcharts.dateFormat(
                    "%d %b %Y",
                    Number(this.x),
                );
                if (Number(this.x) < startTime.valueOf()) {
                    return null;
                }
                const dateRangeSection = html`
                    <div
                        class="flex items-center justify-center rounded-t-xl bg-gray-100 p-3 text-xs font-medium tabular-nums"
                    >
                        <span
                            class="text-charcoal inline-block w-[calc(50%-12px)] tabular-nums"
                        >
                            ${startDate}
                        </span>
                        <span class="inline-block w-6 text-center"> to </span>
                        <span
                            class="text-charcoal inline-block w-[calc(50%-12px)] tabular-nums"
                        >
                            ${endDate}
                        </span>
                    </div>
                `;
                let dataSection = "";
                for (let i = 0; i < points.length; i++) {
                    const colour = colors[i]![0];
                    if (!colour) throw new Error("Not enough colours!");
                    // TODO: complicated logic to get the correct label
                    const label = ["Fund", "Benchmark"][i];
                    if (!label) throw new Error("Not enough labels!");

                    // old implementation: we don't have seriesInfo to make label dynamic
                    // see Resources / Performance Line Chart

                    dataSection += html`
                        <div
                            style="background-color: ${colour}"
                            class="${cx(
                                {
                                    "rounded-b-xl w-full": points.length === 1,
                                    "w-1/2": points.length > 1,
                                    "rounded-bl-xl border-r-4 border-white":
                                        points.length > 1 && i === 0,
                                    "rounded-br-xl":
                                        points.length > 1 && i === 1,
                                },
                                "inline-flex flex-col items-center justify-center p-3 text-sm font-medium text-white",
                            )}"
                        >
                            <div class="drop-shadow-2xl">${label}</div>
                            <div class="tabular-nums drop-shadow-2xl">
                                ${Highcharts.numberFormat(points[i]!.y!, 2)}%
                            </div>
                        </div>
                    `;
                }
                return dateRangeSection + dataSection;
            },
            positioner(labelWidth, labelHeight, point) {
                if (!this.chart.container) return { x: 0, y: 0 };
                const style = getComputedStyle(this.chart.container, null);
                const height = parseFloat(style.height.replace("px", ""));
                const width = parseFloat(style.width.replace("px", ""));
                let xPos = point.plotX + labelWidth / 2 + 10;
                let yPos = point.plotY;
                if (xPos + labelWidth > width) {
                    xPos = point.plotX - labelWidth;
                }
                if (xPos < 0) {
                    xPos = 10;
                    yPos += 20;
                }
                if (yPos + labelHeight > height) {
                    yPos = height - labelHeight - 5;
                }
                return { x: xPos, y: yPos };
            },
        },
        series: [
            /********************************
             * TO DO:
             * We may need to be able to add multiple share class plots here
             * ****************************** */
            {
                name:
                    shareClassNameLength === "default"
                        ? share_class_name
                        : shareClassNameLength === "short"
                        ? share_class_short_name
                        : share_class_long_name,
                data: share_class_plots[0]?.data,
                color: colors[0]![0],
                lineWidth: 2,
                marker: {
                    symbol: "circle",
                },
                states: {
                    hover: {
                        lineWidthPlus: 0,
                    },
                },
                zIndex: 2,
            },
            ...benchmarkData.map((plot, i) => ({
                ...plot,
                color: colors[1]![i],
            })),
        ] as SeriesOptionsType[],
        accessibility: {
            enabled: false,
        },
    } satisfies Highcharts.Options;
};

export default generateOptions;
