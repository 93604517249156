import { type z } from "zod";

import { type peoplePartSchema } from "@hsl/fund-page/schemas";

import FundPageContent from "../../../components/FundPageContent";
import { PersonCard } from "../../../components/PersonCard/PersonCard";

const FundManagers = ({ part, title }: z.infer<typeof peoplePartSchema>) => {
    return (
        <>
            <FundPageContent.Header h3>{title}</FundPageContent.Header>
            <ul className="flex snap-x gap-4 overflow-x-auto xl:flex-wrap xl:gap-6 xl:overflow-x-hidden">
                {part.data.map((person) => {
                    return (
                        <li
                            key={`${person.firstName} ${person.lastName}`}
                            className="snap-center"
                        >
                            <PersonCard
                                {...person}
                                className="h-full w-max max-w-[85vw] sm:max-w-[378px]"
                            />
                        </li>
                    );
                })}
            </ul>
        </>
    );
};

export default FundManagers;
