import { useQuery } from "@tanstack/react-query";
import { type ZodObject } from "zod";

import type { PartConfig } from "@hsl/core/fund-page/schemas";
import { getDataFromPart } from "@hsl/fund-page/services";

function usePartData<T>(
    dataPartId: number,
    config: Partial<PartConfig> & {
        shareClassID?: string;
        pricePartStartDate?: string;
        pricePartEndDate?: string;
        pricePartDay?: string;
        pricePartMonth?: string;
        pricePartYear?: string;
    },
    schema: ZodObject<any>,
    enabled = true,
) {
    const query = useQuery({
        queryKey: ["partData", dataPartId, config],
        enabled: enabled,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const {
                audienceId,
                fundId,
                shareClassID,
                routeId,
                version,
                languageId,
                baseUrl,
                pricePartEndDate,
                pricePartStartDate,
                pricePartDay,
                pricePartMonth,
                pricePartYear,
            } = config;
            const partData = await getDataFromPart({
                partId: dataPartId,
                audienceId: String(audienceId),
                fundID: fundId,
                shareClassID: shareClassID,
                routeId: String(routeId),
                version: version,
                languageId,
                baseUrl,
                price_part_start_date: pricePartStartDate,
                price_part_end_date: pricePartEndDate,
                price_part_day: pricePartDay,
                price_part_month: pricePartMonth,
                price_part_year: pricePartYear,
            });
            const parsedData = schema.parse(partData);
            return parsedData as T;
        },
    });

    return query;
}

export default usePartData;
