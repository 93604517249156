import { type ReactElement } from "react";
import { type z } from "zod";

import cx from "@hsl/core/utils/cx";
import { type advancedDataTableSchema } from "@hsl/fund-page/schemas";

import * as Table from "../Table";
import { ZeroAlignedBar } from "./ZeroAlignedBar";

export interface Props {
    data: z.infer<typeof advancedDataTableSchema>;
    titleElem?: ReactElement;
    footnoteElem?: ReactElement;
    containerClassName?: string;
    barClassNames?: string[];
    barContainerClassName?: string;
    withSeparators?: boolean;
    valueClassNames?: string[];
}

export const BarTable = (props: Props) => {
    const {
        part: { data },
    } = props.data;

    const numericData = data.map((keyVal) => [
        keyVal[0],
        String(keyVal[1]).replace(/[^0-9.]/g, ""),
    ]);

    const largest = Math.max(
        ...numericData
            .map((r) => Number(r[r.length - 1]))
            .filter((x) => !isNaN(x)),
    );
    const smallest = Math.min(
        ...numericData
            .map((r) => Number(r[r.length - 1]))
            .filter((x) => !isNaN(x)),
    );

    return (
        <div className={cx("flex-1 pt-8", props.containerClassName)}>
            {!props.titleElem && props.titleElem}
            <Table.Table className="overflow-visible">
                <Table.Body>
                    {numericData.map((row) => {
                        return (
                            <Table.TR
                                key={row.join()}
                                withBorder={props.withSeparators}
                            >
                                {row?.map((value, j) => (
                                    <Table.TD
                                        key={j}
                                        className={cx(
                                            j > 0 && "text-right",
                                            "py-1.5 tabular-nums",
                                            props.valueClassNames &&
                                                props.valueClassNames[j],
                                        )}
                                    >
                                        {value ?? "-"}
                                        {props.withSeparators && j === 0 && (
                                            <Table.Separator onlyMobile />
                                        )}
                                    </Table.TD>
                                ))}
                                <Table.TD className="pl-4 text-right">
                                    <ZeroAlignedBar
                                        value={Number(row[row.length - 1])}
                                        maxValue={largest}
                                        minValue={smallest}
                                        barClassNames={props.barClassNames}
                                        barContainerClassName={
                                            props.barContainerClassName
                                        }
                                    />
                                </Table.TD>
                            </Table.TR>
                        );
                    })}
                </Table.Body>
            </Table.Table>
            {props.footnoteElem && props.footnoteElem}
        </div>
    );
};
