import {
    TextSkeleton,
    type TextSkeletonProps,
} from "@hsl/components/Skeletons";
import cx from "@hsl/core/utils/cx";

const Skeleton = ({ width, height, className }: TextSkeletonProps) => {
    return (
        <TextSkeleton
            width={width}
            height={height}
            className={cx("bg-secondary/25 mb-1 h-6 sm:mb-2", className)}
        />
    );
};

export default Skeleton;
