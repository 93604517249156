import { type ReactNode } from "react";

import cx from "@hsl/core/utils/cx";

import { CollapsibleLabel, CollapsibleTransition } from "./components";

interface Props {
    className?: string;
    label?: ReactNode | ((open: boolean) => ReactNode);
    labelClassNames?: string;
    withChevron?: boolean;
    children: ReactNode;
    isOpen?: boolean;
    onClick?: (args: any) => void;
}

const ControlledCollapsible = ({
    className,
    isOpen = true,
    children,
    label,
    labelClassNames,
    withChevron = true,
    onClick,
}: Props) => {
    return (
        <div className={cx("w-full", className)}>
            <CollapsibleLabel
                type="controlled"
                label={label}
                labelClassNames={labelClassNames}
                withChevron={withChevron}
                isOpen={isOpen}
                onClick={onClick}
            />
            <CollapsibleTransition show={isOpen}>
                {children}
            </CollapsibleTransition>
        </div>
    );
};

export default ControlledCollapsible;
