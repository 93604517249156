import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

interface Props {
    fallbackChild?: React.ReactNode;
    children: React.ReactNode;
}

export function GenericErrorBoundary({ fallbackChild, children }: Props) {
    const [show, setShow] = useState(false);

    const searchParams = new URLSearchParams(
        typeof window === "undefined" ? "" : window.location.search,
    );

    if (
        searchParams.get("version") === "draft" ||
        searchParams.get("branch") === "local" ||
        searchParams.get("version") === "preview"
    ) {
        return (
            <ErrorBoundary
                fallbackRender={({ error, resetErrorBoundary }) => (
                    <div className="w-full p-4" onClick={() => setShow(true)}>
                        <div className="relative flex w-full flex-col items-center border border-dashed border-red-500 bg-red-50 px-2 py-6 text-center text-red-500">
                            <p className="font-medium">Something went wrong</p>
                            <p>Please try again later</p>
                            {show && (
                                <>
                                    <pre className="text-charcoal mt-6 w-full overflow-x-auto rounded-sm border border-red-500 bg-white p-2 text-left text-xs">
                                        {String(error)}
                                    </pre>
                                    <button
                                        className="mt-2 self-end rounded-full border border-red-500 px-2 py-px text-xs"
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            setShow(false);
                                            resetErrorBoundary();
                                        }}
                                    >
                                        Give it another go (reset component)
                                    </button>
                                </>
                            )}
                            {typeof fallbackChild === "string" ? (
                                <p className="mt-1 text-xs">{fallbackChild}</p>
                            ) : (
                                fallbackChild
                            )}
                        </div>
                    </div>
                )}
            >
                {children}
            </ErrorBoundary>
        );
    }

    return (
        <ErrorBoundary
            fallback={
                <div className="w-full p-4" onClick={() => setShow(true)}>
                    <div className="border-warm-gray bg-platinum/50 relative flex w-full flex-col items-center border border-dashed px-2 py-6 text-center backdrop-blur">
                        <p className="font-medium">
                            Component under development
                        </p>
                        <p className="text-taupe-grey">
                            Please try again later
                        </p>
                    </div>
                </div>
            }
        >
            {children}
        </ErrorBoundary>
    );
}
