import React, { type PropsWithChildren } from "react";

import cx from "@hsl/core/utils/cx";

type TableCellProps = {
    wrapText?: boolean;
} & PropsWithChildren &
    React.ComponentPropsWithoutRef<"td">;

const TableCell = ({
    children,
    className,
    wrapText = false,
}: TableCellProps) => (
    <td
        className={cx(
            "relative p-0",
            {
                "whitespace-nowrap": !wrapText,
            },
            className,
        )}
    >
        {children}
    </td>
);

export default TableCell;
