import cx from "@hsl/core/utils/cx";

import CustomScroller from "../CustomScroller";

type TableProps = {
    className?: string;
    id?: string;
};

const Table = ({
    children,
    className,
    id,
}: TableProps & React.PropsWithChildren) => {
    return (
        <CustomScroller>
            <table
                id={id}
                className={cx(
                    "min-w-full table-fixed overflow-x-hidden",
                    className,
                )}
            >
                {children}
            </table>
        </CustomScroller>
    );
};

export default Table;
