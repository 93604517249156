import { useRef } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/es-modules/masters/highcharts.src.js";

import cx from "@hsl/core/utils/cx";

export interface Props {
    data: (string | null)[][];
    showLegend?: boolean;
    size?: number;
    colors: string[];
    additionalLegends?: React.ReactNode;
    className?: string;
    innerSize?: number;
    legendOptions?: LegendOptions;
}

export const PieChart = ({
    data,
    showLegend,
    size = 130,
    colors,
    additionalLegends,
    className,
    innerSize = 75,
    legendOptions,
}: Props) => {
    // @ts-expect-error HighChartsReact
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const options = {
        title: {
            text: undefined,
        },
        plotOptions: {
            pie: {
                size: size - 10,
                innerSize: `${innerSize}%`,
                colors: colors,
                allowPointSelect: false,
                dataLabels: { enabled: false },
                borderRadius: 0,
                borderWidth: 0,
            },
        },
        chart: { backgroundColor: "transparent" },
        legend: { enabled: false },
        series: [
            {
                type: "pie",
                // invalid according to typescript
                // uncomment + add ts-ignore if it turns out
                // to break things by removing it
                // colorByPoint: true,
                data: data.map((item) => ({
                    name: item[0] as string,
                    y: parseFloat(item[1] as unknown as string),
                })),
            },
        ],
        accessibility: { enabled: false },
        credits: { enabled: false },
    } satisfies Highcharts.Options;

    return (
        <div className={cx("flex gap-8 py-6", className)}>
            <HighchartsReact
                containerProps={{
                    style: { height: size, width: size, pointerEvents: "none" },
                }}
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
            />
            {showLegend && (
                <PieChartLegend
                    data={data}
                    colors={colors}
                    additionalLegends={additionalLegends}
                    {...legendOptions}
                />
            )}
        </div>
    );
};

interface LegendOptions {
    labelClassName?: string;
    valueClassName?: string;
    indicatorClassName?: string;
}

export const PieChartLegend = ({
    data,
    colors,
    additionalLegends,
    labelClassName,
    valueClassName,
    indicatorClassName,
}: Pick<Props, "data" | "colors" | "additionalLegends"> & LegendOptions) => {
    return (
        <div className="flex-1 flex-col justify-center text-sm">
            <table className="">
                <tbody>
                    {data.map((item, i) => (
                        <tr key={item[0]}>
                            <th className="pr-3 font-normal">
                                <span className="flex items-center gap-2">
                                    <span
                                        className={cx(
                                            "block h-3 w-3 shrink-0 rounded-full",
                                            indicatorClassName,
                                        )}
                                        style={
                                            colors[i]
                                                ? {
                                                      backgroundColor:
                                                          colors[i],
                                                  }
                                                : {}
                                        }
                                    />
                                    <span
                                        className={cx(
                                            "w-max text-left",
                                            labelClassName,
                                        )}
                                    >
                                        {item[0]}
                                    </span>
                                </span>
                            </th>
                            <td
                                className={cx(
                                    "ml-6 text-left font-medium",
                                    valueClassName,
                                )}
                            >
                                {item[1]}%
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {additionalLegends ? (
                <div className="mt-4">{additionalLegends}</div>
            ) : null}
        </div>
    );
};
