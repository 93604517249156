import { z } from "zod";

import {
    advancedDataTableSchema,
    dataPartSchema,
    lineChartSchema,
    shareClassSelectorSchema,
} from "@hsl/fund-page/schemas";

import FundPageContent from "../../components/FundPageContent";
import usePageSection from "../../hooks/usePageSection";
import {
    PerformanceLineChart,
    PerformanceShareClassSelector,
} from "./components";
import { PerformanceContextProvider } from "./context";
import {
    CalendarYearPerformance,
    Statistics,
    statisticsSchema,
    SummaryPerformance,
    summarySchema,
} from "./views";

const performanceSectionSchema = dataPartSchema.extend({
    canvas: dataPartSchema.extend({
        part: z.object({
            shareclassSelector: shareClassSelectorSchema,
            tables: z.object({
                part: z.object({
                    calendarYearPerformance: advancedDataTableSchema,
                    summary: summarySchema,
                }),
            }),
            statistics: statisticsSchema,
            lineChartLessThan5yOld: lineChartSchema,
            lineChart5yTo10yOld: lineChartSchema,
            lineChartMoreThan10yOld: lineChartSchema,
        }),
    }),
});

interface Props {
    dataKey: string;
}

const Performance = ({ dataKey }: Props) => {
    const { canvas, title } = usePageSection(dataKey, performanceSectionSchema);
    const {
        tables,
        statistics,
        shareclassSelector,
        lineChart5yTo10yOld,
        lineChartLessThan5yOld,
        lineChartMoreThan10yOld,
    } = canvas.part;
    const { calendarYearPerformance, summary } = tables.part;

    return (
        <PerformanceContextProvider shareClasses={shareclassSelector.part.data}>
            <FundPageContent.Section
                dataKey={dataKey}
                title={title}
                titleElem={<PerformanceShareClassSelector />}
                titleContainerClassName="flex items-center justify-between"
                titleClassName="w-full"
                className="flex-wrap xl:flex-nowrap"
            >
                <div className="flex max-w-full flex-[2] flex-col gap-y-6">
                    <FundPageContent.Box>
                        <PerformanceLineChart
                            underFive={lineChartLessThan5yOld}
                            fiveToTen={lineChart5yTo10yOld}
                            overTen={lineChartMoreThan10yOld}
                        />
                    </FundPageContent.Box>
                    <FundPageContent.Box className="flex flex-col gap-y-6">
                        <SummaryPerformance {...summary} key={summary.key} />
                        <CalendarYearPerformance
                            {...calendarYearPerformance}
                            key={calendarYearPerformance.key}
                        />
                    </FundPageContent.Box>
                </div>
                <FundPageContent.Box containerClassName="flex-[1]">
                    <Statistics {...statistics} key={statistics.key} />
                </FundPageContent.Box>
            </FundPageContent.Section>
        </PerformanceContextProvider>
    );
};

const PerformanceWithErrorBoundary = (props: Props) => {
    return (
        <FundPageContent.Wrapper dataKey={props.dataKey}>
            <Performance {...props} />
        </FundPageContent.Wrapper>
    );
};

export default PerformanceWithErrorBoundary;
