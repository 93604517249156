import { type ReactNode } from "react";

import cx from "@hsl/core/utils/cx";
import { useSidebarContext } from "@hsl/fund-page/hooks";

const Sidebar = ({ children }: { children: ReactNode }) => {
    const { sidebarOpen, toggleSidebar } = useSidebarContext();
    return (
        <>
            <div
                className={cx(
                    "fixed inset-0 z-10 bg-black/20 ",
                    !sidebarOpen && "hidden",
                )}
                onClick={toggleSidebar}
            />
            <div
                className={cx(
                    "fixed bottom-0 right-0 top-[68px] z-[10000] max-h-screen w-[80vw] translate-x-[80vw] overflow-y-scroll bg-gray-200 transition-transform",
                    { "translate-x-0": sidebarOpen },
                )}
            >
                {children}
            </div>
        </>
    );
};

export default Sidebar;
