import dayjs from "dayjs";
import { groupBy } from "ramda";

import { DocumentSet } from "@hsl/core/documents/types";

export const groupByDocType = groupBy(function (docSet: DocumentSet) {
    return docSet.lit_type_id.toString();
});

export const groupByYear = groupBy((set: DocumentSet) =>
    dayjs(set.date).format("YYYY"),
);

export const groupByMonth = groupBy((set: DocumentSet) =>
    dayjs(set.date).format("MM"),
);

export const groupByDay = groupBy((set: DocumentSet) =>
    dayjs(set.date).format("DD"),
);

const getMostRecentYear = (setArr: DocumentSet[]) =>
    Math.max(...setArr.map((x) => parseInt(dayjs(x.date).format("YYYY"))));

const getLatestMonth = (setArr: DocumentSet[], year: number) =>
    Math.max(
        ...setArr
            .filter((x) => dayjs(x.date).year() === year)
            .map((x) => parseInt(dayjs(x.date).format("MM")) - 1),
    );
