export function isLight(bgColor: string) {
    let r: number, g: number, b: number;
    if (bgColor.charAt(0) === "#") {
        r = parseInt(bgColor.substring(0, 2), 16); // hexToR
        g = parseInt(bgColor.substring(2, 4), 16); // hexToG
        b = parseInt(bgColor.substring(4, 6), 16); // hexToB
    } else {
        const rgba = bgColor
            .substring(5, bgColor.length - 1)
            .split(",")
            .map((x) => parseInt(x.trim()));
        r = rgba[0]!;
        g = rgba[1]!;
        b = rgba[2]!;
    }
    return r * 0.299 + g * 0.587 + b * 0.114 > 186;
}
