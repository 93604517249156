import {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";

import useInsights, { type Insight } from "../../hooks/useInsights";
import { useInsightsFilters } from "../../hooks/useInsightsFilters";

export type PostTypeObj = {
    id: string;
    name: string;
    color: string;
};

export type Company = {
    id: string | number;
    name: string;
    count?: number;
};

export type ManagerDiaryContextType = {
    isLoadingEntries: boolean;
    isLoadingFilters: boolean;
    companies: Company[];
    postTypes: PostTypeObj[];
    activePostTypes: string[];
    activeCompanies: Company[];
    reverseOrder: boolean;
    activeDiaryEntries: Insight[];
    isLoadingMore: boolean;
    setActivePostTypes: (vals: string[]) => void;
    toggleCompanyFilter: (company: Company) => void;
    addCompanyFilter: (company: Company) => boolean;
    removeCompanyFilter: (company: Company) => void;
    setReverseOrder: (bool: boolean) => void;
    clearFilters: () => void;
    loadNextPage: () => Promise<void>;
};

export interface Props {
    children: React.ReactNode;
    audienceId: number;
    fundId: number;
}

export const ManagerDiaryContext = createContext<
    ManagerDiaryContextType | undefined
>(undefined);

const ACTIVE_POST_TYPE_FILTERS: string[] = [
    "40",
    "2082",
    "2083",
    "2111",
    "2112",
    "2113",
];

type PostTypeFilter = { id: string; name: string; color: string };
const colors = {
    "40": "#b3b3cc",
    "2113": "#f4ccaa",
    "2112": "#f1e0c2",
    "2111": "#acc3e5",
    "2083": "#d8acd1",
    "2082": "#d3e2cb",
} as Record<string, string>;
const getOrSetColor = (id: string, color?: string) => {
    if (colors[id]) return colors[id];
    if (!color) return "#000";
    colors[id] = color;
    return color;
};

export const ManagerDiaryContextProvider = ({
    children,
    audienceId,
    fundId,
}: Props) => {
    const [activePostTypes, setActivePostTypes] = useState<string[]>([]);
    const [activeCompanies, setActiveCompanies] = useState<Company[]>([]);
    const [reverseOrder, setReverseOrder] = useState(false);

    const diaryFilters = useInsightsFilters({
        audience: audienceId,
        funds: [fundId],
        categories: [2079, 40],
        // activePostTypes.length > 0
        //     ? activePostTypes.map((id) => Number(id))
        //     : [2079, 40],
    });

    const { insights, isLoading, fetchNextPage, next, isLoadingMore } =
        useInsights({
            queryKey: "inTheLoupe",
            pageSize: 10,
            includePostBody: true,
            filters: {
                audiences: [audienceId],
                categories:
                    activePostTypes.length > 0 ? activePostTypes : [40, 2079],
                custom_tags: activeCompanies.map(({ id }) => id),
                funds: [fundId],
            },
        });

    const allCompanies = useMemo(() => {
        if (diaryFilters.isLoading && !diaryFilters.filters?.customTags)
            return [];
        return diaryFilters.filters?.customTags ?? [];
    }, [diaryFilters.filters, diaryFilters.isLoading]);

    const filteredPostTypes = useMemo(() => {
        const categories = diaryFilters.filters?.categories;
        if (categories) {
            return categories
                .map(({ name, id }) => ({
                    id: String(id),
                    name,
                    color: getOrSetColor(
                        `${id}`,
                        insights.find(({ category }) => category.name === name)
                            ?.category.colour as string,
                    ),
                }))
                .filter(
                    ({ id, color }) =>
                        color && ACTIVE_POST_TYPE_FILTERS.includes(id),
                ) as PostTypeFilter[];
        }

        return [];
    }, [diaryFilters.filters?.categories, insights]);

    const loadNextPage = useCallback(async () => {
        if (next && next.length > 0) {
            await fetchNextPage();
        }
    }, [next, fetchNextPage]);

    const addCompanyFilter = useCallback(
        (val: Company) => {
            if (!activeCompanies.find((c) => c.id === val.id)) {
                setActiveCompanies([...activeCompanies, val]);
                return true;
            }
            return false;
        },
        [activeCompanies, setActiveCompanies],
    );

    const removeCompanyFilter = useCallback(
        (val: Company) => {
            setActiveCompanies(activeCompanies.filter((x) => x.id !== val.id));
        },
        [activeCompanies, setActiveCompanies],
    );

    const toggleCompanyFilter = useCallback(
        (val: Company) => {
            const hasBeenAdded = addCompanyFilter(val);
            if (!hasBeenAdded) {
                removeCompanyFilter(val);
            }
        },
        [addCompanyFilter, removeCompanyFilter],
    );

    const clearFilters = useCallback(() => {
        setActivePostTypes([]);
        setActiveCompanies([]);
    }, [setActivePostTypes, setActiveCompanies]);

    const contextValue = useMemo<ManagerDiaryContextType>(() => {
        return {
            isLoadingEntries: isLoading,
            isLoadingFilters: diaryFilters.isLoading,
            companies: allCompanies,
            postTypes: filteredPostTypes,
            activeCompanies,
            activePostTypes,
            reverseOrder,
            activeDiaryEntries: insights,
            isLoadingMore,
            addCompanyFilter,
            removeCompanyFilter,
            toggleCompanyFilter,
            setActivePostTypes,
            setReverseOrder,
            clearFilters,
            loadNextPage,
        };
    }, [
        isLoading,
        diaryFilters,
        activePostTypes,
        activeCompanies,
        reverseOrder,
        insights,
        allCompanies,
        filteredPostTypes,
        isLoadingMore,
        addCompanyFilter,
        removeCompanyFilter,
        toggleCompanyFilter,
        clearFilters,
        setActivePostTypes,
        setReverseOrder,
        loadNextPage,
    ]);

    return (
        <ManagerDiaryContext.Provider value={contextValue}>
            {children}
        </ManagerDiaryContext.Provider>
    );
};

export function useManagerDiaryContext() {
    const documentContext = useContext(ManagerDiaryContext);
    if (!documentContext) throw new Error("No ManagerDiaryContextProvider");
    return documentContext;
}
