import * as React from "react";

const SvgWebinar = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={41}
        height={41}
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            d="M10.492 17.596h1.667v11.66a.833.833 0 1 1-1.667 0v-11.66Z"
        />
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M9.659 17.596H7.992v11.659a3.333 3.333 0 0 0 6.667 0v-11.66h-1.667v11.66a1.667 1.667 0 1 1-3.333 0v-11.66Z"
            clipRule="evenodd"
        />
        <path
            stroke="#fff"
            strokeWidth={2}
            d="M13.992 10.268h18v18.321a3 3 0 0 1-3 3h-15V10.268Z"
        />
        <path
            fill="#fff"
            d="M11.325 30.922h2.5v1.666h-2.5zM16.325 12.598h13.333v5.83H16.325zM16.325 20.094h13.333v3.332H16.325zM16.325 25.092h7.5v3.332h-7.5z"
        />
    </svg>
);
export default SvgWebinar;
