import { useCallback } from "react";

import Spinner from "../../../../components/Spinner";
import { useManagerDiaryContext, type Company } from "../../context";
import { CompanyPillCheckbox } from "./CompanyPillCheckBox";
import { FilterHeader, FilterSection } from "./FilterContent";

export const CompanyFilters = () => {
    const {
        companies,
        activeCompanies,
        isLoadingFilters,
        toggleCompanyFilter,
    } = useManagerDiaryContext();

    const isSelected = useCallback(
        (company: Company) => {
            return !!activeCompanies.find(({ id }) => id === company.id);
        },
        [activeCompanies],
    );
    const containerClassName = "grid grid-cols-1 gap-y-2";
    const orderedCompanies = companies.toSorted((a, b) =>
        a.name.localeCompare(b.name),
    );
    return (
        <FilterSection className={containerClassName}>
            <FilterHeader>
                <span className="relative block">
                    <span>Reference Tags</span>
                    {/* {isLoadingFilters && <Spinner className="h-4 w-4 border-2 absolute right-0 top-0" />} */}
                </span>
            </FilterHeader>
            {isLoadingFilters && companies.length === 0 ? (
                <Spinner className="w- h-4" />
            ) : (
                <>
                    <div className="flex flex-wrap gap-1">
                        {orderedCompanies.map((company) => (
                            <CompanyPillCheckbox
                                key={`${company.id}-filter`}
                                value={company}
                                selected={isSelected(company)}
                                label={
                                    <span>
                                        {company.name}{" "}
                                        {company.count && company.count > 1 ? (
                                            <span className="font-bold">
                                                ({company.count})
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                    </span>
                                }
                                onChange={toggleCompanyFilter}
                            />
                        ))}
                    </div>
                </>
            )}
        </FilterSection>
    );
};
