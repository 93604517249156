import * as React from "react";

const SvgMapPin = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={35}
        height={34}
        fill="none"
        {...props}
    >
        <ellipse
            cx={17.437}
            cy={17.388}
            fill="currentColor"
            rx={16.592}
            ry={16.583}
        />
        <path
            fill="#fff"
            d="M17.779 7.912a6.876 6.876 0 0 0-6.883 6.88c0 5.159 6.883 12.775 6.883 12.775s6.883-7.616 6.883-12.776a6.876 6.876 0 0 0-6.883-6.879Zm0 9.336a2.458 2.458 0 0 1-2.458-2.457 2.458 2.458 0 0 1 4.916 0 2.458 2.458 0 0 1-2.458 2.457Z"
        />
    </svg>
);
export default SvgMapPin;
