import { createContext, useContext, useMemo, useState } from "react";

import { type ShareClassOption } from "@hsl/fund-page/schemas";

export type PerformanceContextType = {
    shareClasses: ShareClassOption[];
    activeShareClass: ShareClassOption;
    setActiveShareClass(shareClass: ShareClassOption): void;
};

export interface Props {
    children: React.ReactNode;
    shareClasses: ShareClassOption[];
}

export const PerformanceContext = createContext<
    PerformanceContextType | undefined
>(undefined);

export const PerformanceContextProvider = ({
    children,
    shareClasses,
}: Props) => {
    const [activeShareClass, setActiveShareClass] = useState<ShareClassOption>(
        shareClasses[0]!,
    );

    const contextValue = useMemo(
        () => ({
            activeShareClass,
            shareClasses,
            setActiveShareClass,
        }),
        [shareClasses, activeShareClass, setActiveShareClass],
    );

    return (
        <PerformanceContext.Provider value={contextValue}>
            {children}
        </PerformanceContext.Provider>
    );
};

export function usePerformanceContext() {
    const performanceContext = useContext(PerformanceContext);
    if (!performanceContext) throw new Error("No PerformanceContextProvider");
    return performanceContext;
}
