import { z } from "zod";

import { peoplePartSchema, simplePartSchema } from "@hsl/fund-page/schemas";

import FundPageContent from "../../../components/FundPageContent";
import TextDataPart from "../../../components/TextDataPart";

export const authorsSchema = z.object({
    diaryOverview: simplePartSchema,
    author: peoplePartSchema,
});

export type AuthorsSchema = z.infer<typeof authorsSchema>;

const Authors = ({ diaryOverview, author }: AuthorsSchema) => {
    return (
        <>
            <FundPageContent.Header>
                {diaryOverview.part.data.title}
            </FundPageContent.Header>
            <ul className="grid grid-cols-3 gap-x-[14px] gap-y-6 py-3">
                {author.part.data.map(
                    ({ headshot, firstName, lastName, role }) => {
                        const fullName = `${firstName} ${lastName}`;
                        return (
                            <li
                                key={fullName}
                                className="flex flex-col items-start "
                            >
                                <img
                                    src={headshot}
                                    alt={fullName}
                                    className="mb-1 h-[60px] w-[60px] rounded-full"
                                />
                                <span className="text-primary my-[2px] text-[13px] font-normal leading-none">
                                    {fullName}
                                </span>
                                <span className="text-[10px] font-normal uppercase leading-none">
                                    {role}
                                </span>
                            </li>
                        );
                    },
                )}
            </ul>
            <TextDataPart
                data={diaryOverview.part.data.text}
                className="mt-4"
            />
        </>
    );
};

export default Authors;
