import LiteratureSkeleton from "../../views/Literature/components/LiteratureSkeleton";
import FundPageContent from "../FundPageContent";
import { PersonCardSkeleton } from "../PersonCard";
import Skeleton from "./Skeleton";

const FundPageSkeleton = () => {
    return (
        <div className="bg-offWhite max-w-screen relative z-0 min-h-screen overflow-hidden pb-60">
            <div className="container mx-auto px-0 pt-4">
                <div className="hidden sm:block">
                    <FundPageSkeletonDesktop />
                </div>
                <div className="sm:hidden">
                    <FundPageSkeletonMobile />
                </div>
            </div>
        </div>
    );
};

export default FundPageSkeleton;

const FundPageSkeletonMobile = () => (
    <>
        {/* Overview */}
        <Skeleton width={"100%"} height={"325px"} />
        <Skeleton width={"100%"} height={"690px"} />
        {/* Fund managers */}
        <div className="flex gap-x-6 py-4">
            <PersonCardSkeleton width="300px" />
        </div>
        {/* Commentary & Portfolio */}
        <FundPageContent.HeaderSkeleton />
        <div className="flex flex-col gap-y-4">
            <Skeleton width={"100%"} height={"850px"} />
            <Skeleton width={"100%"} height={"775px"} />
            <Skeleton width={"100%"} height={"870px"} />
        </div>
        {/* Performance */}
        <FundPageContent.HeaderSkeleton />
        <div className="flex flex-col gap-y-4">
            <Skeleton width={"100%"} height={"680px"} />
            <Skeleton width={"100%"} height={"860px"} />
        </div>
        {/* Prices and dividends */}
        <FundPageContent.HeaderSkeleton />
        <div className="flex flex-col gap-y-4">
            <Skeleton width={"100%"} height={"680px"} />
            <Skeleton width={"100%"} height={"710px"} />
            <Skeleton width={"100%"} height={"190px"} />
            <Skeleton width={"100%"} height={"340px"} />
        </div>
        {/* Literature */}
        <FundPageContent.HeaderSkeleton />
        <div className="flex flex-col gap-y-4">
            <Skeleton width={"100%"} height={"200px"} />
            <Skeleton width={"100%"} height={"470px"} />
        </div>
        {/* Fund Facts */}
        <FundPageContent.HeaderSkeleton />
        <div className="flex flex-col gap-y-4">
            <Skeleton width={"100%"} height={"510px"} />
            <Skeleton width={"100%"} height={"440px"} />
            <Skeleton width={"100%"} height={"400px"} />
            <Skeleton width={"100%"} height={"220px"} />
        </div>
        <Skeleton width={"100%"} height={"1700px"} />
        <Skeleton width={"100%"} height={"1300px"} />
    </>
);

const FundPageSkeletonDesktop = () => (
    <>
        <Skeleton width={"100%"} height={"80px"} className="m-0" />
        <Skeleton width={"100%"} height={"56px"} className="m-0 opacity-40" />
        <div className="pt-8">
            {/* Overview */}
            <div className="flex-col">
                <div className="flex flex-1 gap-6">
                    <Skeleton className="flex-1" height={"500px"} />
                    <Skeleton className="flex-[2]" height={"500px"} />
                </div>
                <Skeleton width={"100%"} height={"92px"} />
            </div>
            {/* Fund managers */}
            <FundPageContent.HeaderSkeleton className="mt-10" />
            <div className="flex gap-x-6 py-4">
                <PersonCardSkeleton className="flex-1" />
                <PersonCardSkeleton className="flex-1" />
                <PersonCardSkeleton className="flex-1" />
            </div>
            <div className="flex justify-between">
                <FundPageContent.HeaderSkeleton />
                <FundPageContent.HeaderSkeleton />
            </div>
            <div className="flex gap-x-6 py-4">
                <PersonCardSkeleton className="flex-1" />
                <PersonCardSkeleton className="flex-1" />
                <PersonCardSkeleton className="flex-1" />
            </div>
            {/* Commentary & Portfolio */}
            <FundPageContent.SectionHeaderSkeleton />
            <div className="flex gap-x-6">
                <Skeleton className="flex-[2]" height={"620px"} />
                <Skeleton className="flex-[1]" height={"620px"} />
            </div>
            <Skeleton width={"100%"} height={"450px"} />
            {/* Performance */}
            <FundPageContent.SectionHeaderSkeleton />
            <div className="flex gap-x-6">
                <div className="flex-[2]">
                    <Skeleton width={"100%"} height={"620px"} />
                    <Skeleton width={"100%"} height={"470px"} />
                </div>
                <Skeleton className="flex-[1]" height={"1090px"} />
            </div>
            {/* Prices and Dividends */}
            <FundPageContent.SectionHeaderSkeleton />
            <div className="flex gap-x-6">
                <Skeleton className="flex-[2]" height={"480px"} />
                <Skeleton className="flex-1" height={"820px"} />
            </div>
            <div className="flex gap-x-6">
                <Skeleton width={"360px"} height={"248px"} />
                <Skeleton className="flex-1" height={"377px"} />
            </div>
            {/* Literature */}
            <FundPageContent.SectionHeaderSkeleton />
            <LiteratureSkeleton />
            {/* Fund Facts */}
            <FundPageContent.SectionHeaderSkeleton />
            <div className="flex gap-x-6">
                <Skeleton width={"384px"} height={"460px"} />
                <Skeleton width={"384px"} height={"460px"} />
                <Skeleton width={"384px"} height={"460px"} />
            </div>
            <Skeleton height={"200px"} width={"100%"} />
        </div>
    </>
);
