import { useMemo } from "react";
import { createPortal } from "react-dom";

import { dataPartSchema, formDataSchema } from "@hsl/fund-page/schemas";

import SignUpForm from "../../../components/SignUpForm";
import usePageSection from "../../../hooks/usePageSection";

const signUpSchema = dataPartSchema.extend({
    data: formDataSchema,
});

const SignUp = () => {
    const { data, title, portal } = usePageSection("signUp", signUpSchema);

    const portalElems = useMemo(
        () => document.querySelectorAll(`[data-react-portal="${portal}"]`),
        [],
    );

    if (portalElems.length === 0) {
        return null;
    }

    return Array.from(portalElems).map((portal) =>
        createPortal(
            <SignUpForm
                label={String(title)}
                partVersionId={data.partVersionId}
                fundName={data.fundName}
                buttonClassNames="mt-0"
                panelClassName="lg:left-0 lg:top-10"
            />,
            portal,
        ),
    );
};

export default SignUp;
