import type {
    PostListFilterData,
    PostListFilterItem,
} from "@hsl/fund-page/schemas";

import { FilterDropdown } from "../../../components/Dropdown";
import {
    groupFilterItemByTenantName,
    sortPostListFilterItem,
} from "../../../lib/groupFilterItemByTenantName";
import { useNewsAndViewsContext } from "../context";

export const Filters = ({ filters }: { filters: PostListFilterData }) => {
    const {
        activeFund,
        activeContributor,
        activeCategory,
        setActiveFund,
        setActiveContributor,
        setActiveCategory,
    } = useNewsAndViewsContext();

    const { funds, categories, contributors } = filters;

    return (
        <div className="flex items-center gap-x-4 lg:justify-end">
            <span className="hidden sm:inline">Filter by</span>
            <div className="flex flex-col items-center gap-x-4 gap-y-2 pt-2 sm:flex-row sm:pt-0 lg:min-w-[800px]">
                {contributors && (
                    <FilterDropdown<PostListFilterItem | null>
                        placeholder="Contributor"
                        value={activeContributor}
                        toLabel={(x) => String(x?.name)}
                        options={contributors.filter(
                            (c) => Number.parseInt(`${c.id}`) > 0,
                        )}
                        containerClassName="flex-1 w-full sm:w-max"
                        onChange={setActiveContributor}
                        onCancel={() => setActiveContributor(null)}
                    />
                )}
                {funds && (
                    <FilterDropdown<PostListFilterItem | null>
                        placeholder="Fund"
                        value={activeFund}
                        toLabel={(x) => String(x?.name)}
                        options={sortPostListFilterItem(
                            Object.values(
                                groupFilterItemByTenantName(
                                    funds as PostListFilterItem[],
                                ),
                            ),
                        )}
                        className="min-w-[250px]"
                        containerClassName="flex-[2]"
                        onChange={setActiveFund}
                        onCancel={() => setActiveFund(null)}
                    />
                )}
                {categories && (
                    <FilterDropdown<PostListFilterItem | null>
                        placeholder="Category"
                        value={activeCategory}
                        toLabel={(x) => String(x?.name)}
                        options={categories.toSorted((a, b) =>
                            a.name.localeCompare(b.name),
                        )}
                        containerClassName="flex-1 w-full sm:w-max"
                        onChange={setActiveCategory}
                        onCancel={() => setActiveCategory(null)}
                    />
                )}
            </div>
        </div>
    );
};
