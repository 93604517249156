import cx from "@hsl/core/utils/cx";

const Author = ({
    name,
    primaryHeadshot,
    className,
}: {
    name: string;
    primaryHeadshot: string;
    className?: string;
}) => {
    const fullName = name || "";
    const imgSrc = primaryHeadshot
        ? `http://www.springcapitalpartners.com/static/${primaryHeadshot}`
        : "";
    return (
        <div className={cx("flex items-center gap-3", className)}>
            <img
                src={imgSrc}
                alt={fullName}
                className="h-[40px] w-[40px] rounded-full object-cover"
            />
            <span className="hidden text-base font-normal text-black sm:inline-block">
                {fullName}
            </span>
        </div>
    );
};

export default Author;
