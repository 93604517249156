import React, {
    forwardRef,
    type ElementType,
    type PropsWithChildren,
} from "react";

import cx from "@hsl/core/utils/cx";

type TableHeaderProps = {
    withBorder?: boolean;
    wrapText?: boolean;
} & PropsWithChildren &
    React.ComponentPropsWithRef<"th">;

//type for HTMLTableHeaderCell is deprecated
const TableHeader = forwardRef<
    React.ComponentPropsWithRef<ElementType<any>>,
    TableHeaderProps
>(
    (
        {
            children,
            className,
            withBorder = true,
            wrapText = false,
            style,
        }: TableHeaderProps,
        ref,
    ) => {
        return (
            <th
                ref={ref}
                style={style}
                className={cx(
                    {
                        "border-charcoal border-b": withBorder,
                        "whitespace-nowrap": !wrapText,
                    },
                    className,
                )}
            >
                {children}
            </th>
        );
    },
);

export default TableHeader;
