import cx from "@hsl/core/utils/cx";

import { CaretTriangle } from "../icons";

export const ChangeIndicator = ({ val }: { val: number | string }) => {
    const numVal = typeof val === "string" ? parseFloat(val) : val;
    if (numVal === 0) {
        return (
            <span className=" mb-px ml-1 inline-block min-h-[7px] min-w-[7px] bg-orange-400" />
        );
    }
    return (
        <CaretTriangle
            fill={numVal > 0 ? "green" : "red"}
            width={8}
            height={8}
            className={cx("ml-1 inline", {
                "rotate-180 transform": numVal < 0,
            })}
        />
    );
};
