import { type ReactNode } from "react";

import cx from "@hsl/core/utils/cx";

import { useTabsContext } from "./context";

export const Tab = ({
    text,
    isActive,
    onClick,
}: {
    text: string;
    isActive: boolean;
    onClick?(): void;
}) => {
    return (
        <button
            className={cx(
                "bg-offWhite hover:bg-secondary/75 rounded-xl px-4 py-1 hover:text-white",
                isActive && "bg-secondary hover:bg-secondary text-white",
            )}
            onClick={onClick}
        >
            {text}
        </button>
    );
};

export const TabContainer = ({
    className,
    children,
}: {
    children: ReactNode;
    className?: string;
}) => (
    <nav className={cx("flex gap-x-3 gap-y-2", className)} aria-label="Tabs">
        {children}
    </nav>
);

interface TabsProps {
    tabs?: string[];
    tabsContainerClassName?: string;
    content?: ReactNode[];
    contentContainerClassName?: string;
}

const Tabs = ({
    tabsContainerClassName,
    tabs = [],
    content = [],
    contentContainerClassName,
}: TabsProps) => {
    const { activeTabIndex, setActiveTabIndex } = useTabsContext();
    return (
        <div>
            {tabs?.length > 0 && (
                <TabContainer
                    className={tabsContainerClassName}
                    aria-label="Tabs"
                >
                    {tabs.map((str, i) => (
                        <Tab
                            key={str}
                            text={str}
                            isActive={activeTabIndex === i}
                            onClick={() => setActiveTabIndex(i)}
                        />
                    ))}
                </TabContainer>
            )}
            {content.length > 0 && (
                <div className={cx(contentContainerClassName)}>
                    {content.map((elem, i) => (
                        <div
                            key={`${i}-content`}
                            className={cx(i !== activeTabIndex && "hidden")}
                        >
                            {elem}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Tabs;
