import * as React from "react";

const SvgCalendar = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={13}
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M10.667 1.3H10V0H8.667v1.3H3.333V0H2v1.3h-.667C.593 1.3 0 1.885 0 2.6v9.1c0 .715.593 1.3 1.333 1.3h9.334C11.4 13 12 12.415 12 11.7V2.6c0-.715-.6-1.3-1.333-1.3Zm0 10.4H1.333V4.55h9.334v7.15ZM2.333 7.15c0-.897.747-1.625 1.667-1.625s1.667.728 1.667 1.625S4.92 8.775 4 8.775 2.333 8.047 2.333 7.15Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgCalendar;
