import { createContext, useContext, useMemo, useState } from "react";

type SidebarContextType = {
    sidebarOpen: boolean;
    toggleSidebar: (this: void) => void;
};

export const SidebarContext = createContext<SidebarContextType | undefined>(
    undefined,
);

interface Props {
    children: React.ReactNode;
}

export const SidebarProvider = ({ children }: Props) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const contextValue = useMemo(
        () => ({
            sidebarOpen: sidebarOpen,
            toggleSidebar: () => setSidebarOpen((s) => !s),
        }),
        [sidebarOpen],
    );

    return (
        <SidebarContext.Provider value={contextValue}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebarContext = () => {
    const sidebar = useContext(SidebarContext);
    if (!sidebar) {
        throw Error("No sidebar context");
    }
    return sidebar;
};
