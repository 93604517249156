import Dropdown, { type Props } from "@hsl/components/Dropdown";
import cx from "@hsl/core/utils/cx";

export const SPRING_DROPDOWN_PROPS = {
    className: "",
    buttonClassName:
        "bg-offWhite h-10 sm:h-[42px] text-purple px-4 rounded-lg focus:outline-purple",
    optionClassName: "w-full pr-4 text-black",
    chevronClassName: "ml-4",
    optionScrollerClassNames: "max-h-[15rem] z-20",
};

const SpringDropdown = <T,>(props: Props<T>) => {
    const {
        className,
        buttonClassName,
        optionClassName,
        chevronClassName,
        optionScrollerClassName,
        ...rest
    } = props;
    return (
        <Dropdown<T>
            className={cx(SPRING_DROPDOWN_PROPS.className, className)}
            buttonClassName={cx(
                SPRING_DROPDOWN_PROPS.buttonClassName,
                buttonClassName,
            )}
            optionClassName={cx(
                SPRING_DROPDOWN_PROPS.optionClassName,
                optionClassName,
            )}
            chevronClassName={cx(
                SPRING_DROPDOWN_PROPS.chevronClassName,
                chevronClassName,
            )}
            optionScrollerClassName={cx(
                SPRING_DROPDOWN_PROPS.optionScrollerClassNames,
                optionScrollerClassName,
            )}
            {...rest}
        />
    );
};

export default SpringDropdown;

export { Props as DropdownProps };
