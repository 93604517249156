import { useEffect, useState } from "react";

function useSignUpForm(baseURL = "", successTimeout?: number) {
    const [success, setSucess] = useState<boolean>();
    const onSubmit = async (args: Record<string, string>) => {
        try {
            const formData = new FormData();
            Object.keys(args).forEach((key) => {
                const val = args[key as keyof typeof args];
                if (val) {
                    formData.append(key, val);
                }
            });
            const res = await fetch(`${baseURL}/srp/user_data/multi_tenant/`, {
                method: "POST",
                body: formData,
            });
            if (res.ok) {
                setSucess(true);
            } else {
                setSucess(false);
            }
        } catch (error) {
            setSucess(false);
        }
    };

    useEffect(() => {
        if (successTimeout) {
            setTimeout(() => {
                setSucess(undefined);
            }, successTimeout);
        }
    }, [success]);

    return {
        success,
        onSubmit,
        setSucess,
    };
}

export default useSignUpForm;
