import { forwardRef, Ref, type InputHTMLAttributes } from "react";
import { FieldError } from "react-hook-form";

import cx from "@hsl/core/utils/cx";

export type Props = InputHTMLAttributes<HTMLInputElement> & {
    error?: FieldError;
};

export const TextInput = forwardRef((props: Props, ref) => {
    return (
        <input
            ref={ref as Ref<HTMLInputElement>}
            {...props}
            className={cx(
                "bg-offWhite focus:outline-purple w-full rounded-lg px-3 py-2",
                "placeholder:text-corePurple",
                Boolean(props.error) && "outline outline-red-300",
            )}
        />
    );
});
