import { z } from "zod";

import { type CSVFieldConfig } from "@hsl/fund-explorer/services";

import { type FilterType } from "../store/types";

export interface Config {
    audienceId: number;
    audience_id: number;
    litStructureId: number;
    jurisdictionId: number;
    usertypeId: number;
    productType: ProductType;
    columns: ColumnConfig[];
    filters: FilterConfig[];
    activeFilters?: string[][];
    fundId?: number;
    groupOptions: GroupOptionConfig[];
    id: number;
    fundCentreId: FundCentreIdMap;
    languageId: number;
    search: SearchConfig;
    tabs: TabConfig[];
    url: string;
    routeId: number;
    infoPartId: number;
    downloadFields: CSVFieldConfig[];
    downloadFileName?: string;
    activeShareClassISIN: string | null;
    translations: string;
    locale: string;
}

export interface SearchConfig {
    label: string;
    minChars: number;
    placeholder: string;
    searchOn: string[];
}

export interface FilterConfig {
    filterChoices?: boolean;
    hashLabel?: string;
    key: string;
    prop: string;
    label: string;
    level: FilterLevel;
    multi?: boolean;
    options?: OptionConfig[];
    required: boolean;
    tooltip?: string;
    type: FilterType;
    overrides?: FilterOverride[];
    open?: boolean;
    ordering?: string[];
    orderDirective?: {
        type: "alphabetical";
        direction: "asc" | "desc";
    };
}

export type FilterLevel = "fund" | "shareclass" | "listing";

export type FilterOverride = {
    type: "*" | "usertype" | "jurisdiction" | "audience" | "language";
    ids: number[];
    label?: string;
    options?: OptionConfig[];
};

export type AudienceConfig = {
    usertypeId: number;
    audienceId: number;
    languageId: number;
    jurisdictionId: number;
};

export type OptionConfigValue =
    | string
    | number
    | boolean
    | (string | number | boolean)[];

export interface OptionConfig {
    label: string;
    value: OptionConfigValue;
    disabled?: boolean;
    key?: string;
    prop?: string;
    level?: FilterLevel;
    associatedFilters?: AssociatedFilters;
}

export type AssociatedFilters = {
    [filterKey: string]: OptionConfigValue[];
};

export interface GroupOptionConfig {
    key: string;
    prop: string;
    label: string;
    ordering: string[];
}

export interface TabConfig {
    key: string;
    label: string;
    columns?: TabColumnConfig[];
    columnsMobile?: TabColumnConfig[];
    tabs?: string[];
    parent?: string;
    footnote?: string;
    preface?: string;
    displayDirectives?: TabDisplayDirectives;
    groupOn?: string;
}

export type TabDisplayDirectives = {
    boldHeaders?: boolean;
    alignRight?: boolean;
    alignCenter?: boolean;
    alignLeft?: boolean;
};

export interface TabColumnConfig extends ColumnConfig {
    min?: number;
    max?: number;
    step?: number;
}

export type Token = "year" | "quarter" | "month" | "week" | "day";

export const productTypeSchema = z.union([
    z.literal("etf"),
    z.literal("pmc"),
    z.literal("collective"),
]);

export type ProductType = z.infer<typeof productTypeSchema>;

export const columnDisplayDirectivesSchema = z.object({
    pinOnMobile: z.boolean(),
    hideOnMobile: z.boolean(),
    hideOnDesktop: z.boolean(),
    withBackground: z.boolean().or(z.string()),
    withBackgroundHeader: z.boolean().or(z.string()),
    highlightText: z.boolean().or(z.string()),
    gapLeft: z.boolean(),
    gapRight: z.boolean(),
    tooltip: z.string(),
    reducedPadding: z.boolean(),
    noPadding: z.boolean(),
    boldHeader: z.boolean(),
    hideHeader: z.boolean(),
    alignCenter: z.boolean(),
    alignRight: z.boolean(),
    alignLeft: z.boolean(),
    alignOnDecimal: z.boolean(),
    suffix: z.string(),
    minWidth: z.string(),
});

export type ColumnDisplayDirectives = z.infer<
    typeof columnDisplayDirectivesSchema
>;

export type FundCentreIdMap = {
    id: number;
    audiences: number[];
    productType?: ProductType;
}[];

export interface ColumnDependency {
    level: string;
    prop: "priceType";
    values: (null | string)[];
}

export const columnDependencySchema = z.object({
    level: z.string(),
    prop: z.literal("priceType"),
    values: z.array(z.string().or(z.null())),
});

const columnConfigSchemaBase = z.object({
    key: z.string(),
    prop: z.string(),
    level: z.string(),
    label: z.string(),
    hidden: z.boolean().optional(),
});

export const columnConfigSchema = columnConfigSchemaBase.extend({
    sortable: z.boolean().optional(),
    iterable: z.boolean().optional(),
    displayDirectives: columnDisplayDirectivesSchema.partial(),
    dependencies: z.array(columnDependencySchema).optional(),
    overrides: z
        .array(
            z.object({
                type: z.literal("fund").optional(),
                prop: z.literal("string").optional(),
                value: z
                    .array(z.string().or(z.boolean()).or(z.null()))
                    .optional(),
                override: columnConfigSchemaBase.partial(),
                card_variant: z.string(),
                options: z.object({
                    hidden: z.boolean().optional(),
                }),
            }),
        )
        .optional(),
    localeSpecific: z.literal("number").or(z.literal("date")).optional(),
});

export type ColumnConfig = z.infer<typeof columnConfigSchema>;
