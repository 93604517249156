import { string, z } from "zod";

import {
    columnConfigSchema,
    productTypeSchema,
} from "@hsl/lgim-explorer/src/config/types";

export type FundPagePartDataArgs = {
    baseUrl?: string;
    partId: string | number;
    audienceId: string | number;
    routeId: string | number;
    fundID?: string | number;
    shareClassID?: string | number;
    languageId?: string | number;
    version?: string;
    asAtDate?: string;
    usertypeShortName?: string;
    jurisdictionShortName?: string;
    price_part_start_date?: string;
    price_part_end_date?: string;
    price_part_day?: string;
    price_part_month?: string;
    price_part_year?: string;
    postId?: string;
};

export type PerformanceChartArgs = {
    start_date?: string;
    end_date?: string;
    period_length?: PerformanceChartPeriod;
} & FundPagePartDataArgs;

export type PageSection = {
    key: string;
    title?: string;
    shortTitle?: string;
    parts: any;
    portal?: string;
    icon?: string;
    data?: number;
    meta?: any;
};

export const partConfigSchema = z.object({
    portalElem: z.any(),
    partId: z.string(),
    audienceId: z.string(),
    jurisdictionId: z.string(),
    languageId: z.string(),
    routeId: z.string(),
    fundId: z.string(),
    version: z.string(),
    baseUrl: z.string().optional(),
});

export type PartConfig = z.infer<typeof partConfigSchema>;

const fundCentreIdMapSchema = z.array(
    z.object({
        id: z.number(),
        audiences: z.array(z.number()),
        productType: productTypeSchema.optional(),
    }),
);

const configPageSectionOverrideOptionsSchema = z.object({
    type: z.literal("productType"), // add more as needed
    value: z.string().or(z.number()), // replacement value
    match: z.string().or(z.number()), // value to match
});

export const configPageSectionSchema = z.object({
    key: z.string(),
    title: z.string().optional(),
    shortTitle: z.string().optional(),
    data: z.number().optional(),
    portal: z.string().optional(),
    icon: z.string().optional(),
    tenant: z.string().optional(),
    baseURL: z.string().optional(),
    overrides: z
        .object({
            title: configPageSectionOverrideOptionsSchema.optional(),
            data: configPageSectionOverrideOptionsSchema.optional(),
        })
        .optional(),
    litApiId: z.number().optional(),
    meta: z.record(z.any()).optional(),
    postApiId: z.number().optional(),
});

export type ConfigPageSection = z.infer<typeof configPageSectionSchema>;

export const configSchema = z.object({
    id: z.number().optional(),
    banner: z.number(),
    fundCentreId: fundCentreIdMapSchema,
    audienceId: z.number(),
    jurisdictionId: z.number(),
    jurisdictionShortName: z.string().optional(),
    usertypeId: z.number(),
    usertypeName: z.string(),
    usertypeShortName: z.string().optional(),
    languageId: z.number(),
    fundId: z.number(),
    routeId: z.number(),
    umbrellaId: z.number(),
    base_url: z.string(),
    url: z.string(),
    priceTypes: z
        .array(
            z.object({
                values: z.array(z.string().or(z.null())),
                props: z.object({
                    price: z.string().or(z.array(z.string())),
                    label: z.string().or(z.array(z.string())),
                    date: z.string().or(z.array(z.string())),
                    change: z.string().or(z.array(z.string())).optional(),
                }),
            }),
        )
        .optional(),
    pageSections: z.array(configPageSectionSchema),
    productType: z.enum(["pmc", "etf", "collective"]).optional(),
    translations: z.number(),
    shareClassesPart: z.number(),
    showTranslations: z.boolean().optional(),
    activeShareClassISIN: z.string().or(z.null()).optional(),
    infoPartId: z.number().optional(),
    locale: z.string(),
    fetchLanguageAware: z.literal(true).optional(),
    postId: z.string().optional(),
    version: z.enum(["live", "draft", "preview"]).default("live"),
});

export type Config = z.infer<typeof configSchema>;

/*********************************
 *  DATA ELEMS
 **********************************/
export const DATA_ATTRIBUTES = [
    "tabs",
    "tab",
    "parseAsScript",
    "list",
    "takeAllParts",
] as const;

export type DataAttributes = (typeof DATA_ATTRIBUTES)[number];

export type ElemData = {
    part: Record<string, any>;
    tab?: true;
    tabs?: true;
    parseAsScript?: true;
    list?: true;
    takeAllParts?: true;
};

export const dataPartSchema = z.object({
    key: z.string(),
    title: z.string().optional(),
    useShareclassData: z.string().optional(),
    useFundData: z.string().optional(),
    partId: z.number().optional(),
    portal: z.string().optional(),
    litApiId: z.number().optional(),
    postApiId: z.number().optional(),
    order: z.number().or(z.string()).optional(),
});
export type DataPart = z.infer<typeof dataPartSchema>;

export const dataCanvasSectionSchema = z.object({
    data: dataPartSchema,
    key: z.string(),
    title: z.string().optional(),
});
export type DataCanvasSection = z.infer<typeof dataCanvasSectionSchema>;

/** PART TYPE DATA ********************************/

/** headers **************************************/
export const headersSchema = z.array(z.string()).optional().nullable();

/** configuration *********************************/
const confSchema = z
    .object({
        className: z.string().optional(),
        headerPhrase: z.number().optional(),
        columns: z
            .array(columnConfigSchema.partial().nullable())
            .optional()
            .nullable(),
        text: z.string().optional(),
        tooltip: z.string().optional(),
        title: z.string().optional(),
    })
    .optional()
    .nullable();
export type ConfSchema = z.infer<typeof confSchema>;

/** data part type *********************************/
export type DataPartType =
    | "Text"
    | "DataTable"
    | "ShareClassData"
    | "RecordArray"
    | "Prices"
    | "AvailableDates";

/** advanced data-table *********************************/
export const advancedDataTableData = z.object({
    type: z.literal("DataTable"),
    data: z.array(z.array(z.string().nullable())),
    headers: headersSchema,
    conf: confSchema,
    note: z.string().optional(),
    title: z.string().optional(),
    partId: z.number(),
});
export type AdvancedDataTableData = z.infer<typeof advancedDataTableData>;
export const advancedDataTableSchema = dataPartSchema.extend({
    part: advancedDataTableData,
});

export type AdvancedDataTableSchema = z.infer<typeof advancedDataTableSchema>;

/** text part *********************************/
export const textPartData = z.object({
    type: z.literal("Text"),
    data: z.string(),
    title: z.string().optional(),
});
export const textPartSchema = dataPartSchema.extend({
    part: textPartData,
});
export type TextPartSchema = z.infer<typeof textPartSchema>;

/** text part object *********************************/
export const textCollectionPartData = z.object({
    type: z.literal("TextCollection"),
    data: z.object({
        text: z.string(),
        author: z.string(),
        authorTitle: z.string(),
        dateWritten: z.string(),
    }),
    conf: confSchema,
    title: z.string().optional(),
});
export const textCollectionPartSchema = dataPartSchema.extend({
    part: textCollectionPartData,
});
export type TextCollectionPartSchema = z.infer<typeof textCollectionPartSchema>;

export const textCollectionPartArraySchema = dataPartSchema.extend({
    part: z.array(textCollectionPartData.optional().nullable()),
});

export type TextCollectionPartArraySchema = z.infer<
    typeof textCollectionPartArraySchema
>;

/** share class selector *********************************/

export const shareClassOptionSchema = z.object({
    id: z.number(),
    isin: z.string(),
    name: z.string(),
    launchDate: z.string(),
    currency: z.string().optional(),
});

export type ShareClassOption = z.infer<typeof shareClassOptionSchema>;

//Array
export const shareClassSelectorData = z.object({
    type: z.literal("ShareClassData"),
    data: z.array(shareClassOptionSchema),
    conf: confSchema,
    partId: z.number().optional(),
});
export type ShareClassSelectorData = z.infer<typeof shareClassSelectorData>;
export const shareClassSelectorSchema = dataPartSchema.extend({
    part: shareClassSelectorData,
});
export type ShareClassSelectorSchema = z.infer<typeof shareClassSelectorSchema>;

//Object
export const shareClassSelectorDataObj = z.object({
    data: z.record(z.any()),
    headers: z.array(z.string()).optional(),
    conf: confSchema,
    partId: z.number().optional(),
});
export type ShareClassSelectorDataObj = z.infer<
    typeof shareClassSelectorDataObj
>;

export const shareClassSelectorObjSchema = dataPartSchema.extend({
    part: shareClassSelectorDataObj,
});
export type ShareClassSelectorObjSchema = z.infer<
    typeof shareClassSelectorObjSchema
>;

/** prices *********************************/
export const pricesData = z.object({
    type: z.literal("Prices"),
    data: z.record(z.any()),
    headers: headersSchema,
    conf: confSchema,
    note: z.string().optional(),
    partId: z.number().optional(),
});
export type PricesData = z.infer<typeof pricesData>;
export const pricesSchema = dataPartSchema.extend({
    part: pricesData,
});
export type PricesSchema = z.infer<typeof pricesSchema>;

/** prices -- grouped *********************************/

export const pricesGroupData = z.array(z.array(z.string()));
export const pricesDataGrouped = z.object({
    type: z.literal("Prices"),
    data: z.record(pricesGroupData),
    headers: headersSchema,
    conf: confSchema,
    note: z.string().optional(),
    partId: z.number().optional(),
});
export type PricesDataGrouped = z.infer<typeof pricesDataGrouped>;
export const pricesGroupedSchema = dataPartSchema.extend({
    part: pricesDataGrouped,
});
export type PricesGroupedSchema = z.infer<typeof pricesGroupedSchema>;

/** record array *********************************/
export const recordArrayData = z.object({
    type: z.literal("RecordArray"),
    data: z.array(z.record(z.string())),
});
export const recordArraySchema = dataPartSchema.extend({
    part: recordArrayData,
});
export type RecordArraySchema = z.infer<typeof recordArraySchema>;

/** performance chart *********************************/
export const performanceChartDataPartSchema = z.object({
    part_id: z.string(),
    calculation_basis: z.string(),
    header: z.string(),
    holidays: z.array(z.string()),
    month_end_data: z.string(),
    period_length: z.string(),
    share_class: z.string(),
    benchmark_label: z.string(),
    initial_share_class: z.string(),
    locale: z.string(),
    weekly_pricing: z.string().optional(),
    daily_close_pricing: z.string().optional(),
    launch_date: z.string(),
});
export type PerformanceChartDataPartSchema = z.infer<
    typeof performanceChartDataPartSchema
>;

export const lineChartSchema = dataPartSchema.extend({
    part: performanceChartDataPartSchema,
});

export type LineChartSchema = z.infer<typeof lineChartSchema>;

export const PERF_CHART_PERIOD_OPTIONS = [
    "1m",
    "3m",
    "1y",
    "3y",
    "5y",
    "10y",
    "sl",
    "sspd",
] as const;
export type PerformanceChartPeriod = (typeof PERF_CHART_PERIOD_OPTIONS)[number];

const gapsSchema = z.array(z.array(z.string()));

export const shareClassPlotSchema = z.object({
    price_type: z.string(),
    calculation_basis: z.string(),
    data: z.array(z.array(z.number())),
    gaps: gapsSchema.optional(),
});

export const indexPlotSchema = z.object({
    name: z.string(),
    id: z.number(),
    is_sector: z.boolean(),
    data: z.array(z.array(z.number())),
    gaps: gapsSchema.optional(),
});

export const shareClassInfoSchema = z.object({
    share_class_name: z.string(),
    share_class_long_name: z.string(),
    share_class_short_name: z.string(),
    launch_date: z.string(),
    significant_performance_date: z.string().optional(),
    valid_periods: z.array(z.array(z.string())),
});

export const performanceChartResponseSchema = z.object({
    price_type_list: z.array(z.string()),
    share_class_info: shareClassInfoSchema,
    share_class_plots: z.array(shareClassPlotSchema),
    index_plots: z.array(indexPlotSchema),
    start_date: z.string(),
    end_date: z.string(),
    earliest_start_date: z.string(),
    latest_start_date: z.string().nullable(),
    as_at_date: z.string(),
    note: z.string().optional(),
    period: z.array(z.string()),
});

export type PerformanceChartResponse = z.infer<
    typeof performanceChartResponseSchema
>;

export const performanceChartErrorResponseSchema = z.object({
    message: z.string(),
    share_class_info: shareClassInfoSchema,
});

export type PerformanceChartErrorResponse = z.infer<
    typeof performanceChartErrorResponseSchema
>;

export type PerformanceChartResponseSchema =
    | PerformanceChartResponse
    | PerformanceChartErrorResponse;

export const performanceChartDataSchema = dataPartSchema.extend({
    part: performanceChartResponseSchema.merge(performanceChartDataPartSchema),
});
export type PerformanceChartDataSchema = z.infer<
    typeof performanceChartDataSchema
>;

/** unions *********************************/
export const dataPartWithTypeSchema = z.discriminatedUnion("type", [
    advancedDataTableData,
    textPartData,
    shareClassSelectorData,
    pricesData,
    recordArrayData,
]);

export const dataElemSchema = z.union([
    advancedDataTableSchema,
    shareClassSelectorSchema,
    textPartSchema,
    pricesSchema,
    recordArraySchema,
]);
export type DataElem = z.infer<typeof dataElemSchema>;

export type DataPartSchemaWithType = z.infer<typeof dataPartWithTypeSchema>;

export type ScriptContentReturn =
    | Omit<AdvancedDataTableData, "type">
    | Omit<ShareClassSelectorData, "type">
    | Omit<PricesData, "type">
    | undefined;

export const availableDatesSchema = z.array(
    z.object({
        value: z.number(),
        availableMonths: z.array(
            z.object({
                value: z.number(),
                availableDays: z.array(z.number()),
            }),
        ),
    }),
);

export type AvailableDates = z.infer<typeof availableDatesSchema>;

export const availableDatesPartSchema = dataPartSchema.extend({
    part: z.object({
        data: availableDatesSchema,
        partId: z.number(),
    }),
});

export type AvailableDatesPartSchema = z.infer<typeof availableDatesPartSchema>;

export const PricesPartArgsSchema = z.object({
    baseUrl: z.string().optional(),
    year: z.number(),
    month: z.number(),
    dates: z.tuple([z.number(), z.number()]),
    partId: z.number(),
    fundId: z.number(),
    audienceId: z.number(),
    version: z.string(),
    flipOrder: z.boolean().optional(),
});
export type PricesPartArgs = z.infer<typeof PricesPartArgsSchema>;

const bannerLinkSchema = z.object({
    id: z.string().optional(),
    text: z.string().optional(),
    newWindow: z.string().optional(),
    rank: z.string().optional(),
    url: z.string(),
});

export const bannerDataSchema = z.object({
    image: z.string(),
    text: z.string().optional(),
    title: z.string().optional(),
    pre: z.string().optional(),
    links: z.array(bannerLinkSchema).optional(),
});

export type BannerData = z.infer<typeof bannerDataSchema>;

export const bannerPartSchema = z.object({
    default: z.object({
        part: z.object({
            data: z.object({
                src: z.string(),
            }),
        }),
    }),
    override: z
        .object({
            part: z.object({
                banner: z.object({
                    part: z.object({
                        data: bannerDataSchema,
                    }),
                }),
            }),
        })
        .optional(),
});

export type BannerPart = z.infer<typeof bannerPartSchema>;

export const linksSchema = z.object({
    id: z.string().optional(),
    text: z.string().optional(),
    newWindow: z.string().optional(),
    rank: z.string().optional(),
    url: z.string(),
});

export const simplePartData = z.object({
    data: z.object({
        title: z.string().optional(),
        text: z.string().optional(),
        links: z.array(linksSchema).optional(),
        image: z.string().optional(),
    }),
    conf: z.any().optional(),
    partId: z.number().optional(),
});

export const simplePartSchema = z.object({
    part: simplePartData,
});
export type SimplePartSchema = z.infer<typeof simplePartSchema>;

/** People *********************************/
export const personSchema = z.object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    role: z.string().optional(),
    bio: z.string().optional(),
    headshot: z.string().optional(),
    citywireRating: z.nullable(z.string()).optional(),
    awardRating: z.nullable(z.string()).optional(),
    academicQualifications: z.array(z.string()).optional(),
    professionalQualifications: z.string().optional(),
    yearsAtFirm: z.string().optional(),
    yearsInIndustry: z.string().optional(),
});

export type Person = z.infer<typeof personSchema>;

export const peoplePartSchema = dataPartSchema.extend({
    part: z.object({
        type: z.literal("RecordArray"),
        data: z.array(personSchema.partial()),
    }),
});

export type PeoplePartSchema = z.infer<typeof peoplePartSchema>;

/** Documents *********************************/

export const documentDataSchema = z.object({
    displayName: z.string(),
    asAtDate: z.string(),
    latestDate: z.string(),
    language: z.string(),
    href: z.string(),
    shareclassIds: z.array(z.string()),
});

export const documentPartDataSchema = dataPartSchema.extend({
    part: z.object({
        data: z.array(documentDataSchema),
    }),
});

export type DocumentPartDataSchema = z.infer<typeof documentPartDataSchema>;

/** Post List & Event metas *********************************/
const postListAndEventFundSchema = z.object({
    id: z.string(),
    name: z.string(),
    tenant: z.string(),
    url: z.string().optional(),
});

/** Post List *********************************/
export const postListItemSchema = z.object({
    id: z.string().or(z.number()),
    type: z.enum([
        "text",
        "text_external",
        "article",
        "document",
        "document_external",
        "video",
        "podcast",
    ]),
    title: z.string(),
    category_name: z.string(),
    category_colour: z.string(),
    date: z.string(),
    formatted_date: z.string(),
    image: z.string(),
    author_name: z.string(),
    author_fund: z.string(),
    authors_to_show: z.array(z.string()),
    post_body: z.string(),
    synopsis: z.string(),
    read_url: z.string(),
    custom_tag_categories: z.record(
        z.string(),
        z.array(z.object({ id: z.string(), name: z.string() })),
    ),
    custom_tags: z.array(
        z.object({
            id: z.string(),
            name: z.string(),
        }),
    ),
    funds: z.array(postListAndEventFundSchema),
    publicationImage: z
        .object({
            src: z.string(),
            alt: z.string(),
        })
        .optional(),
});

export type PostListItem = z.infer<typeof postListItemSchema>;

export const postListPartSchema = dataPartSchema.extend({
    part: z.object({
        data: z.array(postListItemSchema),
        conf: confSchema.optional(),
    }),
});

export type PostListPart = z.infer<typeof postListPartSchema>;

/** Post List Filters *********************************/
export const postListFilterItemSchema = z.object({
    id: z.string().or(z.number()),
    name: z.string(),
});
export type PostListFilterItem = z.infer<typeof postListFilterItemSchema>;

export const postListFilterDataSchema = z.object({
    contributors: z.array(postListFilterItemSchema).optional(),
    funds: z.array(postListFilterItemSchema.or(z.object({}))).optional(),
    categories: z.array(postListFilterItemSchema).optional(),
});
export type PostListFilterData = z.infer<typeof postListFilterDataSchema>;

export const postListFilterSchema = dataPartSchema.extend({
    part: z.object({
        data: postListFilterDataSchema,
        conf: confSchema.optional(),
    }),
});
export type PostListFilterSchema = z.infer<typeof postListFilterSchema>;

/** Event List *********************************/
export const eventListItemSchema = z.object({
    id: z.string().or(z.number()),
    type: z.string(),
    name: z.string(),
    image: z.string(),
    read_url: z.string(),
    date: z.string(),
    start_time: z.string(),
    end_time: z.string(),
    description: z.string(),
    venue: z.object({
        city: z.string(),
        name: z.string(),
    }),
    link: z.string(),
    category: z.string(),
    status: z.enum([
        "upcoming_registration_closed",
        "upcoming_registration_open",
        "in_progress",
        "fully_booked",
        "past",
        "upcoming_registration_not_yet_open",
    ]),
    route: z.string(),
    funds: z.array(postListAndEventFundSchema),
    featured_states: z.array(z.number()),
});

export type EventListItem = z.infer<typeof eventListItemSchema>;

export const eventListPartSchema = dataPartSchema.extend({
    part: z.object({
        data: z.array(eventListItemSchema),
        conf: confSchema.optional(),
    }),
});

export type EventListPart = z.infer<typeof eventListPartSchema>;

/** Form *********************************/

export const formDataSchema = z.object({
    partVersionId: z.string(),
    fundName: z.string(),
    event_id: z.string().optional(),
    fields: z.array(
        z.object({
            type: z.string(),
            label: z.string(),
            name: z.string(),
            required: z.string(),
        }),
    ),
});

export type FormData = z.infer<typeof formDataSchema>;

export const formPartSchema = dataPartSchema.extend({
    part: z.object({
        data: formDataSchema,
        conf: confSchema.optional(),
    }),
});

export type FormPart = z.infer<typeof formPartSchema>;

/** Fund Property *********************************/

export const fundPropertyDataSchema = z.object({
    id: z.string(),
    descriptor: z.string(),
    long_name: z.string(),
    currency: z.string(),
    launch_date: z.string(),
    umbrella: z.string(),
    page_route: z.string(),
    page_url: z.string(),
    image: z.string(),
    intro: z.string(),
    geographic_assetclass: z.string(),
    fund_managers: z.string(),
});

export type FundPropertyData = z.infer<typeof fundPropertyDataSchema>;

export const fundPropertyPartSchema = dataPartSchema.extend({
    part: z.object({
        data: z.array(fundPropertyDataSchema),
    }),
});

export type FundPropertyPartSchema = z.infer<typeof fundPropertyPartSchema>;

/** Platform availability *********************************/

export const platformAvailabilityObj = z.object({
    formal_name: z.string(),
    label: z.string(),
    logo: z.string(),
    url: z.string(),
});

export type PlatformAvailabilityObj = z.infer<typeof platformAvailabilityObj>;

/** Income *********************************/

export const incomePartChartDataSchema = z.object({
    data: z.array(z.array(z.string())),
    fyEndDate: z.string(),
    finalDividendDate: z.string(),
    labels: z.object({
        final: z.string(),
        interim: z.string(),
    }),
    chartTitle: z.string().optional(),
    yearSummaryTitle: z.string().optional(),
    yearSummaryHeaders: z.tuple([z.string(), z.string()]).optional(),
    yearBreakdownTitle: z.string().optional(),
    yearBreakdownHeaders: z.tuple([
        z.string(),
        z.string(),
        z.string(),
        z.string(),
    ]),
    currencySymbol: z.string(),
    chartNote: z.string().default(""),
});

export type IncomePartChartData = z.infer<typeof incomePartChartDataSchema>;

export const incomePartChartSchema = dataPartSchema.extend({
    part: z.object({
        type: z.literal("DataTable"),
        data: incomePartChartDataSchema,
    }),
});

export type IncomePartChartSchema = z.infer<typeof incomePartChartSchema>;
