import { z } from "zod";

import {
    dataPartSchema,
    documentPartDataSchema,
    formPartSchema,
    textCollectionPartSchema,
} from "@hsl/fund-page/schemas";

import DocumentDownloadCTA from "../../../components/DocumentDownloadCTA";
import SignUpForm from "../../../components/SignUpForm";
import TextDataPart from "../../../components/TextDataPart";

export const aboutSectionSchema = dataPartSchema.extend({
    part: z.object({
        factSheet: documentPartDataSchema,
        opportunityText: textCollectionPartSchema,
        signUpForm: formPartSchema,
    }),
});

const AboutSection = ({ part }: z.infer<typeof aboutSectionSchema>) => {
    const { factSheet, opportunityText, signUpForm } = part;

    return (
        <>
            <div className="flex items-center justify-between pb-4 sm:items-end sm:pb-6">
                <DocumentDownloadCTA
                    text={factSheet.title}
                    href={factSheet.part.data[0]?.href}
                    className="mb-0"
                />
                <SignUpForm
                    fundName={signUpForm.part.data.fundName}
                    partVersionId={signUpForm.part.data.partVersionId}
                    label="Sign up"
                    buttonClassNames="sm:mt-4"
                />
            </div>
            <TextDataPart
                data={opportunityText.part.data.text}
                className="border-t pt-4"
            />
        </>
    );
};

export default AboutSection;
