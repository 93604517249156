import { Posts } from "../components";
import { Filters, type FiltersProps } from "../components/Filters/Filters";
import AboutUs, { type AboutUsProps } from "./AboutUs";

type Props = FiltersProps &
    AboutUsProps & { headshots?: Record<string, string> };

const ManagerDiaryDeskptop = ({
    author,
    diaryOverview,
    glossary,
    importantInformation,
    formData,
    headshots,
}: Props) => {
    return (
        <>
            <div className="mb-2 hidden w-full max-w-full gap-6 lg:flex">
                <div className="flex flex-1 flex-col gap-y-6  print:hidden">
                    <Filters formData={formData} />
                    <AboutUs
                        author={author}
                        diaryOverview={diaryOverview}
                        glossary={glossary}
                        importantInformation={importantInformation}
                    />
                </div>
                <div className="intheloupe flex-[2]">
                    <Posts headshots={headshots} />
                </div>
            </div>
        </>
    );
};

export default ManagerDiaryDeskptop;
