import NoDataMessage from "../../../components/NoDataMessage";

const EventsNoDataMessage = ({ type }: { type: "upcoming" | "filter" }) => {
    return (
        <NoDataMessage>
            {type === "upcoming" ? (
                <>
                    There are currently no scheduled upcoming events. Please
                    scroll down to see our past events.
                </>
            ) : (
                <>
                    There are currently no events available for your filter
                    selection.
                </>
            )}
        </NoDataMessage>
    );
};

export default EventsNoDataMessage;
