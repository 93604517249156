import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";

import cx from "@hsl/core/utils/cx";

import CustomScroller from "../CustomScroller";

export interface Props<T> {
    className?: string;
    value?: T;
    options: T[];
    noChevron?: boolean;
    buttonClassName?: string;
    optionClassName?: string;
    optionContainerClassName?: string;
    chevronClassName?: string;
    optionScrollerClassName?: string;
    children?: React.ReactNode;
    placeholder?: string;
    disabled?: boolean;
    onChange?: (value: T) => void;
    onOptionClick?: (args?: any) => void;
    toLabel?: (value: T) => string;
}

const Dropdown = <T,>({
    className,
    value,
    options,
    noChevron,
    buttonClassName,
    optionClassName,
    optionContainerClassName,
    optionScrollerClassName,
    chevronClassName,
    children,
    placeholder,
    disabled,
    onChange,
    toLabel = (x) => x as string,
    onOptionClick,
}: Props<T>) => {
    const handleOptionClick = (args?: any) => {
        onOptionClick && onOptionClick(args);
    };

    return (
        <div className={cx("text-sm sm:text-base", className)}>
            <Listbox value={value} disabled={disabled} onChange={onChange}>
                {({ open }) => (
                    <div className="relative">
                        <Listbox.Button
                            className={cx(
                                "relative flex w-full cursor-pointer appearance-none items-center justify-center bg-transparent",
                                buttonClassName,
                            )}
                        >
                            <span
                                className={cx(
                                    "block flex-1 truncate",
                                    disabled && "opacity-50",
                                    noChevron ? "text-center" : "text-left",
                                )}
                            >
                                {value
                                    ? toLabel(value)
                                    : placeholder ?? "Select"}
                            </span>
                            {!noChevron && (
                                <ChevronDownIcon
                                    className={cx(
                                        `pointer-events-none ml-1 inline-block h-4 w-4 scale-125 transition`,
                                        chevronClassName,
                                        open && "rotate-180",
                                    )}
                                />
                            )}
                            {children && (
                                <span className="ml-2 border-l border-gray-300 pl-2">
                                    {children}
                                </span>
                            )}
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div>
                                <CustomScroller
                                    className={cx(
                                        "absolute z-50 max-h-60 w-full shadow-md",
                                        optionScrollerClassName,
                                    )}
                                >
                                    <Listbox.Options
                                        className={cx(
                                            "w-full rounded-md bg-white py-1 text-base opacity-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                                            optionContainerClassName,
                                        )}
                                    >
                                        {options.map((option, idx) => (
                                            <Listbox.Option
                                                key={`${toLabel(
                                                    option,
                                                )}-${idx}`}
                                                className={({
                                                    active,
                                                    selected,
                                                }) =>
                                                    cx(
                                                        "relative cursor-default select-none px-4 py-1.5",
                                                        "text-sm sm:text-base",
                                                        "cursor-pointer transition hover:bg-[#F6F6F6]",
                                                        optionClassName,
                                                        active ||
                                                            (selected &&
                                                                "bg-[#F6F6F6] hover:bg-white"),
                                                    )
                                                }
                                                value={option}
                                                onClick={() =>
                                                    handleOptionClick(idx)
                                                }
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <span
                                                            className={`block ${
                                                                selected
                                                                    ? "font-semibold"
                                                                    : "font-normal"
                                                            }`}
                                                        >
                                                            {toLabel(option)}
                                                        </span>
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </CustomScroller>
                            </div>
                        </Transition>
                    </div>
                )}
            </Listbox>
        </div>
    );
};

export default Dropdown;
