import { GenericErrorBoundary } from "@hsl/lgim-explorer/src/components/GenericErrorBoundary/GenericErrorBoundary";

import GreyCurveContainer from "../../components/GreyCurveContainer";
import { NewsAndViewsCardListSkeleton } from "../../components/NewsAndViewsCard";
import useInsights from "../../hooks/useInsights";
import useFundPageStore from "../../store/store";
import { Events, NewsAndViews } from "./components";

const HomeNewsAndViews = () => {
    const loading = useFundPageStore((store) => store.loading);
    const audienceId = useFundPageStore(
        (store) => store.config.springAudienceId,
    );
    const { insights, isLoading } = useInsights({
        queryKey: "homeNewsAndViews",
        pageSize: 3,
        filters: {
            audiences: [audienceId],
            categories: [37, 38, 39, 40, 41, 112, 156, 230],
        },
    });
    if (loading || isLoading) {
        return <NewsAndViewsCardListSkeleton className="-mt-12" />;
    }

    return (
        <GenericErrorBoundary>
            <GreyCurveContainer withCurve={false}>
                <Events />
                <NewsAndViews data={insights} />
            </GreyCurveContainer>
        </GenericErrorBoundary>
    );
};

export default HomeNewsAndViews;
