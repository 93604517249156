import { useMemo, useState, type CSSProperties } from "react";

import MonthPicker from "@hsl/components/MonthPicker";
import {
    type DocumentDateFrequency,
    type DocumentSet,
} from "@hsl/core/documents/types";
import useAvailableDates from "@hsl/documents/hooks/useAvailableDates";

import Dropdown, { SPRING_DROPDOWN_PROPS } from "../../../components/Dropdown";
import { DocumentListItem } from "./DocumentListItem";

interface Props {
    data: DocumentSet[];
    baseURL?: string;
    dateFrequency?: DocumentDateFrequency;
    style?: CSSProperties;
}

export const DocumentListItemWithDatePicker = ({
    data,
    baseURL,
    dateFrequency,
    style,
}: Props) => {
    const [activeDoc, setActiveDoc] = useState(data[0]);
    const availableDates = useAvailableDates(data);

    if (!activeDoc || !availableDates) {
        return null;
    }

    const [activeYear, activeMonth] = activeDoc.date.split("-");

    return (
        <DocumentListItem
            key={activeDoc.id}
            baseURL={baseURL}
            style={style}
            {...activeDoc}
        >
            {dateFrequency === "monthly" || dateFrequency === "quarterly" ? (
                <MonthPicker
                    label=""
                    className="z-50"
                    panelClassName="right-0"
                    format="MMM"
                    buttonClassName={SPRING_DROPDOWN_PROPS.buttonClassName}
                    availableDates={availableDates}
                    selectedMonth={Number(activeMonth)}
                    selectedYear={Number(activeYear)}
                    onChange={(changeVal) => {
                        const newActiveDoc = data.find(({ date }) => {
                            const [year, month] = date.split("-");
                            return (
                                Number(year) === changeVal.year &&
                                Number(month) === changeVal.month
                            );
                        });
                        setActiveDoc(newActiveDoc);
                    }}
                />
            ) : dateFrequency === "yearly" ? (
                <Dropdown<number>
                    value={Number(activeYear)}
                    options={availableDates.map((year) => year.value)}
                    onChange={(activeYear) => {
                        const newActiveDoc = data.find(({ date }) => {
                            const [year] = date.split("-");
                            return parseInt(year!) === activeYear;
                        });
                        setActiveDoc(newActiveDoc);
                    }}
                />
            ) : null}
        </DocumentListItem>
    );
};
