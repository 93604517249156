import resolveConfig from "tailwindcss/resolveConfig";
import { z } from "zod";

import twConfig from "@hsl/lgim/tailwind.config";

const breakpointSchema = z.object({
    sm: z.number(),
    md: z.number(),
    lg: z.number(),
    xl: z.number(),
    "2xl": z.number(),
});

const config = resolveConfig(twConfig);

const theme = config.theme as unknown as typeof config.theme;
const breakpoints = theme.container.screens;
const breakpointsWithoutPx = Object.fromEntries(
    Object.keys(breakpoints).map((key) => [
        key,
        parseInt(breakpoints[key as keyof typeof breakpoints]),
    ]),
);

const parsedBreakpoints = breakpointSchema.parse(breakpointsWithoutPx);

export default parsedBreakpoints;
