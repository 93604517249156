import { useMemo } from "react";

import { partConfigSchema, type PartConfig } from "@hsl/core/fund-page/schemas";

function usePortalConfig(portalId: string): PartConfig {
    const config = useMemo(() => {
        const portalElem = document.querySelector(portalId) as HTMLElement;

        const {
            partId,
            audienceId,
            jurisdictionId,
            languageId,
            routeId,
            fundId,
            version,
        } = portalElem.dataset;
        return {
            portalElem: portalElem,
            partId: partId as string,
            audienceId: audienceId as string,
            jurisdictionId: jurisdictionId as string,
            languageId: languageId as string,
            routeId: routeId as string,
            fundId: fundId as string,
            version: version as string,
        };
    }, []);

    return partConfigSchema.parse(config);
}

export default usePortalConfig;
