import * as React from "react";

const SvgNewsEsg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={41}
        height={41}
        fill="none"
        {...props}
    >
        <g fill="#fff" clipPath="url(#news-esg_svg__a)">
            <path d="M31.738 13.389c-1.357-3.157-3.822-5.613-7.012-6.889l-.474 1.18c2.843 1.147 5.085 3.348 6.317 6.219 1.2 2.87 1.263 6.027.126 8.896a11.562 11.562 0 0 1-6.159 6.379c-2.842 1.212-5.97 1.276-8.813.127l-.474 1.18c1.17.477 2.401.765 3.632.86v1.244h-6.315v1.913h15.162v-1.913h-6.317V31.31c1.232-.127 2.433-.478 3.632-.957 3.159-1.371 5.56-3.859 6.823-7.08 1.263-3.222 1.231-6.697-.126-9.887" />
            <path d="M30.317 18.491c0 5.724-4.596 10.364-10.266 10.364S9.786 24.215 9.786 18.491c0-5.723 4.596-10.364 10.266-10.364S30.32 12.767 30.32 18.49" />
        </g>
        <defs>
            <clipPath id="news-esg_svg__a">
                <path fill="#fff" d="M9.786 6.5h23v28h-23z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgNewsEsg;
