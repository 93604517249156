import { z } from "zod";

import cx from "@hsl/core/utils/cx";
import { dataPartSchema } from "@hsl/fund-page/schemas";

const shareclassDetailSchema = z.object({
    label: z.string(),
    value: z.string(),
});

type ShareClassDetailSchema = z.infer<typeof shareclassDetailSchema>;

const shareclassMetaDataSchema = z.object({
    nameOne: z.string(),
    nameTwo: z.string(),
    minInvestment: shareclassDetailSchema.optional(),
    initialCharge: shareclassDetailSchema,
    annualManagementFee: shareclassDetailSchema,
    performanceFee: shareclassDetailSchema,
    ocf: shareclassDetailSchema.optional(),
    note: z.string().optional(),
    isDefault: z.enum(["", "True", "False"]).optional(),
});

export const shareclassMetaTableSchema = dataPartSchema.extend({
    part: z.object({
        data: z.record(z.string(), shareclassMetaDataSchema),
    }),
});

export const ShareclassMetaTable = ({
    part,
    activeGroup,
}: z.infer<typeof shareclassMetaTableSchema> & { activeGroup: string }) => {
    const activeData = Object.values(part.data).find(
        (x) => x.nameOne === activeGroup,
    )!;
    const {
        minInvestment,
        initialCharge,
        annualManagementFee,
        performanceFee,
        ocf,
    } = activeData;
    const metaFieldsToDisplay = [
        minInvestment,
        initialCharge,
        annualManagementFee,
        performanceFee,
        ocf,
    ].filter((x) => !!x && !!x.value) as ShareClassDetailSchema[];

    return (
        <div className="border-offWhite flex flex-wrap gap-y-2 rounded-lg border p-2 sm:w-min sm:flex-nowrap">
            {metaFieldsToDisplay.map(({ label, value }, i) => {
                return (
                    <div
                        key={label}
                        className={cx("flex w-1/2 justify-between sm:w-max")}
                    >
                        <div>
                            <div className="text-xs font-semibold">{label}</div>
                            <div className="text-purple text-lg font-medium">
                                {value}
                            </div>
                        </div>
                        <Separator
                            className={cx(
                                "hidden",
                                i % 2 === 0 && "max-sm:block",
                                i !== metaFieldsToDisplay.length - 1 &&
                                    "sm:block",
                            )}
                        />
                    </div>
                );
            })}
        </div>
    );
};

const Separator = ({ className }: { className?: string }) => (
    <div className={cx("bg-offWhite mx-4 h-full min-w-[2px]", className)} />
);
