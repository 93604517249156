import { z } from "zod";

import { dataPartSchema, personSchema } from "@hsl/fund-page/schemas";

import FundPageContent from "../../../components/FundPageContent";
import PersonCard, { PersonCardSkeleton } from "../../../components/PersonCard";
import usePageSection from "../../../hooks/usePageSection";

const meetTheTeamSchema = dataPartSchema
    .extend({
        data: z.array(personSchema.partial()),
    })
    .optional();

interface Props {
    dataKey: string;
}

const MeetTheTeam = ({ dataKey }: Props) => {
    const sectionData = usePageSection(dataKey, meetTheTeamSchema);
    if (!sectionData) {
        return null;
    }
    const { data, title } = sectionData;
    return (
        <FundPageContent.Section dataKey={dataKey} className="flex-col">
            <FundPageContent.Header h3 className="!mb-0 text-[30px] font-light">
                {title}
            </FundPageContent.Header>
            <div className="flex flex-col gap-y-4">
                {data.map((person) => (
                    <PersonCard {...person} className="border-primary" />
                ))}
            </div>
        </FundPageContent.Section>
    );
};

const MeetTheTeamWithErrorBoundary = (props: Props) => {
    return (
        <FundPageContent.Wrapper dataKey={props.dataKey}>
            <MeetTheTeam {...props} />
        </FundPageContent.Wrapper>
    );
};

export default MeetTheTeamWithErrorBoundary;

export const MeetTheTeamSkeleton = () => (
    <div>
        <FundPageContent.HeaderSkeleton />
        <div className="flex flex-col gap-y-2">
            <PersonCardSkeleton />
            <PersonCardSkeleton />
        </div>
    </div>
);
