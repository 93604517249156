import { ReactNode } from "react";

import cx from "@hsl/core/utils/cx";

import FundPageContent from "../../../components/FundPageContent";

export const DisclaimerText = ({
    className,
    content,
}: {
    className?: string;
    content?: ReactNode;
}) => {
    return (
        <FundPageContent.P className={cx("text-secondary", className)}>
            {content ??
                `For Investment Professionals Only. These views represent the
            opinions of the named Fund Manager(s) as at the time of writing and
            do not constitute investment advice. Please also see 'Important
            Information' to the left of this entry.`}
        </FundPageContent.P>
    );
};

const Disclaimer = ({ content }: { content?: ReactNode }) => {
    return (
        <FundPageContent.Box className="mb-4">
            <DisclaimerText content={content} />
        </FundPageContent.Box>
    );
};

export default Disclaimer;
