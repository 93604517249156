import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { useScrollIntoView } from "@hsl/core/fund-page/hooks";

import { useSectionIntersectionStore } from "./useFundPageSectionObserver";

export type ActiveSectionContextType = {
    activeSection: string | null;
    setActiveSection: (str: string) => void;
    handleSectionClick: (
        sectionKey: string | null,
        disableScroll?: boolean,
        params?: [string, string][],
    ) => void;
    setObserverDisabled: (bool: boolean) => void;
};

export interface Props {
    children: React.ReactNode;
    scrollIntoView?: boolean;
    delayScroll?: number;
    scrollOffset?: number;
    scrollIntoViewOnLoad?: boolean;
}

export const ActiveSectionContext = createContext<
    ActiveSectionContextType | undefined
>(undefined);

export const ActiveSectionContextProvider = ({
    children,
    scrollIntoView = true,
    delayScroll = 0,
    scrollOffset = 0,
    scrollIntoViewOnLoad = true,
}: Props) => {
    const [observerDisabled, setObserverDisabled] = useState(false);
    const [forceDisableObserver, setForceDisableObserver] = useState(true);
    const [activeSection, setActiveSection] = useState<string | null>(null);
    const [activeURLParams, setActiveURLParams] = useState<
        [string, string][] | null
    >(null);
    const [scrollToOnLoad, setScrollToOnLoad] = useState<string>();
    const intersections = useSectionIntersectionStore(
        (state) => state.intersections,
    );
    const handleScrollIntoView = useScrollIntoView();

    // Force active section on click to avoid forcing the intersection state to be ordered
    // Scroll section into view

    const contextValue = useMemo(() => {
        const handleSectionClick = (
            sectionKey: string | null,
            disableScroll?: boolean,
            params?: [string, string][],
        ) => {
            const searchParams = new URLSearchParams(params);
            setObserverDisabled(true);
            setActiveSection(sectionKey);
            setActiveURLParams(params ?? null);
            if (scrollIntoView && !disableScroll) {
                handleScrollIntoView(
                    `#${sectionKey}`,
                    scrollOffset,
                    delayScroll,
                );
            }

            setTimeout(() => {
                setObserverDisabled(false);
            }, 500);
        };

        return {
            activeSection,
            handleSectionClick,
            setActiveSection,
            setObserverDisabled: setForceDisableObserver,
        } satisfies ActiveSectionContextType;
    }, [activeSection, delayScroll, scrollIntoView, scrollOffset]);

    useEffect(() => {
        const activePageSection = window.location.hash;
        setActiveSection(activePageSection.slice(1) || null);
        if (activePageSection !== "#intheLoupe") {
            setForceDisableObserver(false);
        }
        if (activePageSection) {
            setScrollToOnLoad(activePageSection.slice(1));
        }
    }, []);

    useEffect(() => {
        if (
            scrollIntoViewOnLoad &&
            scrollToOnLoad &&
            scrollToOnLoad in intersections
        ) {
            // in the Loupe is at the top, so skip
            if (scrollToOnLoad !== "intheLoupe")
                handleScrollIntoView(`#${scrollToOnLoad}`, scrollOffset, 0);
            setScrollToOnLoad(undefined);
        }
    }, [intersections, scrollIntoViewOnLoad, scrollOffset, scrollToOnLoad]);

    useEffect(() => {
        const currentSearch = new URLSearchParams(window.location.search);
        const filteredParams = activeURLParams?.filter(
            ([key, value]) => currentSearch.get(key) !== value,
        );
        console.log(activeSection);
        const hash = activeSection
            ? `#${activeSection === "overview" ? "" : activeSection}`
            : "";
        const newSearch = filteredParams
            ? filteredParams.reduce((params, [key, value]) => {
                  params.set(key, value);
                  return params;
              }, currentSearch)
            : currentSearch;
        const url = `${newSearch.size > 0 ? "?" : ""}${newSearch}${hash}`;
        history.replaceState({}, "", url);
    }, [activeSection, activeURLParams]);

    useEffect(() => {
        if (!observerDisabled && !forceDisableObserver) {
            setActiveSection(
                Object.entries(intersections).reduce<string | null>(
                    (prev, [key, value]) =>
                        value > (intersections[prev!] ?? 0) ? key : prev,
                    null,
                ),
            );
        }
    }, [forceDisableObserver, intersections, observerDisabled]);

    return (
        <ActiveSectionContext.Provider value={contextValue}>
            {children}
        </ActiveSectionContext.Provider>
    );
};

export function useActiveSectionContext() {
    const documentContext = useContext(ActiveSectionContext);
    if (!documentContext) throw new Error("No ActiveSectionContextProvider");
    return documentContext;
}
