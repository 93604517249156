import { useMemo } from "react";

import { FilterDropdown } from "../../../components/Dropdown";
import {
    groupStrByTenantName,
    sortPostListFilterItem,
    sortPostListStr,
} from "../../../lib/groupFilterItemByTenantName";
import { useEventsContext } from "../context";

export const Filters = () => {
    const {
        allEvents,
        activeCategory,
        activeFund,
        setActiveFund,
        setActiveCategory,
    } = useEventsContext();

    const FUNDS = useMemo(() => {
        return sortPostListStr(
            Object.values(
                groupStrByTenantName(
                    Array.from(
                        new Set(
                            allEvents.flatMap((event) =>
                                event.funds.map((x) => x.name),
                            ),
                        ),
                    ),
                ),
            ),
        );
    }, [allEvents]);

    const CATEGORIES = useMemo(() => {
        return Array.from(new Set(allEvents.map((event) => event.type)));
    }, [allEvents]);

    return (
        <div className="flex flex-col items-center gap-x-4 gap-y-2 pt-2 sm:flex-row sm:pt-0">
            <span className="hidden w-full sm:inline">Filter by</span>
            <FilterDropdown<string>
                placeholder="Fund"
                value={activeFund}
                options={FUNDS}
                className="min-w-[300px]"
                optionContainerClassName="min-w-max"
                onChange={setActiveFund}
                onCancel={() => setActiveFund(undefined)}
            />
            <FilterDropdown<string>
                placeholder="Type"
                value={activeCategory}
                options={CATEGORIES}
                optionContainerClassName="min-w-max"
                onChange={setActiveCategory}
                onCancel={() => setActiveCategory(undefined)}
            />
        </div>
    );
};
