import { GenericErrorBoundary } from "@hsl/lgim-explorer/src/components/GenericErrorBoundary/GenericErrorBoundary";

import useFundPageStore from "../../store/store";
import {
    MeetTheTeam,
    MeetTheTeamSkeleton,
    ReportLibrary,
    ReportLibrarySkeleton,
} from "./components";

const ManagerESGView = () => {
    const loading = useFundPageStore((store) => store.loading);

    if (loading) {
        return (
            <div className="flex flex-col gap-y-6">
                <ReportLibrarySkeleton />
                <MeetTheTeamSkeleton />
            </div>
        );
    }

    return (
        <GenericErrorBoundary>
            <ReportLibrary dataKey="reportLibrary" />
            <MeetTheTeam dataKey="meetTheTeam" />
        </GenericErrorBoundary>
    );
};

export default ManagerESGView;
