import { useClientOnly } from "@hsl/components/ClientOnly";

import useFundPageStore from "../store/store";

/**
 * Takes fund name and uses the first letters to determine access.
 * Stores access for funds in local storage for future use.
 */

type IntheLoupeMeta = {
    verifiedFunds: number[];
};

function useIntheLoupe(): boolean {
    const { fundName, fundId } = useFundPageStore().config;
    const isClient = useClientOnly();
    if (!fundName || !fundId || !isClient) {
        return false;
    }
    const { verifiedFunds } = getFromLocalStorage();
    if (verifiedFunds.includes(fundId)) {
        return true;
    }
    const accessCode = new URLSearchParams(window.location.search).get(
        "loupecode",
    );
    if (!accessCode) {
        console.warn("No access code for in the Loupe content");
        return false;
    }
    //check if code matches first letters of fund name
    if (
        accessCode &&
        fundName
            .split(" ")
            .map((w) => w[0]?.toLowerCase())
            .join("") === accessCode
    ) {
        addFundIdToAccess(fundId);
        return true;
    }
    return false;
}

export default useIntheLoupe;

function getFromLocalStorage() {
    const storageStr = window.localStorage.getItem("intheLoupe");
    if (!storageStr) {
        window.localStorage.setItem(
            "intheLoupe",
            JSON.stringify({
                verifiedFunds: [],
            } as IntheLoupeMeta),
        );
        return { verifiedFunds: [] };
    }
    return JSON.parse(storageStr) as IntheLoupeMeta;
}

function addFundIdToAccess(fundId: number) {
    const storageStr = window.localStorage.getItem("intheLoupe");
    if (!storageStr) throw Error("intheLoupe not initialsed");
    const { verifiedFunds } = JSON.parse(storageStr) as IntheLoupeMeta;
    window.localStorage.setItem(
        "intheLoupe",
        JSON.stringify({
            verifiedFunds: [...verifiedFunds, fundId],
        } as IntheLoupeMeta),
    );
}
