import * as React from "react";

const SvgPrinter = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={22}
        fill="none"
        {...props}
    >
        <g fill="currentColor">
            <rect width={7.5} height={2.917} x={0.489} y={16.041} rx={1.458} />
            <rect width={7.5} height={2.917} x={16.989} y={16.041} rx={1.458} />
            <rect width={15} height={2.917} x={4.989} rx={1.458} />
            <rect width={15} height={2.917} x={4.989} y={18.959} rx={1.458} />
            <rect width={15} height={2.917} x={4.989} y={11.666} rx={1.458} />
            <rect width={24} height={2.917} x={0.489} y={5.834} rx={1.458} />
            <path
                fillRule="evenodd"
                d="M1.99 5.834a1.5 1.5 0 0 1 1.5 1.5v10.125a1.5 1.5 0 0 1-3 0V7.334a1.5 1.5 0 0 1 1.5-1.5ZM6.49 0a1.5 1.5 0 0 1 1.5 1.5v5.75a1.5 1.5 0 0 1-3 0V1.5A1.5 1.5 0 0 1 6.49 0ZM6.49 11.666a1.5 1.5 0 0 1 1.5 1.5v7.208a1.5 1.5 0 1 1-3 0v-7.208a1.5 1.5 0 0 1 1.5-1.5ZM18.49 11.666a1.5 1.5 0 0 1 1.5 1.5v7.208a1.5 1.5 0 1 1-3 0v-7.208a1.5 1.5 0 0 1 1.5-1.5ZM18.49 0a1.5 1.5 0 0 1 1.5 1.5v5.75a1.5 1.5 0 0 1-3 0V1.5a1.5 1.5 0 0 1 1.5-1.5ZM22.99 5.834a1.5 1.5 0 0 1 1.5 1.5v10.125a1.5 1.5 0 0 1-3 0V7.334a1.5 1.5 0 0 1 1.5-1.5Z"
                clipRule="evenodd"
            />
        </g>
    </svg>
);
export default SvgPrinter;
