import Skeleton from "../../../components/Skeletons";

const LiteratureSkeleton = () => (
    <>
        <div className="flex w-full gap-x-6">
            <Skeleton width={"392px"} height={"233px"} />
            <Skeleton className="flex-1" height={"650px"} />
        </div>
    </>
);

export default LiteratureSkeleton;
