import cx from "@hsl/core/utils/cx";

export interface TextSkeletonProps {
    width?: number | string;
    height?: number | string;
    className?: string;
}

export const TextSkeleton = ({
    width,
    height,
    className,
}: TextSkeletonProps) => (
    <span
        suppressHydrationWarning
        className={cx(
            "my-0.5 block h-4 max-w-full animate-pulse rounded opacity-70",
            className,
        )}
        style={{
            width: typeof width === "number" ? `${width}ch` : width,
            height: typeof height === "number" ? `${height}ch` : height,
        }}
    />
);
