import { GenericErrorBoundary } from "@hsl/lgim-explorer/src/components/GenericErrorBoundary/GenericErrorBoundary";

import Button, { ButtonWithArrow } from "../../components/Button";
import { ListContainer as CardListContainer } from "../../components/Card/Card";
import { NewsAndViewsCardListSkeleton } from "../../components/NewsAndViewsCard";
import useFundPageStore from "../../store/store";
import { EventForm, SingleEventReadNewsAndViews } from "./components";

const SingleEventRead = () => {
    const loading = useFundPageStore((store) => store.loading);

    if (loading) {
        return <NewsAndViewsCardListSkeleton className="mt-12" />;
    }
    return (
        <GenericErrorBoundary>
            <EventForm />
            <SingleEventReadNewsAndViews />
        </GenericErrorBoundary>
    );
};

export default SingleEventRead;
