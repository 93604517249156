import React, { Fragment, ReactNode } from "react";

import { ErrorBoundary } from "@hsl/components/GenericErrorBoundary";
import cx from "@hsl/core/utils/cx";
import { isValidEmail } from "@hsl/core/utils/isValidEmail";
import { isValidTelephoneNumber } from "@hsl/core/utils/isValidTelephoneNumber";
import { isValidURL } from "@hsl/core/utils/isValidURL";
import { useFundPageSectionObserver } from "@hsl/fund-page/hooks/useFundPageSectionObserver";
import { type ConfSchema } from "@hsl/fund-page/schemas";

import useFundPageStore from "../../store/store";
import Skeleton from "../Skeletons";
import TextDataPart from "../TextDataPart";

type Props = {
    className?: string;
} & React.PropsWithChildren;

const Header = ({
    children,
    className,
    h5,
    h4 = true,
    h3,
    h2,
}: { h5?: boolean; h4?: boolean; h3?: boolean; h2?: boolean } & Props) => {
    function parseStr(str: string) {
        // split on newline and add <br /> between each line
        // \n is not a real newline in serp, it's just a string
        // so use \\n
        //
        // also, if the string ends with "(%)", make the "(%)" less bold
        return str.split("\\n").map((s, i) => (
            <Fragment key={i}>
                {s.endsWith("(%)") ? (
                    <>
                        {s.slice(0, -3)}
                        <span className="font-light">(%)</span>
                    </>
                ) : (
                    s
                )}
                {i < str.split("\\n").length - 1 && <br />}
            </Fragment>
        ));
    }

    const Content =
        typeof children === "string" ? parseStr(children) : children;

    if (h2) {
        return <h2 className={cx(className)}>{Content}</h2>;
    } else if (h3) {
        return <h3 className={cx(className)}>{Content}</h3>;
    } else if (h5) {
        return <h5 className={cx(className)}>{Content}</h5>;
    } else if (h4) {
        return <h4 className={cx(className)}>{Content}</h4>;
    }
};

export const SectionHeaderSkeleton = ({
    className,
}: {
    className?: string;
}) => (
    <Skeleton
        width={"100%"}
        height="111px"
        className={cx("mb-4 mt-6 sm:mb-6 sm:mt-10", className)}
    />
);

export const HeaderSkeleton = ({
    className,
    width,
    height,
}: {
    className?: string;
    width?: string | number;
    height?: string | number;
}) => (
    <Skeleton
        width={width ?? "200px"}
        height={height ?? "36px"}
        className={cx("mb-4 sm:mb-6", className)}
    />
);

type KeyValueListProps = {
    data: React.ReactNode[][];
    conf?: ConfSchema[];
    footnote?: string;
    className?: string;
    itemClassName?: string;
    labelClassName?: string;
    valueClassName?: string;
    rightAlignValue?: boolean;
    urlReplacementString?: string;
    emailReplacementString?: string | (string | null)[];
    numbered?: boolean;
    withSeparator?: boolean;
    withSumTotal?: boolean;
};

const KeyValueList = ({
    data,
    conf,
    footnote,
    className,
    itemClassName,
    labelClassName,
    valueClassName,
    rightAlignValue,
    urlReplacementString,
    emailReplacementString,
    numbered,
    withSeparator,
    withSumTotal,
}: KeyValueListProps) => {
    return (
        <div className={cx("text-sm sm:text-base", className)}>
            <table className="w-full">
                <tbody>
                    {data?.map(([key, value], i) => {
                        if (!value) {
                            return null;
                        }
                        const rowConfClassName = conf && conf[i]?.className;

                        const isURL = isValidURL(value);
                        const isEmail = isValidEmail(value);
                        const isTel = isValidTelephoneNumber(value);
                        let displayVal: ReactNode;
                        if (isURL && urlReplacementString) {
                            displayVal = (
                                <a
                                    href={
                                        value.startsWith("http")
                                            ? value
                                            : `https://${value}`
                                    }
                                    target="_blank"
                                    className="underline"
                                >
                                    {urlReplacementString}
                                </a>
                            );
                        } else if (isEmail && emailReplacementString) {
                            displayVal = (
                                <a
                                    href={`mailto:${value}`}
                                    target="_blank"
                                    className="underline"
                                >
                                    {Array.isArray(emailReplacementString)
                                        ? emailReplacementString[i]
                                        : emailReplacementString}
                                </a>
                            );
                        } else if (isTel) {
                            displayVal = (
                                <a href={`tel:${value}`} className="underline">
                                    {value}
                                </a>
                            );
                        } else {
                            displayVal = value;
                        }

                        return (
                            <tr
                                key={`${key}-${i}`}
                                className={cx(
                                    withSeparator && "border-platinum border-b",
                                    itemClassName,
                                )}
                            >
                                <td
                                    className={cx(
                                        "py-0.5 pl-0 pr-3 align-top",
                                        rowConfClassName,
                                        labelClassName,
                                    )}
                                >
                                    {numbered && (
                                        <span className="text-purple inline-block w-[25px]">
                                            {i + 1}
                                        </span>
                                    )}
                                    {key}
                                </td>
                                <td
                                    className={cx(
                                        "text-purple py-0.5 align-top font-semibold",
                                        rightAlignValue && "text-right",
                                        rowConfClassName,
                                        valueClassName,
                                    )}
                                >
                                    {displayVal}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {withSumTotal && (
                <div className="border-offWhite mt-2 flex justify-between border-t-2 pt-2 font-semibold">
                    <span>Total</span>
                    <span className="text-purple">
                        {data
                            .map((x) =>
                                typeof x[1] === "string"
                                    ? parseFloat(x[1])
                                    : (x[1] as number),
                            )
                            .reduce((a, b) => a + b)
                            .toFixed(1)}
                    </span>
                </div>
            )}
            {footnote && (
                <p className="text-taupe-grey mt-2 text-xs">{footnote}</p>
            )}
        </div>
    );
};

const Footnote = ({
    children,
    className,
    data,
    withBorder,
}: Props & { data?: string; withBorder?: boolean }) => {
    const CLASS_NAMES = cx(
        "text-corePurple text-xs opacity-60 lg:text-sm",
        withBorder && "mt-2 pt-2 border-t border-offWhite",
        className,
    );
    if (data) {
        return <TextDataPart data={data} className={CLASS_NAMES} />;
    }
    return <div className={CLASS_NAMES}>{children}</div>;
};

const P = ({ children, className }: Props) => {
    return <p className={cx("text-sm sm:text-base", className)}>{children}</p>;
};

const Box = ({
    children,
    className,
    containerClassName,
    grow,
}: Props & {
    containerClassName?: string;
    grow?: boolean;
}) => {
    const isRefetching = useFundPageStore((store) => store.isRefetching);
    return (
        <div
            className={cx(
                "max-w-full transition-opacity sm:rounded-xl",
                isRefetching && "opacity-50",
                grow && "flex-1",
                containerClassName,
            )}
        >
            <div
                className={cx(
                    "w-full max-w-full bg-white p-4 sm:rounded-xl sm:p-6",
                    grow && "h-full",
                    className,
                )}
            >
                {children}
            </div>
        </div>
    );
};

const SectionSubTitle = ({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}) => {
    return (
        <span
            className={cx(
                "block py-1 text-xs !font-extralight leading-normal tracking-normal sm:py-2 sm:text-sm",
                className,
            )}
        >
            {children}
        </span>
    );
};

interface SectionProps {
    title?: ReactNode;
    subTitle?: string;
    dataKey: string;
    titleElem?: React.ReactNode;
    titleContainerClassName?: string;
    titleClassName?: string;
    titleElemContainerClassName?: string;
}
const Section = ({
    dataKey,
    children,
    className,
    title,
    titleElem = null,
    titleContainerClassName,
    titleClassName,
    subTitle,
    titleElemContainerClassName,
}: Props & SectionProps) => {
    return (
        <>
            {title && (
                <div
                    id={dataKey}
                    className={cx(
                        "bg-secondary relative mb-4 flex min-h-[80px] w-full max-w-full items-center justify-between p-4 sm:rounded-xl sm:px-7",
                        titleContainerClassName,
                    )}
                >
                    <h2
                        className={cx(
                            "!mb-0 flex w-full items-center justify-between text-[22px] text-white sm:text-[32px]",
                            titleClassName,
                        )}
                    >
                        <span className="block">
                            {title}
                            {subTitle && (
                                <SectionSubTitle>{subTitle}</SectionSubTitle>
                            )}
                        </span>
                        <span
                            className={cx(
                                "leading-normal tracking-normal",
                                titleElemContainerClassName,
                            )}
                        >
                            {titleElem}
                        </span>
                    </h2>
                </div>
            )}
            <div
                id={!title ? dataKey : undefined}
                data-title={title}
                className={cx("mb-2 flex w-full max-w-full gap-6", className)}
            >
                {children}
            </div>
        </>
    );
};

type WrapperProps<T> = T & {
    dataKey: string;
    withBg?: boolean;
    children: React.ReactNode;
    className?: string;
    containerClassName?: string;
    observe?: boolean;
};

const Wrapper = <T,>({
    dataKey,
    withBg,
    children,
    className,
    containerClassName,
    observe = true,
}: WrapperProps<T>) => {
    const ref = useFundPageSectionObserver(dataKey);

    return (
        <ErrorBoundary>
            <section
                ref={observe ? ref : null}
                className={cx(
                    "relative z-0 my-[32px] first-of-type:mt-4 last-of-type:mb-0 sm:my-[40px] sm:first-of-type:mt-[40px] ",
                    !withBg && "last-of-type:pb-4",
                    withBg &&
                        "min-w-screen bg-secondary px-4 py-6 sm:py-[40px]",
                    containerClassName,
                )}
            >
                <div className={cx("container mx-auto px-0", className)}>
                    {children}
                </div>
            </section>
        </ErrorBoundary>
    );
};

export {
    Box,
    Header,
    KeyValueList,
    Footnote,
    P,
    Section,
    SectionSubTitle,
    Wrapper,
};
