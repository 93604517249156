// import { LoupeLarge as LoupeIcon } from "../../../components/icons";
import { SectionSubTitle } from "../../../components/FundPageContent/FundPageContent";

// @ts-expect-error -> not sure why index.d.ts file is not working...
import LoupeIcon from "../../../assets/loupe.svg";

const TitleElem = ({ title }: { title: string }) => {
    return (
        <span className="flex items-center gap-x-2 sm:gap-x-4">
            <img
                src={LoupeIcon}
                className="h-[36px] max-h-[36px] w-[36px] max-w-[36px] sm:h-[56px] sm:max-h-[56px] sm:w-[56px] sm:max-w-[56px]"
            />
            <div className="pr-2 sm:pr-4">
                <span>{title}</span>
                <SectionSubTitle className="mt-1 hidden !py-0 text-xs leading-[14px] sm:text-sm lg:block">
                    A loupe is a tool that magnifies small details for closer
                    examination. "In the Loupe" is a regular flow of insight
                    from the fund managers and analysts.
                </SectionSubTitle>
            </div>
        </span>
    );
};

export default TitleElem;
