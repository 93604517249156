/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { produce } from "immer";

import {
    type Config,
    type PageSection as SpringConfigPageSection,
} from "@hsl/spring-capital/src/js/fund-page/config";

export default function processConfig(config: Config) {
    return produce(config, (state: Config) => {
        // Set section canvas ids based on Tenant str
        const pageSections =
            config.partConfig?.pageSections ?? config.pageSections;
        state.pageSections = pageSections
            .map((section) => {
                const dataPartId =
                    section.data &&
                    (section.data[config.tenant] ?? section.data);
                return {
                    ...section,
                    data: dataPartId,
                    baseURL: section.tenant
                        ? config.tenantURLMap[section.tenant]
                        : undefined,
                    litApiId:
                        section.litApiId && section.litApiId[config.tenant],
                    title: section.title
                        ? typeof section.title === "string"
                            ? section.title
                            : section.title[config.tenant]
                        : undefined,
                    shortTitle: section.shortTitle
                        ? typeof section.shortTitle === "string"
                            ? section.shortTitle
                            : section.shortTitle[config.tenant]
                        : undefined,
                };
            })
            .filter((x) => x !== null) as SpringConfigPageSection[];
        state.tenant = config.partConfig?.tenant ?? config.tenant;
        console.log("TENANT: ", state.tenant);
        // Get base url based on Tenant str
        state.base_url = config.tenantURLMap[state.tenant];
        state.springAudienceId = config.audienceId;
        if (state.tenant !== "Spring") {
            // Get the audience id derived from map
            state.audienceId =
                config.audienceIdMap[config.audienceId]![state.tenant];
        }
        // Override jurisdiction and audience for super users
        if (!!config.superUserMap && !!config.superUserMap[config.routeId]) {
            state.audienceId = config.superUserMap[config.routeId]!.audienceId;
            state.jurisdictionId =
                config.superUserMap[config.routeId]!.jurisdictionId;
            state.jurisdictionShortName =
                config.superUserMap[config.routeId]!.jurisdictionShortName;
        }
    });
}
