import { ReactNode, useState } from "react";

import cx from "@hsl/core/utils/cx";
import { useSidebarContext } from "@hsl/fund-page/hooks";

import { Filter } from "../../../components/icons";
import Sidebar from "../../../components/Sidebar";
import { Posts } from "../components";
import { Filters, type FiltersProps } from "../components/Filters/Filters";
import AboutUs, { type AboutUsProps } from "./AboutUs";

type Props = FiltersProps &
    AboutUsProps & { headshots?: Record<string, string> };

const ManagerDiaryMobile = ({
    author,
    diaryOverview,
    glossary,
    importantInformation,
    formData,
    headshots,
}: Props) => {
    const { toggleSidebar } = useSidebarContext();
    const [activeTab, setActiveTab] = useState<"aboutUs" | "diary">("diary");

    return (
        <div className="relative lg:hidden">
            <nav className="text-primary mb-4 flex h-[44px] justify-stretch px-4 font-semibold  print:hidden">
                <MobileTab
                    roundedLeft
                    isActive={activeTab === "aboutUs"}
                    onClick={() => setActiveTab("aboutUs")}
                >
                    About us
                </MobileTab>
                <MobileTab
                    isActive={activeTab === "diary"}
                    withBorder
                    onClick={() => setActiveTab("diary")}
                >
                    Diary
                </MobileTab>
                <MobileTab roundedRight onClick={toggleSidebar}>
                    <span className="mr-2">Filter</span>
                    <Filter />
                </MobileTab>
            </nav>
            <Sidebar>
                <Filters formData={formData} />
            </Sidebar>
            {activeTab === "aboutUs" ? (
                <AboutUs
                    author={author}
                    diaryOverview={diaryOverview}
                    glossary={glossary}
                    importantInformation={importantInformation}
                />
            ) : (
                <Posts headshots={headshots} />
            )}
        </div>
    );
};

export default ManagerDiaryMobile;

interface MobileTabProps {
    isActive?: boolean;
    withBorder?: boolean;
    children: ReactNode;
    roundedLeft?: boolean;
    roundedRight?: boolean;
    onClick?(): void;
}

const MobileTab = ({
    isActive,
    roundedLeft,
    roundedRight,
    withBorder,
    children,
    onClick,
}: MobileTabProps) => {
    return (
        <button
            className={cx(
                "relative flex flex-1 items-center justify-center overflow-hidden bg-white",
                {
                    "rounded-l-xl": roundedLeft,
                    "rounded-r-xl": roundedRight,
                    "border-x-offWhite border-x": withBorder,
                },
            )}
            onClick={onClick}
        >
            {children}
            {isActive && (
                <div className="bg-primary absolute bottom-0 left-0 right-0 min-h-[3px] min-w-[110%]" />
            )}
        </button>
    );
};
