import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";

import cx from "@hsl/core/utils/cx";

import { ArrowRight } from "../icons";
import Button, { Props } from "./Button";

const ButtonWithArrow = (props: Props) => {
    return (
        <Button
            {...props}
            className={cx(
                "bg-secondary group flex items-center justify-between gap-2 rounded-xl px-4 py-3 font-light leading-6 text-white disabled:opacity-50 group-hover:translate-y-0",
                props.className,
            )}
        >
            {props.children}
            <ArrowRight
                width={20}
                className="translate-x-1 transform transition-transform duration-1000 ease-in-out group-hover:translate-x-3"
            />
        </Button>
    );
};

export default ButtonWithArrow;
