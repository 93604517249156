import Skeleton from "./Skeleton";

const TableSkeleton = ({ numRows }: { numRows: number }) => {
    return (
        <>
            <Skeleton width={"100%"} className="mb-2 h-10 sm:mb-4" />
            <Skeleton width={"100%"} className="h-8" />
            {Array.from(Array(numRows)).map((_, i) => (
                <Skeleton key={`table-skeleton-row-${i}`} width={"100%"} />
            ))}
        </>
    );
};

export default TableSkeleton;
