import * as React from "react";

const SvgDocument = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={20}
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="M11.38.58A2.216 2.216 0 0 0 9.873 0H2.265C1.098 0 .143.885.143 1.968v15.741c0 1.083.944 1.968 2.112 1.968h12.744c1.167 0 2.122-.885 2.122-1.968V6.72c0-.52-.223-1.022-.626-1.387L11.38.58Zm.435 15.162H5.45c-.584 0-1.062-.443-1.062-.984 0-.541.478-.984 1.062-.984h6.366c.584 0 1.061.443 1.061.984 0 .54-.477.984-1.06.984Zm0-3.936H5.45c-.584 0-1.062-.442-1.062-.984 0-.54.478-.983 1.062-.983h6.366c.584 0 1.061.442 1.061.983 0 .542-.477.984-1.06.984ZM9.693 5.903V1.476l5.836 5.411h-4.775c-.583 0-1.061-.443-1.061-.984Z"
        />
    </svg>
);
export default SvgDocument;
