export type CurrencyStr = "EUR" | "GBP" | "CHF" | "JPY" | "USD";

function convertCurrencyStr(str?: CurrencyStr) {
    switch (str) {
        case "EUR":
            return "€";
        case "CHF":
            return "CHF";
        case "JPY":
            return "¥";
        case "USD":
            return "$";
        case "GBP":
            return "£";
        default:
            break;
    }
}

export function convertCurrencyStrMinor(str?: CurrencyStr) {
    switch (str) {
        case "EUR":
            return "c";
        case "CHF":
            return "Rp.";
        case "JPY":
            return "¥";
        case "USD":
            return "c";
        case "GBP":
            return "p";
        default:
            break;
    }
}

export default convertCurrencyStr;
