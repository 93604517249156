import React, { type PropsWithChildren } from "react";

import cx from "@hsl/core/utils/cx";

export interface TableValueProps {
    alignCenter?: boolean;
    alignLeft?: boolean;
    alignRight?: boolean;
    className?: string;
    gapLeft?: boolean;
    gapRight?: boolean;
    withBackground?: boolean;
}

const TableValue = ({
    alignCenter,
    alignRight,
    alignLeft = true,
    children,
    className,
    gapLeft,
    gapRight,
    withBackground,
}: TableValueProps & PropsWithChildren) => {
    return (
        <span
            className={cx(
                {
                    " bg-gray-100": withBackground,
                    "mr-1": gapRight,
                    "ml-1": gapLeft,
                    "justify-start text-left": alignLeft,
                    "justify-center text-center": alignCenter,
                    "justify-end text-right": alignRight,
                    "absolute inset-0": !children,
                },
                "flex h-full min-h-full w-full items-center p-1",
                className,
            )}
        >
            {children}
        </span>
    );
};

export default TableValue;
