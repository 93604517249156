import useMediaQuery from "@hsl/core/hooks/useMediaQuery";
import cx from "@hsl/core/utils/cx";
import { decode as htmlDecode } from "html-entities";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const BASE_STYLES = [
    "text-sm",
    "sm:text-base",
    "[&>*]:text-sm",
    "[&>*]:sm:text-base",
    "[&_img]:cursor-pointer",
];

const UNORDERED_LIST_STYLES = [
    "[&_ul]:pl-6",
    "[&_ul>li::marker]:text-bullet",
    "[&_ul>li::marker]:text-xs",
    "[&_ul>li::marker]:text-dark-yellow",
    "[&_ul>li]:!list-disc",
    "[&_ul>li]:my-1",
    "[&_ul>li]:sm:my-2",
    "[&_ul>li>strong]:!font-medium",
    "[&>ul>li>strong]:!block",
    "[&_ul:not(:last-child)]:mb-2",
    "[&_ul:not(:last-child)]:sm:mb-4",
];

const ORDERED_LIST_STYLES = [
    "[&_ol]:pl-4",
    "[&_ol]:list-decimal",
    "[&_ol:not(:last-child)]:mb-2",
    "[&_ol:not(:last-child)]:sm:mb-4",
];

const HEADER_STYLES = [
    "[&_h1]:text-lg",
    "[&_h1]:sm:text-2xl",
    "[&_h1]:text-secondary",
    "[&_h1]:font-medium",
    "[&_h1]:pb-1",
    "[&_h2]:text-lg",
    "[&_h2]:text-primary",
    "[&_h2]:font-medium",
    "[&_h2]:mb-0",
    "[&_h2]:pb-2",
    "[&_h2]:pt-3",
    "[&_h3]:text-lg",
    "[&_h3]:text-black",
    "[&_h3]:font-medium",
    "[&_h3]:mb-0",
    "[&_h3]:pb-2",
    "[&_h3]:pt-3",
];

const PARAGRAPH_STYLES = [
    "[&>p:not(:last-child)]:mb-2",
    // "[&>p:not(:first-child)]:mt-2",
    "[&>em]:my-2",
    "[&_q]:text-primary",
    "[&_q]:text-2xl",
    "[&_q]:font-light",
    "[&_q]:leading-8",
    "[&_q]:p-5",
    "[&_q]:block",
    "[&_q]:relative",
    "[&_q::after]:hidden",
    "[&_sup]:text-secondary",
    "[&_sup]:text-sm",
    "[&_sup]:font-light",
    "[&_em]:text-secondary",
];

const ANCHOR_STYLES = [
    "[&_a]:!text-darkBlue",
    "[&_a]:underline",
    // "[&_a]:break-all",
];

const MISC_CLASSNAMES = ["", "", ""];

export interface Props {
    className?: string | string[];
    data?: string;
    decode?: boolean;
}

const TextDataPart = ({ className, data, decode = true }: Props) => {
    const [modalContent, setModalContent] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isDesktop = useMediaQuery("(min-width: 1024px)");
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const target = event.target as HTMLElement;

        if (target.tagName === "IMG") {
            const image = target as HTMLImageElement;
            const parent = image.parentElement;
            const imgW = image.naturalWidth;
            const parentW = parent?.getBoundingClientRect().width ?? 0;
            if (imgW > parentW + 50 && isDesktop) {
                const clone = image.cloneNode() as HTMLImageElement;
                clone.style.cursor = "default";
                setModalContent(clone.outerHTML);
                setIsModalOpen(true);
            }
        }
    };
    const handleMouseOver = (event: React.MouseEvent<HTMLDivElement>) => {
        const target = event.target as HTMLElement;

        if (target.tagName === "IMG") {
            const image = target as HTMLImageElement;
            const parent = image.parentElement;
            const imgW = image.naturalWidth;
            const parentW = parent?.getBoundingClientRect().width ?? 0;
            if (imgW > parentW + 50 && isDesktop) {
                target.style.cursor = "zoom-in";
            } else {
                target.style.cursor = "default";
            }
        }
    };

    const closeModal = (e?: React.MouseEvent) => {
        e?.stopPropagation();
        setIsModalOpen(false);
        setModalContent(null);
    };

    useEffect(() => {
        if (isModalOpen) {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === "Escape") {
                    closeModal();
                }
            };

            window.addEventListener("keydown", handleKeyDown);

            // Cleanup the event listener when the modal is closed
            return () => {
                window.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [isModalOpen]);

    if (!data) {
        return null;
    }
    const Modal = () => {
        if (!isModalOpen) return null;

        return createPortal(
            <div style={modalStyles.overlay} onClick={closeModal}>
                <div style={modalStyles.modal}>
                    <button
                        style={modalStyles.closeButton}
                        onClick={closeModal}
                        aria-label="Close"
                    >
                        ×
                    </button>
                    <div dangerouslySetInnerHTML={{ __html: modalContent! }} />
                </div>
            </div>,
            document.body
        );
    };

    const computedData = decode ? htmlDecode(data) : data;
    return (
        <div>
            <div
                className={cx(
                    BASE_STYLES,
                    HEADER_STYLES,
                    UNORDERED_LIST_STYLES,
                    ORDERED_LIST_STYLES,
                    ANCHOR_STYLES,
                    PARAGRAPH_STYLES,
                    MISC_CLASSNAMES,
                    className
                )}
                suppressHydrationWarning
                dangerouslySetInnerHTML={{
                    __html: computedData,
                }}
                onClick={handleClick}
                onMouseOver={handleMouseOver}
            />
            <Modal />
        </div>
    );
};

export default TextDataPart;

const modalStyles = {
    overlay: {
        position: "fixed" as const,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99999,
    },
    modal: {
        background: "#fff",
        padding: "30px",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        width: "max-content",
        maxWidth: "80vw",
        maxHeight: "80%",
        overflowY: "auto" as const,
        position: "relative" as const,
    },
    closeButton: {
        position: "absolute" as const,
        top: "10px",
        right: "20px",
        background: "none",
        border: "none",
        fontSize: "1.75rem",
        lineHeight: 1,
        cursor: "pointer",
    },
};
