import React from "react";

import cx from "@hsl/core/utils/cx";

export interface Props {
    className?: string;
}

const Spinner = ({ className }: Props) => (
    <div
        className={cx(
            "border-l-primary border-r-primary mx-auto my-2 h-8 w-8 animate-spin rounded-full border-[3px] border-b-transparent border-t-transparent",
            className,
        )}
    />
);

export default Spinner;
