import { ReactNode, useMemo } from "react";

import ClientOnly from "@hsl/components/ClientOnly";
import * as TableComponent from "@hsl/components/Table";
import cx from "@hsl/core/utils/cx";
import handleDisplayDirectives, {
    type ColDefMeta,
    type ColumnDisplayDirectives,
} from "@hsl/core/utils/handleDisplayDirectives";
import {
    advancedDataTableSchema,
    AdvancedDataTableSchema,
} from "@hsl/fund-page/schemas";

import FundPageContent from "../FundPageContent";

interface Props {
    colDisplayDirectives?:
        | Partial<ColumnDisplayDirectives>
        | ((i: number) => Partial<ColumnDisplayDirectives>);
    colMeta?: ColDefMeta | ((i: number) => Partial<ColDefMeta>);
    icon?: (props: {
        i?: number;
        header?: string | null;
        value: string | null;
    }) => ReactNode;
    prefix?: (props: { i?: number; header?: string }) => string;
    suffix?: (props: { i?: number; header?: string }) => string;
    footNoteClassName?: string;
}

const Table = ({
    partId,
    part,
    colDisplayDirectives,
    colMeta,
    icon,
    prefix,
    suffix,
    footNoteClassName,
}: Omit<AdvancedDataTableSchema, "key"> & Props) => {
    const { data, headers, note, conf } = part;

    const displayDirectives = useMemo(() => {
        if (headers) {
            return headers.map((_header, i) => {
                return handleDisplayDirectives(
                    typeof colDisplayDirectives === "function"
                        ? colDisplayDirectives(i)
                        : colDisplayDirectives,
                    {},
                    {
                        headerParentClassName: cx(
                            "[&:last-child]:pr-2 text-sm md:text-base font-semibold bg-offWhite",
                            typeof colMeta === "function"
                                ? colMeta(i).headerParentClassName
                                : colMeta?.headerParentClassName,
                        ),
                        cellParentClassName: cx(
                            "[&:last-child]:pr-2 text-sm sm:py-0.5 md:text-base",
                            typeof colMeta === "function"
                                ? colMeta(i).cellParentClassName
                                : colMeta?.cellParentClassName,
                        ),
                    },
                );
            });
        }
        return [];
    }, [headers, conf]);

    return (
        <ClientOnly>
            <TableComponent.Table
                id={String(partId)}
                className="overflow-visible text-sm"
            >
                <TableComponent.Head>
                    <TableComponent.TR>
                        {headers?.map((header, i) => {
                            return (
                                <TableComponent.TH
                                    withBorder={false}
                                    key={`${partId}-th-${i}`}
                                    className={cx(
                                        displayDirectives[i]
                                            ?.headerParentClassName,
                                    )}
                                >
                                    {header}
                                </TableComponent.TH>
                            );
                        })}
                    </TableComponent.TR>
                </TableComponent.Head>
                <TableComponent.Body>
                    {data?.map((row, i) => {
                        return (
                            <TableComponent.TR key={`${partId}-tr-${i}`}>
                                {row?.map((value, j) => {
                                    let Icon;
                                    if (icon) {
                                        Icon = icon({
                                            i: j,
                                            header: headers
                                                ? headers[j]
                                                : undefined,
                                            value,
                                        });
                                    }
                                    let Prefix;
                                    if (prefix) {
                                        Prefix = prefix({
                                            i: j,
                                            header: headers
                                                ? headers[j]
                                                : undefined,
                                        });
                                    }
                                    let Suffix;
                                    if (suffix) {
                                        Suffix = suffix({
                                            i: j,
                                            header: headers
                                                ? headers[j]
                                                : undefined,
                                        });
                                    }
                                    return (
                                        <TableComponent.TD
                                            key={`${partId}-td-${j}`}
                                            className={cx(
                                                displayDirectives[j]
                                                    ?.cellParentClassName,
                                            )}
                                        >
                                            {Prefix}
                                            {value}
                                            {Suffix}
                                            {Icon}
                                        </TableComponent.TD>
                                    );
                                })}
                            </TableComponent.TR>
                        );
                    })}
                </TableComponent.Body>
            </TableComponent.Table>
            {note && (
                <FundPageContent.Footnote
                    className={cx(
                        "border-offWhite mt-2 border-t pt-2",
                        footNoteClassName,
                    )}
                    data={note}
                />
            )}
        </ClientOnly>
    );
};

export default Table;
