import { CSSProperties, ReactElement, ReactNode } from "react";
import dayjs from "dayjs";

import { type DocumentSet } from "@hsl/core/documents/types";
import cx from "@hsl/core/utils/cx";

import { Document, DownloadBlack } from "../../../components/icons";
import themeColors from "../../../lib/themeColors";

interface AdditionalProps {
    baseURL?: string;
    withDownloadIcon?: boolean;
    style?: CSSProperties;
    children?: ReactNode;
    className?: string;
    disabled?: boolean;
}

export const DocumentListItem = ({
    baseURL,
    url,
    language_aware_display_name,
    date,
    withDownloadIcon,
    children,
    style,
    className,
    disabled,
}: DocumentSet & AdditionalProps) => {
    return (
        <li
            className={cx(
                "border-b-purple relative mb-3 flex w-full min-w-[85%] items-center justify-between border-b pb-3 last-of-type:mb-0 last-of-type:border-b-0 last-of-type:pb-0 sm:w-max",
                className,
            )}
            style={style}
        >
            <a
                href={`${baseURL}${url}`}
                target="_blank"
                className={cx("flex-1 pr-4", disabled && "pointer-events-none")}
            >
                <div className="text-purple flex min-w-[160px] items-center gap-x-2 sm:min-w-[225px]">
                    <div className="mr-1 flex h-full items-center pb-4">
                        <Document height={19} fill={themeColors.purple} />
                    </div>
                    <div className="flex flex-1 flex-col items-start justify-center">
                        <div className="text-sm leading-tight sm:text-base">
                            {language_aware_display_name}
                        </div>
                        <div className="text-offWhiteDark text-xs sm:text-sm">
                            {dayjs(date).format("DD MMM YYYY")}
                        </div>
                    </div>
                    {withDownloadIcon && (
                        <DownloadBlack height={16} className="mb-4" />
                    )}
                </div>
            </a>
            {children}
        </li>
    );
};
