import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";

import { useBreakpointContext } from "@hsl/core/hooks/useBreakpoint";
import cx from "@hsl/core/utils/cx";

export function ShowMoreCTA({
    active,
    className,
    onClick,
}: {
    active: boolean;
    className?: string;
    onClick(): void;
}) {
    const isMobile = useBreakpointContext()?.currentBreakpoint === "xs";
    return (
        <button
            className={cx(
                "text-purple border-t-offWhite hover:bg-offWhite/50 -mb-4 flex w-full items-center justify-center border-t py-2 text-sm transition sm:text-base",
                className,
            )}
            onClick={onClick}
        >
            <ChevronDownIcon
                height={isMobile ? 20 : 32}
                className={cx(active && "rotate-180", "transition")}
            />
            <span>Show {active ? "less" : "more"}</span>
        </button>
    );
}
