import { GenericErrorBoundary } from "@hsl/lgim-explorer/src/components/GenericErrorBoundary/GenericErrorBoundary";

import GreyCurveContainer from "../../components/GreyCurveContainer";
import { NewsAndViewsCardListSkeleton } from "../../components/NewsAndViewsCard";
import useFundPageStore from "../../store/store";
import { ManagerPageNewsAndViews, ManagerPageRelatedEvent } from "./components";

const ManagerPage = () => {
    const { loading, config } = useFundPageStore((store) => store);
    const { routeId, partConfig } = config;

    if (loading) {
        return <NewsAndViewsCardListSkeleton className="mt-12" />;
    }

    if (!partConfig?.tenantRouteMap) {
        throw Error("Need a tenant route map in the part config");
    }

    const tenant = partConfig.tenantRouteMap[routeId];

    if (!tenant) {
        throw Error("Cannot identify the tenant from the route map provided");
    }

    return (
        <div className="flex flex-col gap-x-6 gap-y-4 lg:flex-row lg:items-stretch">
            <ManagerPageRelatedEvent tenant={tenant} />
            <ManagerPageNewsAndViews tenant={tenant} />
        </div>
    );
};

const ManagerPageRoot = () => {
    return (
        <GenericErrorBoundary>
            <GreyCurveContainer>
                <ManagerPage />
            </GreyCurveContainer>
        </GenericErrorBoundary>
    );
};

export default ManagerPageRoot;
