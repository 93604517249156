// import type { Config } from "@hsl/core/fund-page/schemas";
import { type Config } from "@hsl/spring-capital/src/js/fund-page/config";

export const config: Partial<Config> = {
    audienceId: 0,
    jurisdictionId: 0,
    usertypeId: 0,
    languageId: 0,
    fundId: 0,
    routeId: 0,
    base_url: "https://corporate2-spring-uk.huguenots.co.uk",
    pageSections: [],
};

export default config;
