import { useState } from "react";

import cx from "@hsl/core/utils/cx";
import { type AdvancedDataTableData } from "@hsl/fund-page/schemas";

import InfoMessage from "../../../components/InfoMessage";
import ShowMoreCTA from "../../../components/ShowMoreCTA";
import Table from "../../../components/Table";

const ROWS_TO_SHOW = 6;

const DividendHistoryTable = (
    props: AdvancedDataTableData & { activeCurrency?: string },
) => {
    const [showMore, setShowMore] = useState(false);

    const noData = props.data.length === 0;

    return (
        <div>
            <div
                className={cx(
                    !showMore && !noData && props.data.length > ROWS_TO_SHOW
                        ? `h-[${ROWS_TO_SHOW * 32}px] sm:h-[${
                              ROWS_TO_SHOW * 34
                          }px]`
                        : "h-max",
                    "overflow-hidden transition duration-1000 ease-out ",
                )}
            >
                <Table
                    colDisplayDirectives={{
                        alignLeft: true,
                    }}
                    colMeta={{
                        headerParentClassName: "w-[16.666%]",
                    }}
                    suffix={({ header }) =>
                        header === "Rate" ? String(props.activeCurrency) : ""
                    }
                    part={{
                        ...props,
                    }}
                />
            </div>
            {noData ? (
                <InfoMessage
                    severity="info"
                    html="There is no dividend history available for this share class"
                    className="mt-4"
                />
            ) : props.data.length > ROWS_TO_SHOW ? (
                <ShowMoreCTA
                    active={showMore}
                    onClick={() => setShowMore(!showMore)}
                />
            ) : null}
        </div>
    );
};

export default DividendHistoryTable;
