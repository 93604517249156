import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
    type Dispatch,
    type ReactNode,
    type SetStateAction,
} from "react";

import {
    type Config,
    type PerformanceChartArgs,
    type PerformanceChartDataPartSchema,
    type PerformanceChartErrorResponse,
    type PerformanceChartResponse,
} from "@hsl/core/fund-page/schemas";
import { fetchPerfChartData } from "@hsl/core/fund-page/services";
import dayjs from "@hsl/lgim-explorer/src/lib/dayjs";

import { convertPeriodToTimestamp } from "../helpers/convertPeriodToTimestamp";
import { type LineChartPeriodOption } from "../types";

type PerformanceLineChartContextType = {
    apiData?: PerformanceChartResponse;
    startTime?: Date;
    endTime?: Date;
    holidays?: string[];
    gaps?: string[][];
    error?: string;
    loading?: boolean;
    indexDisplayName?: string;
    earliestStartDate?: Date;
    asAtDate?: Date;
    setStartTime: Dispatch<SetStateAction<Date | undefined>>;
    setEndTime: Dispatch<SetStateAction<Date | undefined>>;
    handlePeriodChange: (periodOption: LineChartPeriodOption) => void;
};

export const PerformanceLineChartContext = createContext<
    PerformanceLineChartContextType | undefined
>(undefined);

type Props = {
    children: ReactNode;
    perfChartData: PerformanceChartDataPartSchema;
    indexDisplayName?: string;
    shareClassId: number;
    launchDate: string;
    baseURL?: string;
} & Pick<Config, "audienceId" | "routeId" | "languageId">;

export const PerformanceLineChartProvider = ({
    children,
    perfChartData,
    audienceId,
    routeId,
    languageId,
    indexDisplayName,
    shareClassId,
    launchDate,
    baseURL,
}: Props) => {
    const { part_id, initial_share_class, holidays } = perfChartData;

    const [loading, setLoading] = useState(true);

    const [apiData, setApiData] = useState<PerformanceChartResponse>();

    const [gaps, setGaps] = useState<string[][]>([]);

    const [startTime, setStartTime] = useState<Date | undefined>();
    const [endTime, setEndTime] = useState<Date | undefined>();

    const [error, setError] = useState<string | undefined>();

    const EARLIEST_START_DATE = useMemo(
        () =>
            apiData?.earliest_start_date
                ? new Date(apiData.earliest_start_date)
                : undefined,
        [apiData?.earliest_start_date],
    );

    const AS_AT_DATE = useMemo(
        () => (apiData?.as_at_date ? new Date(apiData.as_at_date) : undefined),
        [apiData?.as_at_date],
    );

    useEffect(() => {
        if (startTime && endTime) {
            // Set a custom date range
            void getAndSetData({
                start: startTime,
                end: endTime,
            });
        }
    }, [startTime, endTime]);

    useEffect(() => {
        if (shareClassId) {
            // Update data on fund/shareClass change -> since launch
            // second argument forces it to use the launch date
            void getAndSetData({}, true);
        }
    }, [shareClassId]);

    const contextValue = useMemo(
        () => ({
            apiData,
            startTime,
            endTime,
            holidays,
            gaps,
            error,
            loading,
            indexDisplayName: indexDisplayName,
            earliestStartDate: EARLIEST_START_DATE,
            asAtDate: AS_AT_DATE,
            setStartTime,
            setEndTime,
            handlePeriodChange: (periodOption: LineChartPeriodOption) => {
                if (periodOption.option === "sl") {
                    setStartTime(EARLIEST_START_DATE);
                } else if (periodOption.option === "sspd") {
                    const significantDate =
                        apiData?.share_class_info.significant_performance_date;
                    if (!significantDate) {
                        setError("No significant performance date available.");
                        return;
                    }
                    setStartTime(new Date(significantDate));
                } else {
                    const periodInMilliseconds = convertPeriodToTimestamp(
                        periodOption.option,
                    );
                    if (AS_AT_DATE && periodInMilliseconds) {
                        const startDate = dayjs(
                            new Date(
                                AS_AT_DATE.valueOf() - periodInMilliseconds,
                            ),
                        );
                        if (AS_AT_DATE && periodInMilliseconds) {
                            const startDate = dayjs(
                                new Date(
                                    AS_AT_DATE.valueOf() - periodInMilliseconds,
                                ),
                            );
                            const startDateEndOfMonth =
                                startDate.endOf("month");
                            setStartTime(
                                new Date(startDateEndOfMonth.valueOf()),
                            );
                        }
                    }
                    setEndTime(AS_AT_DATE);
                }
            },
        }),
        [
            apiData,
            startTime,
            endTime,
            holidays,
            gaps,
            error,
            loading,
            indexDisplayName,
            EARLIEST_START_DATE,
            AS_AT_DATE,
        ],
    );

    return (
        <PerformanceLineChartContext.Provider value={contextValue}>
            {children}
        </PerformanceLineChartContext.Provider>
    );

    /******************************
     *  HELPERS
     *******************************/
    async function getAndSetData(
        { start, end }: { start?: Date; end?: Date },
        shareClassIsChanging?: boolean,
    ) {
        const args: PerformanceChartArgs = {
            audienceId,
            partId: part_id,
            routeId: routeId,
            shareClassID: shareClassId ?? initial_share_class,
            languageId,
        };
        if (start && end) {
            // Use specific date range
            args.start_date = dayjs(start).format("YYYY-MM-DD");
            args.end_date = dayjs(end).format("YYYY-MM-DD");
            await fetchData(args);
        } else {
            // Get data since launch
            // args["period_length"] = "sl";
            const newData = await fetchData(args);

            if (!startTime || shareClassIsChanging) {
                const tenYearsAgo = dayjs().subtract(10, "year");
                const isMoreThanTenYearsOld = dayjs(
                    launchDate,
                    "DD/MM/YYYY",
                ).isBefore(tenYearsAgo);

                if (
                    newData.period.includes("sspd") &&
                    newData.share_class_info.significant_performance_date
                ) {
                    // if sspd defined then use that as a starting date
                    setStartTime(
                        new Date(
                            newData.share_class_info.significant_performance_date,
                        ),
                    );
                } else {
                    setStartTime(
                        isMoreThanTenYearsOld
                            ? new Date(tenYearsAgo.valueOf())
                            : new Date(newData.earliest_start_date),
                    );
                }
                if (
                    Array.isArray(newData.share_class_plots) &&
                    newData.share_class_plots.length > 0 &&
                    newData.share_class_plots[0]?.gaps
                ) {
                    setGaps(newData.share_class_plots[0].gaps);
                }
            }
            if (!endTime || shareClassIsChanging) {
                setEndTime(new Date(newData.as_at_date));
            }
        }
    }

    async function fetchData(args: PerformanceChartArgs) {
        setLoading(true);
        const data = await fetchPerfChartData(args, baseURL);
        const { message } = data as PerformanceChartErrorResponse;
        if (message) {
            setError(
                message
                    ? "No data is available for the selected share class"
                    : undefined,
            );
        } else {
            setApiData(data as PerformanceChartResponse);
            setError(undefined);
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
        return data as PerformanceChartResponse;
    }
};

export function usePerformanceLineChartContext() {
    const Context = useContext(PerformanceLineChartContext);
    if (!Context) throw new Error("No PerformanceLineChartProvider");
    return Context;
}
