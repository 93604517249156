import * as React from "react";

const SvgNewPage = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        fill="none"
        {...props}
    >
        <path
            fill="#434D68"
            d="M13.417 13.08H2.337V2h5.54V.419h-5.54C1.46.418.756 1.13.756 2.001v11.08c0 .87.704 1.582 1.583 1.582h11.08c.87 0 1.582-.712 1.582-1.582V7.54h-1.583v5.54ZM9.46.418v1.583h2.841L4.523 9.78l1.116 1.115 7.78-7.78v2.842H15V.418H9.46Z"
        />
    </svg>
);
export default SvgNewPage;
