import { useEffect } from "react";
import { z } from "zod";

import {
    dataPartSchema,
    eventListPartSchema,
    postListPartSchema,
} from "@hsl/fund-page/schemas";

import FundPageContent from "../../components/FundPageContent";
import { NewsAndViewsCardSkeleton } from "../../components/NewsAndViewsCard";
import useInsights from "../../hooks/useInsights";
import usePageSection from "../../hooks/usePageSection";
import useFundPageStore from "../../store/store";
import { FundEventCard, NewsAndViewsCard, NewsAndViewsCTA } from "./components";

const newsAndViewsSchema = dataPartSchema.extend({
    canvas: dataPartSchema.extend({
        part: z.object({
            newsAndViews: postListPartSchema,
            events: eventListPartSchema,
        }),
    }),
});

interface Props {
    dataKey: string;
    onNoData?: (key: string, hide: boolean) => void;
}

const NewsAndViews = ({ dataKey, onNoData }: Props) => {
    const { springFundId, springAudienceId } = useFundPageStore(
        (store) => store.config,
    );
    const { canvas } = usePageSection(dataKey, newsAndViewsSchema);
    const { newsAndViews, events } = canvas.part;

    const featuredEvent = events.part.data[0];

    const { insights, isLoading } = useInsights({
        pageSize: featuredEvent ? 2 : 3,
        filters: {
            funds: [springFundId],
            audiences: [springAudienceId],
            categories: [37, 40, 41, 112, 156, 230],
        },
        orderBy: "as_at_date",
    });

    useEffect(() => {
        onNoData?.(dataKey, insights.length === 0);
    }, [dataKey, insights.length, onNoData]);

    return (
        <FundPageContent.Section
            dataKey={dataKey}
            className="flex-col xl:flex-row"
        >
            <div className="flex flex-col justify-between">
                <FundPageContent.Header h3 className="text-white sm:mb-4">
                    {newsAndViews.title}
                </FundPageContent.Header>
                <div className="custom-scroller flex flex-1 snap-x flex-col gap-x-6 gap-y-4 sm:flex-row sm:overflow-x-auto xl:overflow-hidden">
                    {isLoading ? (
                        <>
                            <NewsAndViewsCardSkeleton />
                            <NewsAndViewsCardSkeleton />
                            {!featuredEvent && <NewsAndViewsCardSkeleton />}
                        </>
                    ) : (
                        insights.map((article, i) => (
                            <NewsAndViewsCard
                                key={`${article.title}-${i}`}
                                {...article}
                            />
                        ))
                    )}
                </div>
                <NewsAndViewsCTA
                    href="/News-Views"
                    text="More news and views"
                />
            </div>
            {featuredEvent && (
                <div className="flex flex-col justify-between">
                    <FundPageContent.Header h3 className="text-white sm:mb-4">
                        {events.title}
                    </FundPageContent.Header>
                    <FundEventCard {...featuredEvent} />
                    <NewsAndViewsCTA href="/Events" text="More events" />
                </div>
            )}
        </FundPageContent.Section>
    );
};

const NewsAndViewsWithErrorBoundary = (props: Props) => {
    return (
        <FundPageContent.Wrapper dataKey={props.dataKey} withBg>
            <NewsAndViews {...props} />
        </FundPageContent.Wrapper>
    );
};

export default NewsAndViewsWithErrorBoundary;
