import cx from "@hsl/core/utils/cx";
import { isLight } from "@hsl/core/utils/isLight";

import Spinner from "../../../../components/Spinner";
import { useManagerDiaryContext } from "../../context";
import { FilterCheckbox, FilterHeader, FilterSection } from "./FilterContent";

export const PostTypeFilters = () => {
    const { postTypes, activePostTypes, setActivePostTypes, isLoadingFilters } =
        useManagerDiaryContext();

    return isLoadingFilters && postTypes.length === 0 ? (
        <Spinner className="h-4 w-4" />
    ) : (
        <FilterSection>
            <FilterHeader>Post type</FilterHeader>
            {postTypes.map(({ id, name, color }) => {
                const isSelected = activePostTypes.includes(id);
                return (
                    <FilterCheckbox<string>
                        key={`${id}-filter`}
                        value={id}
                        onChange={(val) => {
                            if (activePostTypes.includes(val)) {
                                setActivePostTypes(
                                    activePostTypes.filter((x) => x !== val),
                                );
                            } else {
                                setActivePostTypes([...activePostTypes, val]);
                            }
                        }}
                        selected={isSelected}
                        inputClassName={cx(
                            !isSelected && "border-[3px]",
                            "mr-1 rounded-none",
                        )}
                        checkIconClassName={
                            isLight(color) ? "text-black" : "text-white"
                        }
                        inputStyles={{
                            borderColor: color,
                            backgroundColor: isSelected ? color : "transparent",
                        }}
                        label={name}
                    />
                );
            })}
        </FilterSection>
    );
};
