import { ReactNode } from "react";

import cx from "@hsl/core/utils/cx";

export const BUTTON_CLASSNAMES =
    "flex w-max items-center rounded-lg  text-lg px-2.5 sm:px-4 py-2 transition duration-300";

export interface Props {
    type?: "button" | "submit" | "reset";
    href?: string;
    openInNewTab?: boolean;
    className?: string;
    children?: ReactNode;
    disabled?: boolean;
    onClick?: () => void;
}

const Button = ({
    href,
    openInNewTab,
    className,
    children,
    disabled,
    type = "button",
    onClick,
}: Props) => {
    if (href && !disabled) {
        return (
            <a
                target={openInNewTab ? "_blank" : "_self"}
                href={href}
                className={cx(
                    BUTTON_CLASSNAMES,
                    className,
                    disabled && "opacity-50",
                )}
            >
                {children}
            </a>
        );
    } else {
        return (
            <button
                type={type}
                disabled={disabled}
                className={cx(
                    BUTTON_CLASSNAMES,
                    className,
                    disabled && "cursor-not-allowed opacity-50",
                )}
                onClick={onClick}
            >
                {children}
            </button>
        );
    }
};

export default Button;
