import * as ReactDOMClient from "react-dom/client";

import { getGenericScriptContent } from "@hsl/core/fund-page/services";
import App from "@hsl/spring-fund-page";
import useFundPageStore from "@hsl/spring-fund-page/src/store/store";

import { type Config, type PartViewConfig } from "./config";

const params = new URLSearchParams(window.location.search);
const isWorkingDraft = params.has("version") || params.has("no-cache");

try {
    convertAndReloadURL();
    const contentContainer = document.querySelector(
        "#masterContainer .content [data-part_type='ReactComponent']",
    ) as HTMLElement;

    const rootContainer = contentContainer.querySelector(
        "#root",
    ) as HTMLElement;

    const rootConfig = getGenericScriptContent(
        contentContainer,
        "#root-config",
        true,
    );

    if (!rootConfig) {
        throw Error("No root config");
    }
    const config = rootConfig.data as Config;
    contentContainer.querySelector("script#root-config")?.remove();

    // Get part config if present
    const partConfig = getGenericScriptContent(
        contentContainer,
        "#conf",
        false,
    );
    if (partConfig) {
        contentContainer.querySelector("script#conf")?.remove();
        config.partConfig = partConfig.data as PartViewConfig;
    }

    //Get single post id if present
    const singlePostArticle = contentContainer.querySelector(
        "article[data-post-id]",
    );
    if (singlePostArticle) {
        const postId = (singlePostArticle as HTMLElement).dataset.postId;
        config.postId = postId;
    }

    // Initialize store
    if (!isWorkingDraft && rootContainer.dataset.static) {
        try {
            const staticConfig = JSON.parse(
                String(document.querySelector("#static-config")?.innerHTML),
            );
            const staticPageSections = JSON.parse(
                String(
                    document.querySelector("#static-page-sections")?.innerHTML,
                ),
            );
            const staticRelatedFunds = JSON.parse(
                String(
                    document.querySelector("#static-related-funds")?.innerHTML,
                ),
            );
            const staticShareClasses = JSON.parse(
                String(
                    document.querySelector("#static-share-classes")?.innerHTML,
                ),
            );

            void useFundPageStore.getState().init(config, {
                config: staticConfig,
                pageSections: staticPageSections,
                relatedFunds: staticRelatedFunds,
                shareClasses: staticShareClasses,
            });
        } catch (err) {
            console.log(
                "Could not initialise store, falling back to client-only",
                err,
            );
            rootContainer.innerHTML = "";
            void useFundPageStore.getState().init(config);
        }
    } else {
        void useFundPageStore.getState().init(config);
    }

    const contentInRoot = rootContainer.children.length > 0;

    if (contentInRoot && !isWorkingDraft) {
        ReactDOMClient.hydrateRoot(rootContainer, <App config={config} />);
    } else {
        if (isWorkingDraft) {
            // empty the cached root
            rootContainer.innerHTML = "";
        }
        ReactDOMClient.createRoot(rootContainer).render(
            <App config={config} />,
        );
    }
} catch (err) {
    console.error(err);
}
// })();

function convertAndReloadURL() {
    const shouldConvert = window.location.hash.startsWith("#/");
    if (!shouldConvert) {
        return;
    }
    const currentURL = window.location.href;
    const urlParts = currentURL.split("#/");
    const [baseUrl = "", hashPath = ""] = urlParts;
    const newURL = new URL(baseUrl);
    newURL.pathname = newURL.pathname + hashPath;
    // Updating the browser's URL and reloading the page
    window.location.href = newURL.toString();
}
