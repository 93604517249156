import { useMemo } from "react";

import { EventListItem } from "@hsl/fund-page/schemas";

import {
    CollapsibleListContainer,
    ListContainer,
} from "../../../components/Card";
import FundEventCard from "../../../components/FundEventCard";
import NoDataMessage from "../components/NoDataMessages";
import { useEventsContext } from "../context";

export const EventList = ({
    type,
    events,
    withShowMoreCTA,
}: {
    type: "upcoming" | "past";
    events: EventListItem[];
    withShowMoreCTA?: boolean;
}) => {
    const { activeCategory, activeFund } = useEventsContext();

    const activeEvents = useMemo(() => {
        return events
            .filter((event) => !activeCategory || event.type === activeCategory)
            .filter(
                (event) =>
                    !activeFund ||
                    event.funds.some((x) => x.name === activeFund),
            );
    }, [events, activeCategory, activeFund]);

    if (activeEvents.length === 0) {
        if (type === "past") {
            return <NoDataMessage type="filter" />;
        } else {
            return (
                <NoDataMessage
                    type={activeCategory || activeFund ? "filter" : "upcoming"}
                />
            );
        }
    }

    if (withShowMoreCTA) {
        return (
            <>
                <ListContainer className="flex-wrap sm:overflow-hidden">
                    {activeEvents.slice(0, 6).map((event) => (
                        <FundEventCard
                            key={event.id}
                            disableLink={type === "past"}
                            {...event}
                        />
                    ))}
                </ListContainer>
                {activeEvents.length > 6 && (
                    <CollapsibleListContainer className="mt-6">
                        <ListContainer className="flex-wrap sm:overflow-hidden">
                            {activeEvents.slice(6).map((event) => (
                                <FundEventCard
                                    key={event.id}
                                    disableLink={type === "past"}
                                    {...event}
                                />
                            ))}
                        </ListContainer>
                    </CollapsibleListContainer>
                )}
            </>
        );
    }
    return (
        <ListContainer className="flex-wrap sm:overflow-hidden">
            {activeEvents.map((event) => (
                <FundEventCard
                    key={event.id}
                    disableLink={type === "past"}
                    {...event}
                />
            ))}
        </ListContainer>
    );
};
