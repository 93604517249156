import * as React from "react";

const SvgArrowDown = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={10}
        fill="none"
        {...props}
    >
        <path
            fill="#434D68"
            d="M.419.704a1.426 1.426 0 0 0 0 2.02L6.99 9.294c.558.558 1.46.558 2.018 0l6.572-6.572a1.426 1.426 0 0 0 0-2.019 1.426 1.426 0 0 0-2.019 0L7.992 6.26 2.439.704a1.439 1.439 0 0 0-2.02 0Z"
        />
    </svg>
);
export default SvgArrowDown;
