import * as React from "react";

const SvgFactSheetWhite = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={74}
        height={82}
        fill="none"
        {...props}
    >
        <path
            fill="#D3E3CC"
            fillRule="evenodd"
            d="M43.916 51.912c0 12.127-9.83 21.959-21.958 21.959H0V51.912c0-12.127 9.831-21.958 21.958-21.958 12.127 0 21.958 9.831 21.958 21.958Z"
            clipRule="evenodd"
        />
        <rect
            width={60.381}
            height={80}
            x={12.402}
            y={1}
            stroke="#fff"
            strokeWidth={2}
            rx={5}
        />
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth={2}
            d="M20.455 38.066v-13.92M28.022 38.066V12.643M35.588 38.066v-13.92M20.482 51.155H65.32M20.482 60.235h44.641M20.482 69.314H42.9"
        />
        <circle
            cx={53.149}
            cy={27.873}
            r={10.554}
            stroke="#fff"
            strokeWidth={2}
        />
        <path
            stroke="#fff"
            strokeWidth={2}
            d="M54.149 16.319v11.555M64.7 28.873l-11.554-.033"
        />
    </svg>
);
export default SvgFactSheetWhite;
