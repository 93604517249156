import * as React from "react";

const SvgArrowRight = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={15}
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            d="M15.706 6.371a1.066 1.066 0 1 1 0 2.131H1.321a1.066 1.066 0 0 1 0-2.13h14.385Z"
        />
        <path
            fill="#fff"
            d="M9.625.312a1.066 1.066 0 0 1 1.507 0l6.37 6.37.754.754-7.124 7.124a1.065 1.065 0 1 1-1.507-1.507l5.617-5.617-5.617-5.617a1.066 1.066 0 0 1-.062-1.44l.062-.067Z"
        />
    </svg>
);
export default SvgArrowRight;
