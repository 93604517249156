import * as React from "react";

const SvgOnlineEvent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={34}
        height={34}
        fill="none"
        {...props}
    >
        <circle cx={16.876} cy={17.254} r={16.592} fill="#434D68" />
        <g fill="#fff">
            <path d="M21.02 10.05v7.16c0 5.424-8.288 5.467-8.288 0v-7.16c0-5.38 8.288-5.38 8.288 0Z" />
            <path d="M15.813 24.065c-5.51-.52-6.161-5.597-6.161-10.24 0-.781 1.649-.781 1.822-.087.044 3.732.087 8.504 5.38 8.504 5.337 0 5.38-4.773 5.38-8.504.13-.738 1.866-.694 1.866.087 0 4.643-.65 9.719-6.248 10.24v2.603h2.864c1.258 0 1.301 1.823 0 1.823h-7.767c-1.215 0-1.171-1.823 0-1.823h2.864v-2.603Z" />
        </g>
    </svg>
);
export default SvgOnlineEvent;
