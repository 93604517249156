import * as React from "react";

const SvgNewsInvestmentViews = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={41}
        height={41}
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            d="M25.475 16.472a12.898 12.898 0 0 0-3.164 1.562v.964a11.803 11.803 0 0 1 3.224-1.716l-.06-.81ZM22.31 20.167a12.969 12.969 0 0 1 3.32-1.613l.06.807c-1.21.402-2.35 1-3.38 1.77v-.964Z"
        />
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M9.236 26.794h23.1V11.97h-23.1v14.824Zm11.166-10.113c-1.404-1.513-3.385-2.305-5.41-2.165l-.59 7.063a12.766 12.766 0 0 1 6 2.921v-7.819Zm6.172-2.165.59 7.063a12.767 12.767 0 0 0-6 2.921v-7.819c1.405-1.512 3.385-2.305 5.41-2.165Zm1.637 1.777.575 6.754a13.02 13.02 0 0 0-6.37 1.427A12.488 12.488 0 0 1 27.62 22.3l.365-.046-.496-5.938c.304-.021.553-.027.722-.024Zm-14.851-.018c.17.001.416.01.719.032l-.497 5.948.365.046a12.504 12.504 0 0 1 5.056 2.064 13.424 13.424 0 0 0-6.217-1.343l.574-6.747Z"
            clipRule="evenodd"
        />
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M8.186 9.5h25.2a1.41 1.41 0 0 1 1.4 1.412v16.941a1.41 1.41 0 0 1-1.4 1.412H23.235v3.53h3.85v.705h-12.6v-.706h3.85v-3.53H8.185a1.41 1.41 0 0 1-1.4-1.41V10.911a1.41 1.41 0 0 1 1.4-1.412Zm10.849 23.294h3.5v-3.529h-3.5v3.53ZM8.535 27.5V11.265h24.501V27.5h-24.5Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgNewsInvestmentViews;
