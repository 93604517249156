import { createContext, useContext, useMemo, useState } from "react";

import type {
    PostListFilterData,
    PostListFilterItem,
} from "@hsl/fund-page/schemas";

import useInsights, {
    type Insight,
    type InsightsFiltersParams,
} from "../../hooks/useInsights";

export type NewsAndViewsContextType = {
    activePosts?: Insight[];
    activeFund?: PostListFilterItem | null;
    activeContributor?: PostListFilterItem | null;
    activeCategory?: PostListFilterItem | null;
    reloadingIds: boolean;
    isLoadingMorePosts: boolean;
    numPostsToFetch?: number;
    filtersActive?: boolean;
    filters: PostListFilterData;
    setActiveFund(this: void, val: PostListFilterItem | null): void;
    setActiveContributor(this: void, val: PostListFilterItem | null): void;
    setActiveCategory(this: void, val: PostListFilterItem | null): void;
};

export interface Props {
    children: React.ReactNode;
    numPostsToFetch: number;
    fetchMorePosts: boolean;
    filters: PostListFilterData;
    // if config is not present then will take from the session
    config?: {
        audienceId?: number;
        jurisdictionId?: number;
        usertypeId?: number;
    };
}

export const NewsAndViewsContext = createContext<
    NewsAndViewsContextType | undefined
>(undefined);

export const NewsAndViewsContextProvider = ({
    children,
    numPostsToFetch,
    fetchMorePosts,
    filters,
    config,
}: Props) => {
    const params = getFilterParamsFromSearch(filters);
    const [activeContributor, setActiveContributor] =
        useState<PostListFilterItem | null>(params.contributor ?? null);
    const [activeFund, setActiveFund] = useState<PostListFilterItem | null>(
        (params.fund as PostListFilterItem) ?? null,
    );
    const [activeCategory, setActiveCategory] =
        useState<PostListFilterItem | null>(params.category ?? null);

    const { insights, isLoading, isLoadingMore } = useInsights({
        queryKey: "newsAndViews",
        pageSize: numPostsToFetch,
        fetchMoreInsights: fetchMorePosts,
        includePostBody: true,
        filters: {
            audiences: [config?.audienceId],
            funds: [activeFund?.id],
            categories: activeCategory?.id
                ? [activeCategory.id]
                : [37, 38, 39, 40, 41, 112, 156, 230],
            contributors: [activeContributor?.id],
        } as InsightsFiltersParams,
    });

    const contextValue = useMemo<NewsAndViewsContextType>(
        () => ({
            activePosts: insights,
            activeFund,
            activeContributor,
            activeCategory,
            filtersActive:
                !!activeFund || !!activeContributor || !!activeCategory,
            reloadingIds: isLoading,
            filters,
            isLoadingMorePosts: isLoadingMore,
            numPostsToFetch,
            setActiveContributor,
            setActiveFund,
            setActiveCategory,
        }),
        [
            insights,
            activeFund,
            activeContributor,
            activeCategory,
            numPostsToFetch,
            isLoading,
            isLoadingMore,
            filters,
            setActiveContributor,
            setActiveFund,
            setActiveCategory,
        ],
    );

    return (
        <NewsAndViewsContext.Provider value={contextValue}>
            {children}
        </NewsAndViewsContext.Provider>
    );
};

export function useNewsAndViewsContext() {
    const documentContext = useContext(NewsAndViewsContext);
    if (!documentContext) throw new Error("No NewsAndViewsContextProvider");
    return documentContext;
}

function getFilterParamsFromSearch(filters: PostListFilterData) {
    const searchParams = new URL(String(document.location)).searchParams;

    const args = {
        contributor: filters.contributors?.find(
            (f) => f.id === searchParams.get("contributor"),
        ),
        fund: filters.funds?.find(
            (f) =>
                f && (f as PostListFilterItem).id === searchParams.get("fund"),
        ),
        category: filters.categories?.find(
            (f) => f.id === searchParams.get("category"),
        ),
    };
    return args;
}
