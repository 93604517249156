import { type DocumentSet } from "@hsl/documents/types";

import Dropdown from "../../../components/Dropdown";

interface Props {
    value: Date;
    docs: DocumentSet[];
    onChange(date: Date): void;
}

const YearlyDateSelector = ({ docs, value, onChange }: Props) => {
    return (
        <Dropdown<number>
            value={value.getFullYear()}
            options={Array.from(
                new Set(docs.map((doc) => new Date(doc.date).getFullYear())),
            ).sort((a, b) => (a > b ? -1 : 1))}
            onChange={(year) => {
                const newDate = new Date();
                newDate.setFullYear(year);
                onChange(newDate);
            }}
            buttonClassName="bg-white"
        />
    );
};

export default YearlyDateSelector;
