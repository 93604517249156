import { z } from "zod";

import usePosts from "@hsl/fund-page/hooks/usePostsQuery";
import { dataPartSchema, fundPropertyDataSchema } from "@hsl/fund-page/schemas";
import { Tenant } from "@hsl/spring-capital/src/js/fund-page/config";

import { ListContainer as CardListContainer } from "../../../components/Card/Card";
import FundPageContent from "../../../components/FundPageContent";
import NewsAndViewsCard, {
    NewsAndViewsCardSkeleton,
} from "../../../components/NewsAndViewsCard";
import usePageSection from "../../../hooks/usePageSection";
import useFundPageStore from "../../../store/store";

const allFundsSchema = z.object({
    data: z.array(fundPropertyDataSchema.extend({ tenant: z.string() })),
});

export const ManagerPageNewsAndViews = ({ tenant }: { tenant: Tenant }) => {
    const { audienceId, jurisdictionId, usertypeId } = useFundPageStore(
        (store) => store.config,
    );
    const { title, postApiId } = usePageSection("newsAndViews", dataPartSchema);
    const allFunds = usePageSection("allFunds", allFundsSchema);

    const filteredFunds = allFunds.data.filter(
        (fund) => fund.tenant === tenant,
    );

    const { activePosts, isRefetchingIds, isLoadingMorePosts } = usePosts({
        dataPartId: Number(postApiId),
        config: {
            readRoute: 4160,
            metaTemplate: 2527,
            template: 2520,
            version: "live",
            numPostsToFetch: 2,
            audienceId: audienceId,
            jurisdictionId: jurisdictionId,
            usertypeId: usertypeId,
        },
        funds: filteredFunds.map(({ id }) => id),
    });

    if (!isRefetchingIds && !isLoadingMorePosts && activePosts.length === 0) {
        return null;
    }

    return (
        <div className="flex flex-col lg:max-w-[60%] xl:max-w-none">
            <FundPageContent.Header h2 className="font-extralight">
                {title}
            </FundPageContent.Header>

            <CardListContainer className="flex-1 sm:flex-col md:flex-row">
                {isRefetchingIds || isLoadingMorePosts ? (
                    <Loading />
                ) : (
                    activePosts.map((post) => (
                        <NewsAndViewsCard key={post.id} {...post} />
                    ))
                )}
            </CardListContainer>
        </div>
    );
};

const Loading = () => (
    <CardListContainer className="flex-1">
        <NewsAndViewsCardSkeleton />
        <NewsAndViewsCardSkeleton />
    </CardListContainer>
);
