import ExclamationTriangleIcon from "@heroicons/react/20/solid/ExclamationTriangleIcon";

import cx from "@hsl/core/utils/cx";

export interface Props {
    severity: "info" | "warning";
    html?: string;
    className?: string;
    textClassName?: string;
    withBorder?: boolean;
}

export const InfoMessage = ({
    severity,
    html,
    className,
    textClassName,
    withBorder,
}: Props) => {
    const containerClassName = cx(
        "w-full pl-2 py-2 pr-4 rounded flex-row flex items-start justify-center",
        className,
    );
    const iconClassName = "w-4 h-4 mt-0.5 mr-1.5 flex-shrink-0";
    const strClassName = cx(
        "text-sm [&_a]:font-medium [&_a:hover]:underline leading-tight",
        textClassName,
    );
    if (!html) {
        return null;
    }
    switch (severity) {
        case "info":
            return (
                <div
                    className={cx(
                        containerClassName,
                        "text-darkBlue bg-lightBlue",
                        withBorder && "border-darkBlue/50 border",
                    )}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        className={iconClassName}
                    >
                        <path
                            d="M7.575 10.399H9.175V11.999H7.575V10.399ZM7.575 3.99902H9.175V8.79902H7.575V3.99902ZM8.367 -0.000976562C3.951 -0.000976562 0.375 3.58302 0.375 7.99902C0.375 12.415 3.951 15.999 8.367 15.999C12.791 15.999 16.375 12.415 16.375 7.99902C16.375 3.58302 12.791 -0.000976562 8.367 -0.000976562ZM8.375 14.399C4.839 14.399 1.975 11.535 1.975 7.99902C1.975 4.46302 4.839 1.59902 8.375 1.59902C11.911 1.59902 14.775 4.46302 14.775 7.99902C14.775 11.535 11.911 14.399 8.375 14.399Z"
                            fill="#127AD2"
                        />
                    </svg>
                    <div
                        className={strClassName}
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </div>
            );
        case "warning":
            return (
                <div
                    className={cx(
                        containerClassName,
                        "bg-yellow-100 text-yellow-700",
                        withBorder && "border-text-yellow-700/50 border",
                    )}
                >
                    <ExclamationTriangleIcon className={iconClassName} />
                    <div
                        className={strClassName}
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </div>
            );
    }
};
