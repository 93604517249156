import { useState } from "react";
import { z } from "zod";

import { CollapsibleTransition } from "@hsl/components/Collapsible/components";
import {
    BreakpointProvider,
    useBreakpointContext,
} from "@hsl/core/hooks/useBreakpoint";
import {
    dataPartSchema,
    eventListItemSchema,
    type EventListItem,
} from "@hsl/fund-page/schemas";
import { GenericErrorBoundary } from "@hsl/lgim-explorer/src/components/GenericErrorBoundary/GenericErrorBoundary";

import FundPageContent from "../../components/FundPageContent";
import {
    ArrowDown as ArrowIcon,
    Filter as FilterIcon,
} from "../../components/icons";
// import NoDataMessage from "../../components/NoDataMessage";
import usePageSection from "../../hooks/usePageSection";
import useFundPageStore from "../../store/store";
import { EventList, EventsSkeleton, Filters } from "./components";
import { EventsContextProvider } from "./context";

const Events = () => {
    const loading = useFundPageStore((store) => store.loading);

    if (loading) {
        return <EventsSkeleton />;
    }

    return (
        <GenericErrorBoundary>
            <BreakpointProvider>
                <div className="bg-offWhite">
                    <div className="container mx-auto pt-8">
                        <Content />
                    </div>
                </div>
            </BreakpointProvider>
        </GenericErrorBoundary>
    );
};

export default Events;

const eventsSchema = dataPartSchema.extend({
    data: z.array(eventListItemSchema),
});

const Content = () => {
    const upcomingEvents = usePageSection("upcoming", eventsSchema);
    const pastEvents = usePageSection("past", eventsSchema.optional());

    const upcomingEventsData = upcomingEvents.data.map(parseEventType);
    const pastEventsData = pastEvents?.data?.map(parseEventType);

    let allEvents: EventListItem[];
    const hasPastEvents =
        Boolean(pastEventsData) &&
        Array.isArray(pastEventsData) &&
        pastEventsData.length > 0;
    if (hasPastEvents) {
        allEvents = [...upcomingEventsData, ...pastEventsData];
    } else {
        allEvents = upcomingEventsData;
    }

    const isMobile = useBreakpointContext()?.currentBreakpoint === "xs";
    const [showFilters, setShowFilters] = useState(isMobile ? false : true);
    const toggleShowFilters = () => setShowFilters(!showFilters);

    const [pastEventsAccordionOpen, setPastEventsAccordionOpen] =
        useState(false);

    return (
        <div>
            <EventsContextProvider events={allEvents}>
                <div className="-mb-6 flex flex-col items-end justify-start lg:mb-4">
                    <button
                        className="flex items-center gap-2 sm:hidden"
                        onClick={toggleShowFilters}
                    >
                        <FilterIcon />
                        Filters
                    </button>

                    <CollapsibleTransition
                        show={showFilters}
                        className="relative mb-10 w-full sm:mt-0 lg:mb-0 lg:w-fit"
                    >
                        <Filters />
                    </CollapsibleTransition>
                </div>
                <div className="pb-12">
                    <FundPageContent.Section
                        dataKey="upcoming"
                        className="block"
                    >
                        <FundPageContent.Header h2>
                            {upcomingEvents.title}
                        </FundPageContent.Header>
                        <EventList
                            type="upcoming"
                            events={upcomingEventsData}
                        />
                    </FundPageContent.Section>

                    {hasPastEvents && (
                        <FundPageContent.Section
                            dataKey="past"
                            className={`mt-12 block ${
                                isMobile ? "accordion" : ""
                            }`}
                        >
                            <div className="mb-4 flex items-center justify-between lg:mb-8">
                                <FundPageContent.Header h2 className="mb-0">
                                    {pastEvents?.title}
                                </FundPageContent.Header>
                                {isMobile && (
                                    <button
                                        className={`transform transition-transform ${
                                            pastEventsAccordionOpen
                                                ? "rotate-180"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            setPastEventsAccordionOpen(
                                                !pastEventsAccordionOpen,
                                            )
                                        }
                                    >
                                        <ArrowIcon />
                                    </button>
                                )}
                            </div>
                            <CollapsibleTransition
                                show={isMobile ? pastEventsAccordionOpen : true}
                                className="mt-2"
                            >
                                <EventList
                                    type="past"
                                    events={pastEventsData}
                                    withShowMoreCTA
                                />
                            </CollapsibleTransition>
                        </FundPageContent.Section>
                    )}
                </div>
            </EventsContextProvider>
        </div>
    );
};

function parseEventType(event: EventListItem) {
    return {
        ...event,
        type:
            event.type === "physical"
                ? "In person"
                : event.type.charAt(0).toUpperCase() + event.type.slice(1),
    };
}
