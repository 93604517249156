import * as React from "react";

const SvgDownloadWhite = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={72}
        height={83}
        fill="none"
        {...props}
    >
        <g clipPath="url(#downloadWhite_svg__a)">
            <path
                fill="#D3E3CC"
                fillRule="evenodd"
                d="M44.444 28.628c0 12.273-9.949 22.222-22.222 22.222H0V28.628C0 16.355 9.95 6.406 22.222 6.406c12.273 0 22.222 9.95 22.222 22.222Z"
                clipRule="evenodd"
            />
            <path
                fill="#fff"
                d="M64.097 36.712c-1.574 1.574-3.154 3.149-4.724 4.726-6.245 6.265-12.49 12.527-18.733 18.795-.646.647-.66.65-1.327-.018L16.581 37.412c-.22-.221-.424-.458-.76-.821.392-.018.637-.041.884-.041 4.217 0 8.434-.018 12.651.014.719.006.905-.218.902-.918-.027-10.028-.03-20.054-.036-30.082 0-2.354 1.15-3.92 3.377-4.575.369-.11.773-.127 1.159-.127C38.218.85 41.674.85 45.13.85c2.708 0 4.608 1.905 4.614 4.64.01 4.204 0 8.404 0 12.607v17.327c0 .73.355 1.097 1.064 1.099h13.217c.023.062.044.124.068.186l.003.003Zm-44.041 1.542c-.05.097-.098.195-.148.29.563.51 1.144.998 1.68 1.535 5.912 5.905 11.814 11.815 17.72 17.726.68.682.669.673 1.414-.074 5.855-5.878 11.717-11.753 17.572-17.631.542-.544 1.073-1.096 1.61-1.645-.027-.062-.054-.121-.08-.183H49.047c-.684 0-1.026-.349-1.026-1.046V5.594c0-1.829-1.158-2.992-2.97-2.995-3.383-.003-6.766-.003-10.149 0-1.827 0-2.926 1.096-2.938 2.918-.012 1.94-.02 3.884-.02 5.825 0 8.702-.01 17.4.011 26.102 0 .65-.177.834-.828.828-3.406-.033-6.816-.015-10.223-.015h-.851l.003-.003ZM39.982 82.824H12.627c-2.768 0-4.612-1.835-4.621-4.6a982.902 982.902 0 0 1 0-5.898c.009-2.744 1.889-4.635 4.612-4.635h54.711c2.732 0 4.65 1.941 4.656 4.7.003 1.867-.05 3.736.012 5.603.103 3.022-2.296 4.874-4.806 4.856-9.07-.068-18.138-.026-27.209-.026Zm-.006-1.734h27.362c1.748 0 2.89-1.146 2.9-2.904a749.98 749.98 0 0 0 0-5.75c-.006-1.841-1.15-2.996-2.977-2.996H12.683c-1.819 0-2.944 1.117-2.956 2.924-.012 1.894.068 3.79-.024 5.677-.085 1.76 1.356 3.09 3.056 3.078 9.07-.065 18.144-.03 27.215-.03h.002Z"
            />
        </g>
        <defs>
            <clipPath id="downloadWhite_svg__a">
                <path fill="#fff" d="M0 .85h72v82H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgDownloadWhite;
