import React, { ReactNode } from "react";

import Collapsible from "@hsl/components/Collapsible";
import cx from "@hsl/core/utils/cx";

import { BUTTON_CLASSNAMES } from "../Button";

interface BaseProps {
    children?: ReactNode;
    className?: string;
}

export const ListContainer = ({ children, className }: BaseProps) => {
    return (
        <div
            className={cx(
                "custom-scroller flex flex-col flex-nowrap justify-center gap-6 sm:snap-x sm:flex-row sm:items-stretch md:justify-start xl:overflow-hidden",
                Array.isArray(children) &&
                    children.length > 1 &&
                    "sm:overflow-x-auto",
                className,
            )}
        >
            {children}
        </div>
    );
};

export const CollapsibleListContainer = ({
    children,
    className,
}: BaseProps) => {
    return (
        <Collapsible
            labelPosition="end"
            className={className}
            label={(open) => (
                <div
                    className={cx(
                        BUTTON_CLASSNAMES,
                        "border-secondary text-secondary hover:bg-secondary mx-auto border px-8 text-base transition hover:text-white",
                    )}
                >
                    {open ? "Show less" : "Show more"}
                </div>
            )}
            labelClassNames="mx-auto my-4"
            panelClassName="pb-4"
        >
            <ListContainer>{children}</ListContainer>
        </Collapsible>
    );
};

export const Container = ({
    href,
    children,
    className,
    newTab,
}: { href?: string; newTab?: boolean } & BaseProps) => {
    const classNames = cx(
        "flex-1 flex max-w-full sm:max-w-sm sm:min-w-[350px] xl:min-w-[375px] flex-col overflow-hidden rounded-t-xl",
        className,
    );
    if (href) {
        return (
            <a
                className={classNames}
                href={href}
                target={newTab ? "_blank" : "_self"}
            >
                {children}
            </a>
        );
    } else {
        return <div className={classNames}>{children}</div>;
    }
};

export const Image = ({
    children,
    className,
    imgURL,
}: BaseProps & { imgURL: string }) => {
    return (
        <div
            className={cx(
                "relative flex h-44 w-full flex-col justify-between rounded-t-xl bg-gray-200 bg-cover bg-center",
                className,
            )}
            style={{ backgroundImage: `url(${imgURL})` }}
        >
            {children}
        </div>
    );
};

export const Brow = ({ children, className }: BaseProps) => {
    return (
        <div className={cx("text-purple text-sm font-semibold", className)}>
            {children}
        </div>
    );
};

export const Title = ({ children, className }: BaseProps) => {
    return (
        <h4
            className={cx(
                "mb-0 py-2 text-xl font-semibold text-black",
                className,
            )}
        >
            {children}
        </h4>
    );
};

export const Footer = ({ children, className }: BaseProps) => {
    return (
        <div
            className={cx(
                "border-purple text-purple mt-4 border-t pt-2 text-base",
                className,
            )}
        >
            {children}
        </div>
    );
};

export const Body = ({ children, className }: BaseProps) => {
    return (
        <div
            className={cx(
                "flex flex-1 flex-col justify-between rounded-b-xl bg-white p-4",
                className,
            )}
        >
            {children}
        </div>
    );
};
