function scrollIntoViewWithinElement(
    identifier: string,
    offset: number,
    axis: "x" | "y",
) {
    const targetElem = document.querySelector(`#nav-item-${identifier}`);

    if (!targetElem) {
        throw new Error(`${identifier} not found to scroll into view`);
    }

    const parent = targetElem.parentElement!;

    // Where is the parent on page
    const parentRect = parent.getBoundingClientRect();
    // What can you see?
    const parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth,
    };

    // Where is the child
    const childRect = targetElem.getBoundingClientRect();
    // Is the child viewable?
    const isViewable =
        childRect.left >= parentRect.left &&
        childRect.right <= parentRect.left + parentViewableArea.width;

    // if you can't see the child try to scroll parent
    if (!isViewable) {
        if (axis === "x") {
            // Should we scroll using left or right? Find the smaller ABS adjustment
            const scrollLeft = childRect.left - parentRect.left;
            const scrollRight = childRect.right - parentRect.right;
            if (Math.abs(scrollLeft) < Math.abs(scrollRight)) {
                // we're near the left of the list
                parent.scrollTo({
                    left: (parent.scrollLeft += scrollLeft - offset),
                    behavior: "smooth",
                });
            } else {
                // we're near the right of the list
                parent.scrollTo({
                    left: (parent.scrollLeft += scrollRight + offset),
                    behavior: "smooth",
                });
            }
        } else {
            // Should we scroll using up or down? Find the smaller ABS adjustment
            const scrollTop = childRect.top - parentRect.top;
            const scrollBottom = childRect.bottom - parentRect.bottom;
            if (Math.abs(scrollTop) < Math.abs(scrollBottom)) {
                // we're near the top of the list
                parent.scrollTo({
                    top: (parent.scrollTop += scrollTop - offset),
                    behavior: "smooth",
                });
            } else {
                // we're near the bottom of the list
                parent.scrollTo({
                    top: (parent.scrollTop += scrollBottom + offset),
                    behavior: "smooth",
                });
            }
        }
    }
}

export default scrollIntoViewWithinElement;
