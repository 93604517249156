import { useCallback } from "react";

import scrollIntoView from "../../utils/scrollIntoView";

export default function useScrollToHash() {
    return useCallback(
        (selector: string, offset: number, delay: number) =>
            setTimeout(scrollIntoView.bind(null, selector, offset), delay),
        [],
    );
}
