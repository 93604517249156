import { createContext, useContext, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { fetchDocStructure } from "@hsl/core/documents/services";
import type {
    DocsData,
    LitStructureAPIParams,
} from "@hsl/core/documents/types";

export type DocumentContextType = {
    shareClasses?: number | number[];
    docsData: DocsContextData;
    baseURL?: string;
    isLoading?: boolean;
};

export interface Props {
    queryKey?: string;
    children: React.ReactNode;
    params: LitStructureAPIParams;
    litApiId: number;
    baseURL?: string;
}

export type DocsContextData = {
    data: DocsData | undefined;
    error: any;
    isLoading: boolean;
    settings: any;
};

export const DocumentContext = createContext<DocumentContextType | undefined>(
    undefined,
);

export const DocumentContextProvider = ({
    queryKey = "docs",
    params,
    litApiId,
    baseURL,
    children,
}: Props) => {
    const { data, error, isLoading } = useQuery({
        queryKey: [queryKey, params],
        queryFn: () => fetchDocStructure(litApiId, params, baseURL),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });

    const contextValue = useMemo(
        () => ({
            docsData: {
                error,
                isLoading,
                data: data?.data,
                settings: data?.settings,
            },
            shareClasses: params.share_classes,
            baseURL,
            isLoading,
        }),
        [data, error, isLoading, params.share_classes, baseURL],
    );

    return (
        <DocumentContext.Provider value={contextValue}>
            {children}
        </DocumentContext.Provider>
    );
};

export function useDocumentContext() {
    const documentContext = useContext(DocumentContext);
    if (!documentContext) throw new Error("No DocumentContextProvider");
    return documentContext;
}
