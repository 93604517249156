import * as React from "react";

const SvgPodcastInterview = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={41}
        height={41}
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            d="M33.511 20.002a8.13 8.13 0 0 1-2.06 4.839l.916 1.03a9.468 9.468 0 0 0 .217-12.766l-1.05.886a8.13 8.13 0 0 1 1.977 6.011Z"
        />
        <path
            fill="#fff"
            d="M30.718 19.797a5.38 5.38 0 0 1-1.144 2.923l.924 1.053a6.694 6.694 0 0 0-.07-8.849l-1.05.885a5.394 5.394 0 0 1 1.34 3.988ZM9.203 25.884l.917-1.03a8.152 8.152 0 0 1-.084-10.836l-1.05-.885a9.468 9.468 0 0 0 .217 12.765v-.014Z"
        />
        <path
            fill="#fff"
            d="M9.438 19.9a6.646 6.646 0 0 0 1.616 3.873l.924-1.053a5.377 5.377 0 0 1 .2-6.91l-1.05-.886a6.606 6.606 0 0 0-1.69 4.976ZM21.589 30.578v-3.236c3.094-.4 5.407-2.986 5.407-6.046v-2.652h-1.62v2.652c0 2.49-2.06 4.509-4.599 4.509-2.54 0-4.599-2.019-4.599-4.509v-2.652h-1.62v2.652c0 3.06 2.313 5.647 5.407 6.046v3.236h-5.662v1.589h12.983v-1.59H21.59Z"
        />
        <path
            fill="#fff"
            d="M20.777 24.745c1.933 0 3.5-1.537 3.5-3.432v-.017h-2.688v-1.06h2.705v-1.592H21.59v-1.06h2.705v-1.59H21.59v-1.06h2.705v-1.582H21.59v-1.07h2.705c0-1.905-1.575-3.449-3.517-3.449-1.943 0-3.517 1.544-3.517 3.449v9.014c-.01 1.895 1.55 3.44 3.482 3.449h.035Z"
        />
    </svg>
);
export default SvgPodcastInterview;
