import { type PerformanceChartPeriod } from "@hsl/core/fund-page/schemas";

import { ONE_MONTH, ONE_YEAR } from "./times";

export function convertPeriodToTimestamp(period?: PerformanceChartPeriod) {
    if (period) {
        const multiplier = period.match(/^[0-9]{1,2}/)![0];
        const lengthOfTime = period.match(/[a-z]*$/)![0];
        switch (lengthOfTime) {
            case "y":
                return parseInt(multiplier) * ONE_YEAR;
            case "m":
                return parseInt(multiplier) * ONE_MONTH;
            default:
                break;
        }
    }
}
