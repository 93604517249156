import * as React from "react";

const SvgFactSheetGreen = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={74}
        height={83}
        fill="none"
        {...props}
    >
        <path
            fill="#016560"
            fillRule="evenodd"
            d="M43.922 51.919c0 12.129-9.832 21.961-21.961 21.961H0V51.919C0 39.79 9.832 29.958 21.961 29.958c12.129 0 21.961 9.832 21.961 21.961Z"
            clipRule="evenodd"
            opacity={0.4}
        />
        <rect
            width={60.389}
            height={80.011}
            x={12.404}
            y={1}
            stroke="#016560"
            strokeWidth={2}
            rx={5}
        />
        <path
            stroke="#016560"
            strokeLinecap="round"
            strokeWidth={2}
            d="M20.458 38.071V24.15M28.025 38.071V12.645M35.593 38.071V24.15M20.484 51.162H65.33M20.484 60.243h44.648M20.484 69.324h22.422"
        />
        <circle
            cx={53.156}
            cy={27.877}
            r={10.556}
            stroke="#016560"
            strokeWidth={2}
        />
        <path
            stroke="#016560"
            strokeWidth={2}
            d="M54.156 16.322v11.555M64.709 28.877l-11.556-.033"
        />
    </svg>
);
export default SvgFactSheetGreen;
