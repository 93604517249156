import cx from "@hsl/core/utils/cx";

interface Props {
    onlyMobile?: boolean;
    className?: string;
}

const Separator = ({ onlyMobile, className }: Props) => (
    <div
        className={cx(
            "absolute bottom-0 right-0 top-0 min-w-[1px] bg-gray-200",
            onlyMobile && "sm:hidden",
            className,
        )}
    />
);

export default Separator;
