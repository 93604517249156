import { NewsAndViewsCardSkeleton } from "../../../components/NewsAndViewsCard";
import Skeleton from "../../../components/Skeletons";

export const NewsAndViewsSkeleton = () => (
    <div className="container mx-auto pt-8">
        <Skeleton width={"100%"} height={"60px"} />
        <div className="flex flex-wrap gap-6 pt-8">
            {Array.from(Array(9)).map((_, i) => (
                <NewsAndViewsCardSkeleton key={`newsAndViewsSkel-${i}`} />
            ))}
        </div>
    </div>
);
