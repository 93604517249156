import * as React from "react";

const SvgGreyCurve = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 1920 84"
        {...props}
    >
        <path
            fill="#F1F1F1"
            fillRule="evenodd"
            d="M0 15.573S127.358 5.161 599.463.205C1071.57-4.752 1920 82.02 1920 82.02V84H0V15.573Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgGreyCurve;
