import { type SeriesLineOptions } from "highcharts";

import cx from "@hsl/core/utils/cx";

export const LineChartLegend = ({
    series,
    className,
    legendIndicatorClassName,
    valueClassName,
    entryClassName,
}: {
    series?: SeriesLineOptions[];
    className?: string;
    legendIndicatorClassName?: string;
    valueClassName?: string;
    entryClassName?: string;
}) => {
    return (
        <ul
            className={cx(
                "flex w-full items-center gap-x-16 px-1 py-1 text-xs sm:px-0 sm:py-2 sm:text-base",
                className,
            )}
        >
            {series?.map((item) => {
                const { name, color, data } = item;
                if (!data || data.length === 0) {
                    return;
                }
                const lastDataPoint = data?.slice(-1)[0] as number[];
                return (
                    <li
                        className={cx(
                            "flex items-center justify-between gap-x-2",
                            entryClassName,
                        )}
                        key={name}
                    >
                        <div className="flex items-center gap-x-2">
                            <div
                                className={cx(
                                    "h-3 w-3 shrink-0 rounded-full",
                                    legendIndicatorClassName,
                                )}
                                style={
                                    color
                                        ? { backgroundColor: color as string }
                                        : undefined
                                }
                            />
                            <span className="name">{name}</span>
                        </div>
                        <span className={cx("font-medium", valueClassName)}>
                            {lastDataPoint[1]?.toFixed(2).toString()}%
                        </span>
                    </li>
                );
            })}
        </ul>
    );
};
