import { useCallback, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import dayjs from "dayjs";

import cx from "@hsl/core/utils/cx";
import { type AvailableDates } from "@hsl/fund-page/schemas";

import MonthsDisplay from "./MonthsDisplay";

interface Props {
    availableDates: AvailableDates;
    selectedMonth?: number;
    selectedYear?: number;
    label?: React.ReactNode | ((open?: boolean) => React.ReactNode);
    trigger?: React.ReactNode;
    zeroIndexed?: boolean;
    format?: string;
    className?: string;
    buttonClassName?: string;
    panelClassName?: string;
    onChange?: (selection: { year: number; month: number }) => void;
}

const MonthPicker = ({
    label = "Month",
    availableDates,
    selectedYear = availableDates[availableDates.length - 1]?.value,
    selectedMonth = availableDates[availableDates.length - 1]?.availableMonths
        .slice()
        .pop()?.value,
    zeroIndexed = false,
    format = "MMMM",
    className,
    buttonClassName,
    trigger,
    panelClassName,
    onChange,
}: Props) => {
    const djsObj = dayjs();
    const sortedDates = availableDates.sort((a, b) =>
        a.value > b.value ? 1 : -1,
    );
    const allYears = sortedDates.map((d) => d.value);
    const getYearIndex = useCallback(
        () =>
            selectedYear
                ? allYears.findIndex((d) => d === selectedYear)
                : allYears.length - 1,
        [selectedYear, allYears],
    );
    const [yearIndex, setYearIndex] = useState(getYearIndex());
    const year = sortedDates[yearIndex];
    const next = sortedDates[yearIndex + 1];
    const prev = sortedDates[yearIndex - 1];
    const [yearStr, setYearStr] = useState(selectedYear);
    const [month, setMonth] = useState(
        selectedMonth &&
            year?.availableMonths.find((m) => m.value === selectedMonth)
            ? zeroIndexed
                ? selectedMonth
                : selectedMonth - 1
            : year?.availableMonths.slice().pop()?.value,
    );

    const availableMonths = year?.availableMonths.map((m) => m.value - 1);

    const [monthStr, setMonthStr] = useState(
        selectedMonth
            ? djsObj
                  .month(zeroIndexed ? selectedMonth : selectedMonth - 1)
                  .format(format)
            : "",
    );

    return (
        year &&
        yearStr &&
        typeof month === "number" &&
        Array.isArray(availableMonths) && (
            <Popover className={cx("relative z-10", className)}>
                {({ open }) => (
                    <PopoverContent
                        open={open}
                        availableMonths={availableMonths}
                        year={year}
                        yearIndex={yearIndex}
                        next={next}
                        prev={prev}
                        month={month}
                        label={label}
                        setYearIndex={setYearIndex}
                        handleMonthSelect={handleMonthSelect}
                        allYears={allYears}
                        getYearIndex={getYearIndex}
                        monthStr={monthStr}
                        yearStr={yearStr}
                        trigger={trigger}
                        buttonClassName={buttonClassName}
                        panelClassName={panelClassName}
                    />
                )}
            </Popover>
        )
    );

    function handleMonthSelect(month: number) {
        if (year) {
            onChange && onChange({ year: year.value, month: month + 1 });
            setYearStr(year.value);
            setMonth(month);
            setMonthStr(djsObj.month(month).format(format));
        }
    }
};

const PopoverContent = ({
    open,
    availableMonths,
    year,
    yearIndex,
    next,
    prev,
    month,
    label,
    setYearIndex,
    handleMonthSelect,
    allYears,
    getYearIndex,
    monthStr,
    yearStr,
    trigger,
    buttonClassName,
    panelClassName,
}: {
    open: boolean;
    availableMonths: number[];
    year: { value: number; availableMonths: { value: number }[] };
    yearIndex: number;
    next?: { value: number };
    prev?: { value: number };
    month: number;
    label?: React.ReactNode | ((open?: boolean) => React.ReactNode);
    setYearIndex: (index: number) => void;
    handleMonthSelect: (month: number) => void;
    allYears: number[];
    getYearIndex: () => number;
    monthStr: string;
    yearStr: number;
    trigger?: React.ReactNode;
    buttonClassName?: string;
    panelClassName?: string;
}) => {
    // Reset the picker so it always starts at the selected month/year
    useEffect(() => {
        !open && setYearIndex(getYearIndex());
    }, [open, getYearIndex, setYearIndex]);

    return (
        <>
            <Popover.Button
                as="div"
                className={cx(
                    "inline-flex items-center justify-between text-sm transition hover:opacity-95 sm:text-base",
                    buttonClassName,
                )}
            >
                {label &&
                    (typeof label === "string" ? (
                        <span className="mr-2">{label}</span>
                    ) : typeof label === "function" ? (
                        label(open)
                    ) : (
                        label
                    ))}
                <span className="flex cursor-pointer items-center gap-1 font-medium">
                    <span>{trigger ?? `${monthStr} ${yearStr}`}</span>{" "}
                    <ChevronDownIcon
                        className={`h-4 w-4 transition ${
                            open ? "rotate-180" : ""
                        }`}
                    />
                </span>
            </Popover.Button>
            <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Popover.Panel
                    className={cx(
                        "absolute z-10 w-[200px] bg-white shadow-lg",
                        panelClassName,
                    )}
                    unmount
                >
                    {({ close }) => (
                        <MonthsDisplay
                            activeYearIndex={yearIndex}
                            months={availableMonths}
                            selection={[month, yearStr]}
                            year={year.value}
                            next={next ? yearIndex + 1 : undefined}
                            prev={prev ? yearIndex - 1 : undefined}
                            onChange={setYearIndex}
                            onSelect={(month) => {
                                close();
                                handleMonthSelect(month);
                            }}
                            years={allYears}
                        />
                    )}
                </Popover.Panel>
            </Transition>
        </>
    );
};

export default MonthPicker;
