import { z } from "zod";

import ClientOnly from "@hsl/components/ClientOnly";
import PieChart from "@hsl/components/PieChart";
import cx from "@hsl/core/utils/cx";
import {
    advancedDataTableSchema,
    textPartSchema,
} from "@hsl/fund-page/schemas";

import SpringBarTable from "../../../components/BarTable";
import FundPageContent from "../../../components/FundPageContent";
import useIsXS from "../../../hooks/useIsXS";
import themeColors from "../../../lib/themeColors";

export const sectorAllocationSchema = advancedDataTableSchema;
export const geographicAllocationSchema = advancedDataTableSchema;
export const marketCapAllocationSchema = advancedDataTableSchema;
export const noteSchema = textPartSchema.optional();

const separatorClassName =
    "border-secondary border-t xl:border-t-0 xl:border-l border-opacity-50 pt-4 xl:pt-0 xl:pl-10";

interface Props {
    sectorAllocation: z.infer<typeof sectorAllocationSchema>;
    geographicAllocation: z.infer<typeof sectorAllocationSchema>;
    marketCapAllocation: z.infer<typeof sectorAllocationSchema>;
    note: z.infer<typeof noteSchema>;
}

const GraphSection = ({
    geographicAllocation,
    marketCapAllocation,
    sectorAllocation,
    note,
}: Props) => {
    const isXS = useIsXS();
    return (
        <FundPageContent.Box>
            <div className="flex flex-col gap-x-10 gap-y-4 xl:flex-row">
                <div className="flex-1">
                    <FundPageContent.Header className="mb-2">
                        {sectorAllocation.part.title}
                    </FundPageContent.Header>
                    <SpringBarTable data={sectorAllocation} />
                </div>
                <div className={cx("flex-1", separatorClassName)}>
                    <FundPageContent.Header className="mb-2">
                        {geographicAllocation.part.title}
                    </FundPageContent.Header>
                    <SpringBarTable data={geographicAllocation} />
                </div>
                <div className={cx("flex-1", separatorClassName)}>
                    <FundPageContent.Header className="mb-0">
                        {marketCapAllocation.part.title}
                    </FundPageContent.Header>
                    <ClientOnly>
                        <PieChart
                            data={marketCapAllocation.part.data}
                            colors={[
                                themeColors.corePurple,
                                themeColors.darkPink,
                                themeColors.lightPink,
                                themeColors.gray,
                            ]}
                            innerSize={40}
                            size={isXS ? 100 : 130}
                            showLegend
                            className="items-center"
                            legendOptions={{
                                indicatorClassName: "h-[9px] w-[9px]",
                                labelClassName: "text-base sm:text-sm",
                                valueClassName:
                                    "text-base text-right sm:text-sm",
                            }}
                        />
                    </ClientOnly>
                </div>
            </div>
            {note?.part.data && (
                <FundPageContent.Footnote className="pt-4">
                    {note.part.data}
                </FundPageContent.Footnote>
            )}
        </FundPageContent.Box>
    );
};

export default GraphSection;
