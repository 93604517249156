import DatePicker, { type DatePickerProps } from "@hsl/components/DatePicker";
import cx from "@hsl/core/utils/cx";

import { SPRING_DROPDOWN_PROPS } from "../Dropdown";

const SpringDatePicker = (
    props: Omit<
        DatePickerProps,
        "modifierClassNames" | "dropDownOptionClassNames"
    >,
) => {
    return (
        <DatePicker
            {...props}
            dateFormat="DD/MM/YYYY"
            modifierClassNames={{
                selected: "bg-purple text-white",
                disabled: "",
            }}
            dayPickerClassName={cx(
                SPRING_DROPDOWN_PROPS.buttonClassName,
                props.dayPickerClassName,
            )}
            dropDownOptionClassNames={{
                active: "bg-secondary text-white hover:bg-secondary",
                default: "hover:bg-secondary/75 hover:text-white",
            }}
        />
    );
};

export default SpringDatePicker;
