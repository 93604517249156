import { GenericErrorBoundary } from "@hsl/lgim-explorer/src/components/GenericErrorBoundary/GenericErrorBoundary";

import FundPageContent from "../../components/FundPageContent";
import { GreyCurve } from "../../components/icons";
import { NewsAndViewsCardSkeleton } from "../../components/NewsAndViewsCard";
import useFundPageStore from "../../store/store";
import { Articles, SignUp } from "./components";

const PostRead = () => {
    const loading = useFundPageStore((store) => store.loading);

    if (loading) {
        return <PostReadSkeleton />;
    }

    return (
        <GenericErrorBoundary>
            <SignUp />
            <Articles />
        </GenericErrorBoundary>
    );
};

export default PostRead;

export const PostReadSkeleton = () => (
    <>
        <div className="overflow-hidden">
            <GreyCurve className="mx-auto -mb-1" />
            <div className="bg-offWhite mx-auto max-w-7xl pb-6">
                <div className="container mx-auto">
                    <FundPageContent.HeaderSkeleton height={"2.5rem"} />
                    <div className="flex flex-wrap gap-6 pt-8">
                        {Array.from(Array(3)).map((_, i) => (
                            <NewsAndViewsCardSkeleton
                                key={`homeNewsAndViewsSkel-${i}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </>
);
