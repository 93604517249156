import { useMemo } from "react";

import { useClientOnly } from "@hsl/components/ClientOnly";
import { useBreakpointContext } from "@hsl/core/hooks/useBreakpoint";
import { type PageSection } from "@hsl/fund-page/schemas";

import useIntheLoupe from "./useIntheLoupe";

function useComputedPageSections(pageSections: PageSection[]) {
    const isMobile = useBreakpointContext()?.currentBreakpoint === "xs";
    const isClient = useClientOnly();
    const intheLoupeAccess = useIntheLoupe();

    const computedPageSections = useMemo(() => {
        let output: PageSection[] = [...pageSections];

        if (isClient) {
            // Move news and views last on mobile
            if (isMobile) {
                output = [
                    ...output.filter((x) => x.key !== "newsAndViews"),
                    output.find((x) => x.key === "newsAndViews"),
                ] as PageSection[];
            }
            if (!intheLoupeAccess) {
                output = output.filter((x) => x.key !== "intheLoupe");
            }
        } else {
            // remove in the loupe if static render
            output = output.filter((x) => x.key !== "intheLoupe");
        }

        return output;
    }, [isMobile, isClient, pageSections]);

    return computedPageSections;
}

export default useComputedPageSections;
