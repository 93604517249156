import { useEffect, useRef, useState } from "react";

import debounceFunc from "../utils/debounce";

const useSticky = (loading: boolean, debounce?: number) => {
    // change layout when sticky element is stuck
    const stickyCanaryRef = useRef<HTMLDivElement>(null!);
    const [stuck, setStuck] = useState(false);

    useEffect(() => {
        const handleStickyChange = debounceFunc((boolean: boolean) => {
            setStuck(boolean);
        }, debounce);

        const element = stickyCanaryRef.current;

        const observer = new IntersectionObserver(
            ([e]) => e && handleStickyChange(e.intersectionRatio < 1),
            { threshold: [1] },
        );

        if (element) {
            observer.observe(element);

            return () => observer.unobserve(element);
        }
    }, [loading, debounce]);

    return { stickyRef: stickyCanaryRef, stuck };
};

export default useSticky;
