import type { z } from "zod";

import cx from "@hsl/core/utils/cx";
import { pricesGroupedSchema } from "@hsl/fund-page/schemas";

import ChangeIndicator from "../../../components/ChangeIndicator";
import Table from "../../../components/Table";

export const shareclassPricesTableSchema = pricesGroupedSchema;
export type ShareclassPricesTableData = z.infer<
    typeof shareclassPricesTableSchema
>;

const ShareclassPricesTable = ({
    part,
    activeGroup,
    showAllCodes,
}: ShareclassPricesTableData & {
    activeGroup: string;
    showAllCodes?: boolean;
}) => {
    if (!part) {
        return null;
    }
    const { data, headers, partId } = part;
    return (
        <Table
            key={String(partId)}
            colDisplayDirectives={(i) => ({
                alignLeft: i < 2,
                alignRight: i > 1,
            })}
            colMeta={(i) => ({
                headerParentClassName: cx(
                    "bg-offWhite",
                    i === 0 && "w-[110px]",
                    i === 1 && "w-[150px] bg-gapRight pr-4",
                    i === 2 && "bg-gapLeft pl-4",
                    i > 1 &&
                        (showAllCodes
                            ? "whitespace-normal"
                            : i === 2
                            ? "whitespace-nowrap"
                            : "whitespace-normal"),
                    i > 1 && (showAllCodes ? "w-[25%]" : "max-content"),
                    "border-b-0",
                ),
                cellParentClassName: cx(i === 1 && "pr-4", i === 2 && "pl-4"),
            })}
            icon={({ header, value }) => {
                if (header === "Change (%)" && value) {
                    return <ChangeIndicator val={value} />;
                }
                return null;
            }}
            part={{
                type: "DataTable",
                partId: partId!,
                headers,
                data: data[activeGroup]!,
            }}
        />
    );
};

export default ShareclassPricesTable;
