import { useState } from "react";

import ClientOnly, { useClientOnly } from "@hsl/components/ClientOnly";
import { useBreakpointContext } from "@hsl/core/hooks/useBreakpoint";
import cx from "@hsl/core/utils/cx";
import { type TextCollectionPartArraySchema } from "@hsl/fund-page/schemas";

import FundPageContent from "../../../components/FundPageContent";
import ShowMoreCTA from "../../../components/ShowMoreCTA";
import TextDataPart from "../../../components/TextDataPart";

const CommentaryText = ({ part }: TextCollectionPartArraySchema) => {
    const isMobile = useBreakpointContext()?.currentBreakpoint === "xs";
    const [showMoreText, setShowMoreText] = useState(isMobile ? false : true);
    const isClient = useClientOnly();

    return (
        <>
            <div className="sm:pt-2">
                <div
                    className={cx(
                        "flex flex-col gap-y-2 overflow-hidden transition duration-1000 ease-out",
                        isClient && !showMoreText && "max-h-[390px]",
                    )}
                >
                    {part.map((textCollection, i) => {
                        if (!textCollection) return null;
                        return (
                            <div key={`commentary-textdatapart-${i}`}>
                                {textCollection.conf?.title && (
                                    <TextDataPart
                                        data={`<h1>${textCollection.conf.title}</h1>`}
                                        decode={false}
                                    />
                                )}
                                <TextDataPart
                                    data={textCollection?.data.text}
                                />
                            </div>
                        );
                    })}
                </div>
                <ClientOnly>
                    <ShowMoreCTA
                        className="-mx-4 mt-2 w-screen sm:hidden"
                        active={showMoreText}
                        onClick={() => setShowMoreText(!showMoreText)}
                    />
                </ClientOnly>
            </div>
            {part.map((textCollection, i) => {
                if (!textCollection || !textCollection.data.author) return null;
                return (
                    <FundPageContent.P
                        key={`commentary-text-${i}`}
                        className="mt-2 text-right"
                    >
                        <span className="text-purple text-xl font-medium">
                            {textCollection.data.author}
                        </span>
                        <span className="ml-2 text-xs">
                            {textCollection.data.dateWritten}
                        </span>
                    </FundPageContent.P>
                );
            })}
        </>
    );
};

export default CommentaryText;
