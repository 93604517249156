import { createContext, useContext, useMemo } from "react";

import useWindowDimensions from "@hsl/core/hooks/useWindowDimensions";
import themeBreakpoints from "@hsl/lgim/src/js/lib/themeBreakpoints";

type Breakpoint = "sm" | "md" | "lg" | "xl" | "2xl";

type AllBreakpoints = Breakpoint | "xs";

type BreakpointContextType = {
    currentBreakpoint?: AllBreakpoints;
    isGreaterThan(breakpoint: Breakpoint): boolean;
};

export const BreakpointContext = createContext<
    BreakpointContextType | undefined
>(undefined);

interface Props {
    children: React.ReactNode;
}

export const BreakpointProvider = ({ children }: Props) => {
    const { width } = useWindowDimensions();

    function isGreaterThan(breakpoint: Breakpoint) {
        return width > themeBreakpoints[breakpoint];
    }

    function isLessThan(breakpoint: Breakpoint) {
        return width < themeBreakpoints[breakpoint];
    }

    const currentBreakpoint = useMemo<AllBreakpoints>(() => {
        if (width > themeBreakpoints["2xl"]) {
            return "2xl";
        } else if (width > themeBreakpoints.xl) {
            return "xl";
        } else if (width > themeBreakpoints.lg) {
            return "lg";
        } else if (width > themeBreakpoints.md) {
            return "md";
        } else if (width > themeBreakpoints.sm) {
            return "sm";
        } else {
            return "xs";
        }
    }, [width]);

    const contextValue = useMemo(
        () => ({
            currentBreakpoint,
            isGreaterThan,
            isLessThan,
        }),
        [currentBreakpoint, isGreaterThan, isLessThan],
    );

    return (
        <BreakpointContext.Provider value={contextValue}>
            {children}
        </BreakpointContext.Provider>
    );
};

export function useBreakpointContext() {
    const Breakpoint = useContext(BreakpointContext);
    if (!Breakpoint) return undefined;
    return Breakpoint;
}
