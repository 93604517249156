import { useClientOnly } from "@hsl/components/ClientOnly";
import cx from "@hsl/core/utils/cx";

import useFundPageStore from "../../store/store";

export const Banner = ({ isStuck }: { isStuck: boolean }) => {
    const isClient = useClientOnly();
    const { fundName, fundAssetClass } = useFundPageStore(
        (store) => store.config,
    );

    return (
        <div className="bg-primary text-white">
            <div
                className={cx(
                    "container mx-auto flex flex-col justify-center px-4 transition-all",
                    isStuck && isClient ? "py-2" : "py-4",
                )}
            >
                <span
                    className={cx(
                        "overflow-hidden pb-1 text-sm transition-all",
                        isStuck && isClient ? "max-h-0" : "max-h-6",
                    )}
                >
                    {fundAssetClass}
                </span>
                <h1
                    className={cx(
                        isStuck && isClient && "scale-75",
                        "origin-left text-2xl leading-[1] transition-transform sm:text-3xl",
                    )}
                >
                    {fundName}
                </h1>
            </div>
        </div>
    );
};
