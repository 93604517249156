import { z } from "zod";

import ClientOnly from "@hsl/components/ClientOnly";
import {
    dataPartSchema,
    peoplePartSchema,
    shareClassSelectorObjSchema,
    textCollectionPartSchema,
} from "@hsl/fund-page/schemas";

import FundPageContent from "../../components/FundPageContent";
import TextDataPart from "../../components/TextDataPart";
import usePageSection from "../../hooks/usePageSection";
import useFundPageStore from "../../store/store";
import AboutSection, { aboutSectionSchema } from "./views/About";
import FundManagers from "./views/FundManagers";
import IntroSection, { introSectionSchema } from "./views/Intro";
import Ribbon, { ribbonSchema } from "./views/Ribbon";

const overviewSectionSchema = dataPartSchema.extend({
    canvas: dataPartSchema.extend({
        part: z.object({
            bannerText: textCollectionPartSchema.optional(),
            about: aboutSectionSchema,
            intro: introSectionSchema,
            ribbon: ribbonSchema,
            fundManagers: peoplePartSchema,
            note: shareClassSelectorObjSchema.optional(),
        }),
    }),
});

interface Props {
    dataKey: string;
}

const Overview = ({ dataKey }: Props) => {
    const activeShareClass = useFundPageStore((store) => store.shareClasses[0]);
    const { canvas } = usePageSection(dataKey, overviewSectionSchema);
    const { bannerText, about, intro, ribbon, fundManagers, note } =
        canvas.part;

    return (
        <FundPageContent.Section dataKey={dataKey} className="flex-col">
            {bannerText && (
                <FundPageContent.Box className="bg-notify">
                    <TextDataPart data={bannerText.part.data.text} />
                </FundPageContent.Box>
            )}
            <div className="flex flex-1 flex-wrap items-stretch gap-4 sm:gap-6">
                <FundPageContent.Box grow containerClassName="z-20">
                    <AboutSection {...about} key={about.key} />
                </FundPageContent.Box>
                <FundPageContent.Box containerClassName="flex-[2] z-10">
                    <IntroSection {...intro} key={intro.key} />
                </FundPageContent.Box>
            </div>
            <div>
                <ClientOnly>
                    <Ribbon {...ribbon} key={ribbon.key} />
                    {note && activeShareClass && (
                        <FundPageContent.Footnote
                            className="mt-4 px-4 pl-4 opacity-100 sm:px-0"
                            data={note.part.data[activeShareClass.id]}
                        />
                    )}
                </ClientOnly>
            </div>
            <div className="mt-4 pl-4 sm:pl-0">
                <FundManagers {...fundManagers} key={fundManagers.key} />
            </div>
        </FundPageContent.Section>
    );
};

const OverviewWithErrorBoundary = (props: Props) => {
    return (
        <FundPageContent.Wrapper dataKey={props.dataKey}>
            <Overview {...props} />
        </FundPageContent.Wrapper>
    );
};

export default OverviewWithErrorBoundary;
