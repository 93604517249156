import { z } from "zod";

import cx from "@hsl/core/utils/cx";
import { advancedDataTableData } from "@hsl/fund-page/schemas";

import ChangeIndicator from "../../../components/ChangeIndicator";
import FundPageContent from "../../../components/FundPageContent";
import Skeleton from "../../../components/Skeletons";

export const pricesHistoryTableDataSchema = advancedDataTableData
    .omit({ type: true, data: true })
    .extend({
        data: z.record(z.string(), z.array(z.array(z.string()))),
    });

export type PricesHistoryTableDataSchema = z.infer<
    typeof pricesHistoryTableDataSchema
>;

export const pricesHistoryTableSchema = z.object({
    part: pricesHistoryTableDataSchema,
});

export const PricesHistoryTable = ({
    headers,
    data,
    note,
    isLoading,
}: z.infer<typeof pricesHistoryTableDataSchema> & { isLoading?: boolean }) => {
    if (isLoading || !data) {
        return <PricesHistoryTableSkeleton />;
    }

    const dataKeys = Object.keys(data);

    return (
        <>
            <table className="w-full">
                <thead className="bg-offWhite">
                    <tr>
                        {headers?.map((header, i) => (
                            <th
                                key={header}
                                className={cx(
                                    "w-[33%] px-1 py-1.5 font-semibold",
                                    {
                                        "text-left": i === 0,
                                        "text-right": i > 0,
                                    },
                                )}
                            >
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
            </table>
            <div>
                {dataKeys.map((groupTitle, i) => {
                    const values = data[groupTitle];
                    if (!values) return null;
                    const filteredValues = values.filter((x) => x.length === 3);
                    if (filteredValues.length === 0) {
                        return null;
                    }
                    return (
                        <div
                            key={groupTitle}
                            className={cx(
                                i < dataKeys.length - 1 &&
                                    "border-offWhite my-2 border-b pb-2",
                            )}
                        >
                            <div className="text-purple p-1 text-lg">
                                {groupTitle}
                            </div>
                            <table className="w-full">
                                <tbody>
                                    {filteredValues.map((val) => (
                                        <tr
                                            key={`${groupTitle}-${val}`}
                                            className="text-sm sm:text-base"
                                        >
                                            {val.map((str, j) => (
                                                <td
                                                    key={str}
                                                    className={cx(
                                                        "w-[33%] px-1",
                                                        {
                                                            "text-left font-semibold":
                                                                j === 0,
                                                            "text-right": j > 0,
                                                        },
                                                    )}
                                                >
                                                    {str ?? "-"}
                                                    {j === 2 && !!str && (
                                                        <ChangeIndicator
                                                            val={str}
                                                        />
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    );
                })}
            </div>
            {note && <FundPageContent.Footnote data={note} withBorder />}
        </>
    );
};

const PricesHistoryTableSkeleton = () => {
    return (
        <div className="flex flex-col gap-y-4">
            {Array.apply(null, Array(3)).map((x, i) => {
                return (
                    <div key={`PricesHistoryTable-${i}`}>
                        <Skeleton width={"100%"} />
                        <div className="flex flex-col gap-y-2">
                            {Array.apply(null, Array(3)).map((y, j) => {
                                return (
                                    <Skeleton
                                        key={`PricesHistoryTableSkeleton-${j}`}
                                        width={"100%"}
                                    />
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
