import type { ReactNode } from "react";

import cx from "@hsl/core/utils/cx";

import "./styles.css";

/**
 * Need to make a proper Carousel component at some point...
 */

interface Props {
    containerClassName?: string;
    className?: string;
    children: ReactNode;
    enabled?: boolean;
}

const Carousel = ({
    children,
    className,
    containerClassName,
    enabled,
}: Props) => {
    return (
        <div
            className={cx(
                "relative h-full w-full overflow-hidden",
                containerClassName,
            )}
        >
            <div
                className={cx(
                    "flex h-full items-center justify-center",
                    enabled && "slide-animation absolute left-0 w-[200%]",
                    className,
                )}
            >
                {enabled ? Array(10).fill(children) : children}
            </div>
        </div>
    );
};

export default Carousel;
