import genNumInRange from "@hsl/core/utils/genNumInRange";

import Skeleton from "../Skeletons";

const NavSkeleton = () => (
    <div className="h-14 w-full bg-white">
        <div className="container mx-auto flex h-full items-center justify-between px-0">
            <div className="flex items-center gap-x-8 px-4 sm:px-0">
                {Array.from(Array(8)).map((_, i) => (
                    <Skeleton
                        key={`NavSkeleton-${i}`}
                        className="!m-0"
                        width={`${genNumInRange(70, 90)}px`}
                    />
                ))}
            </div>
            <Skeleton className="!m-0" width="130px" height="40px" />
        </div>
    </div>
);

export default NavSkeleton;
