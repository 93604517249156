import React, {
    type CSSProperties,
    type ElementType,
    type PropsWithChildren,
    type ReactNode,
} from "react";
import { Disclosure } from "@headlessui/react";

import cx from "@hsl/core/utils/cx";

import { CollapsibleLabel, CollapsibleTransition } from "./components";

interface Props {
    className?: string;
    panelClassName?: string | ((open: boolean) => string);
    transitionClassName?: string;
    isOpen?: boolean;
    label: ReactNode | ((open: boolean) => React.ReactNode);
    labelClassNames?: string | ((open: boolean) => string);
    labelPosition?: "start" | "end";
    chevronWidth?: number;
    chevronClassNames?: string;
    withChevron?: boolean;
    style?: CSSProperties;
    onClick?: (args?: any) => void;
    as?: ElementType<any>;
    labelAs?: ElementType<any>;
}

const Collapsible = ({
    as = "div",
    children,
    isOpen = false,
    label,
    labelAs,
    labelClassNames,
    labelPosition = "start",
    chevronClassNames,
    className,
    panelClassName,
    transitionClassName,
    withChevron = false,
    chevronWidth,
    style,
    onClick,
}: Props & PropsWithChildren) => {
    return (
        <Disclosure
            as={as}
            className={cx("w-full", className)}
            defaultOpen={isOpen}
            style={style}
        >
            {({ open }) => {
                const Label = (
                    <CollapsibleLabel
                        label={label}
                        labelClassNames={
                            typeof labelClassNames === "function"
                                ? labelClassNames(open)
                                : labelClassNames
                        }
                        chevronClassNames={chevronClassNames}
                        as={labelAs}
                        onClick={onClick}
                        withChevron={withChevron}
                        chevronWidth={chevronWidth}
                        isOpen={open}
                    />
                );
                return (
                    <>
                        {labelPosition === "start" && Label}
                        <CollapsibleTransition className={transitionClassName}>
                            <Disclosure.Panel
                                className={
                                    typeof panelClassName === "function"
                                        ? panelClassName(open)
                                        : panelClassName
                                }
                            >
                                {children}
                            </Disclosure.Panel>
                        </CollapsibleTransition>
                        {labelPosition === "end" && Label}
                    </>
                );
            }}
        </Disclosure>
    );
};

export default Collapsible;
