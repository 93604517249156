import { type ReactNode } from "react";

import Checkbox, {
    type Props as CheckboxProps,
} from "@hsl/components/Checkbox";
import cx from "@hsl/core/utils/cx";

export const FilterSection = ({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}) => {
    return (
        <div className={cx("border-t-offWhite mt-4 border-t pt-4", className)}>
            {children}
        </div>
    );
};

export const FilterHeader = ({ children }: { children: ReactNode }) => {
    return (
        <h4 className="mb-4 w-full text-base font-semibold text-black">
            {children}
        </h4>
    );
};

export const FilterCheckbox = <T,>(props: CheckboxProps<T>) => {
    return (
        <Checkbox<T>
            {...props}
            containerClassName={cx("my-1", props.containerClassName)}
            labelClassName={cx("pl-1 text-base", props.labelClassName)}
            inputClassName={cx("mr-1 rounded-none", props.inputClassName)}
        />
    );
};
