import * as React from "react";

const SvgMail = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={18}
        fill="none"
        {...props}
    >
        <g clipPath="url(#mail_svg__a)">
            <path
                fill="#016560"
                d="M19.8 0H2.2A2.176 2.176 0 0 0 .651.663c-.41.422-.64.992-.64 1.587L0 15.75c.002.596.234 1.168.646 1.589.412.422.971.66 1.554.661h17.6a2.182 2.182 0 0 0 1.554-.661c.412-.421.644-.993.646-1.589V2.25a2.283 2.283 0 0 0-.646-1.589A2.182 2.182 0 0 0 19.8 0Zm0 4.5L11 10.125 2.2 4.5V2.25L11 7.875l8.8-5.625V4.5Z"
            />
        </g>
        <defs>
            <clipPath id="mail_svg__a">
                <path fill="#fff" d="M0 0h22v18H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgMail;
