import React, { type PropsWithChildren } from "react";

import cx from "@hsl/core/utils/cx";

const TablePreface = ({
    children,
    className,
}: { className?: string } & PropsWithChildren) => {
    if (!children) {
        return null;
    }
    return <p className={cx("block text-sm", className)}>{children}</p>;
};

export default TablePreface;
