import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";

import cx from "@hsl/core/utils/cx";
import type { PostListFilterItem } from "@hsl/fund-page/schemas";

import Dropdown, { DropdownProps } from ".";

const FilterDropdown = <T,>({
    value,
    options,
    placeholder,
    className,
    containerClassName,
    optionContainerClassName,
    toLabel,
    onChange,
    onCancel,
}: DropdownProps<T> & {
    containerClassName?: string;
    onCancel(): void;
}) => {
    return (
        <div className={cx("relative w-full", containerClassName)}>
            {value && (
                <div className="bg-primary absolute inset-0 z-20 flex items-center justify-between rounded-lg p-2 text-white">
                    {typeof toLabel === "function"
                        ? toLabel(value)
                        : String(value)}
                    <XMarkIcon
                        className="cursor-pointer"
                        width={20}
                        onClick={onCancel}
                    />
                </div>
            )}
            <Dropdown<T>
                buttonClassName={cx(
                    "bg-white p-2 min-w-[200px] rounded-lg z-10",
                    className,
                )}
                placeholder={placeholder}
                optionContainerClassName={optionContainerClassName}
                value={value}
                options={options}
                toLabel={toLabel}
                onChange={onChange}
            />
        </div>
    );
};

export default FilterDropdown;
