import { type PerformanceChartPeriod } from "@hsl/core/fund-page/schemas";

const DEFAULT_LABELS: Record<PerformanceChartPeriod, string> = {
    sl: "Launch",
    "10y": "10 years",
    "5y": "5 years",
    "3y": "3 years",
    "1y": "1 year",
    "3m": "3 months",
    "1m": "1 month",
};

export default DEFAULT_LABELS;
